import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InfoCard from '../../../components/SAOrganization/InfoCard';
import Tabs from '../../../components/SAOrganization/Tabs';
import * as helpers from "../../../global/helpers";
import * as Constants from '../../../constants/Constants';

export default function OrgName({ orgDetails, acceptRejectOrg, enableDisableOrg }) {
  const dispatch = useDispatch();
  let userType = helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? 'contributor'
    : helpers.getUserType() === Constants.USER_TYPE_ADMIN ? 'admin' : '';

  useEffect(() => {
    dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
  }, [dispatch]);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-4'>
          <InfoCard orgDetails={orgDetails} userType={userType} acceptRejectOrg={acceptRejectOrg} enableDisableOrg={enableDisableOrg}/>
        </div>
        <div className='col-lg-7 mt-lg-0 mt-md-5 mt-sm-5'>
          <Tabs orgDetails={orgDetails} userType={userType} />
        </div>
      </div>
    </div>
  );
}
