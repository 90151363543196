import { useParams } from 'react-router';
import { BASE_URL } from '../constants/Constants';
import { postImage, uploadMedia } from '../global/helpers';

import { useMutation, useQueryClient } from 'react-query';

import { ImagesKey } from '../constants/Keys';

export const usePostImage = (imageFile, textarea, setIsEditing, setIsOpen, setDisabled) => {
  let guid = '';
  const queryClient = useQueryClient();
  const { id } = useParams();
  const handlePostImage = async () => {
    let url = `${BASE_URL}/projects/${id}/images/`;
    let data = {
      imageGuid: guid,
      caption: textarea,
    };
    await postImage(url, data);
  };
  const handleImageUpload = async () => {
    setDisabled(true);
    const formData = new FormData();
    formData.append('file', imageFile);

    await uploadMedia(formData).then((resp) => {
      guid = resp.data.guid;
      handlePostImage();
    });
  };
  return useMutation(handleImageUpload, {
    onSuccess: async () => {
      setTimeout(() => {
        queryClient.setQueryData(ImagesKey, (old) => [
          ...old,
          { imageGuid: guid, caption: textarea },
        ]);
      }, 200);

      // await queryClient.invalidateQueries(ImagesKey);
      setDisabled(false);
      setIsEditing(false);
      setIsOpen(false);
    },
    onError: () => {
      setIsEditing(false);
      setIsOpen(false);
    },
  });
};
