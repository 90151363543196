import React, { useState } from 'react';
import "./SinglePasswordToggle.css";
import { Form } from "react-bootstrap";

function SinglePasswordToggle({ width = "", placeholder = "Password", ...props }) {
    const [passwordType, setPasswordType] = useState("password");

    const showHidePassword = (e) => {
        e.preventDefault();
        setPasswordType(passwordType === "input" ? "password" : "input")
    }

    return (
        <>
            <Form.Group className="single_password_form" style={{ width: width }}>
                <Form.Control
                    type={passwordType}
                    className="single_password_input"
                    placeholder={placeholder}
                    value={props.value}
                    onChange={(e) => { props.setPassword(e.target.value) }}
                    {...props.register}
                />
                <span className="single_password_show" onClick={showHidePassword}>
                    {passwordType === "input" ? (
                        <i id="eye-icon" className="fa fa-eye"></i>
                    ) : (
                        <i
                            id="eye-icon"
                            className="fa fa-eye-slash fa-flip-horizontal"
                        ></i>
                    )}
                </span>
            </Form.Group>
        </>
    )
}

export default SinglePasswordToggle;
