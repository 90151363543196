import React, { useState } from 'react';

import Img from '../../Icons/no-updates.png';
import PostModal from '../PostModal';

import postlogo from '../../Icons/post-logo.svg';
import * as helpers from '../../global/helpers';
import * as Constants from '../../constants/Constants';

export default function NoUpdates({ postUpdate }) {
  const [showPost, setShowPost] = useState(false);

  const user = localStorage.getItem('KEY_USER_TYPE');
  return (
    <div className='bg-white rounded p-5 shadow my-4  text-center'>
      <img src={Img} alt='np update' className='mb-4' />
      <h1 className='text-sm tracking-normal text-normal font-weight-normal mb-3'>
        There are no update(s) yet.
      </h1>
      {helpers.getUserType() === Constants.USER_TYPE_CONTRIB || user === 'admin' ? null : (
        <>
          <h1 className='text-sm tracking-normal text-normal font-weight-normal mb-3'>
            <span className='d-block'>Post an update. Tell everyone</span>
            <span className='d-block'> what’s new with the project.</span>
          </h1>

          <button
            onClick={() => setShowPost(true)}
            className='py-1 px-3 border-0 text-light rounded mt-3 '
            style={{ backgroundColor: '#7CB1FF' }}
          >
            <img src={postlogo} alt='logo' className='mr-2 ' />
            Post an update
          </button>
        </>
      )}

      <PostModal
        isOpen={showPost}
        setIsOpen={setShowPost}
        postUpdate={postUpdate}
        title={'Update'}
      />
    </div>
  );
}
