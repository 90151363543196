import React, { useState } from "react";
import TrendingOrganizationTab from "./TrendingOrganizationTab";
import TrendingProjectsTab from "./TrendingProjectsTab";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./TrendingOrganizationsAndProjectsTabs.css";

const TrendingOrganizationsAndProjectsTabs = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => setTabIndex(tabIndex)}
            className="trending_tabs"
        >
            <TabList>
                <Tab>Organizations</Tab>
                <Tab>Projects</Tab>
            </TabList>
            <TabPanel>
                <TrendingOrganizationTab />
            </TabPanel>
            <TabPanel>
                <TrendingProjectsTab />
            </TabPanel>
        </Tabs>
    );
};

export default TrendingOrganizationsAndProjectsTabs;