import React, { useEffect, useState } from 'react';
import NoProjects from '../NoProjects/NoProjects';
import './MyProjects.css';
import * as helpers from '../../../../global/helpers';
import * as Constants from '../../../../constants/Constants';

//import ProjectsList from "../ProjectsList/ProjectsList";
//import Download from '../../../../Icons/download.svg';
import { Search } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProjectItem from '../../../../components/reusableComponents/ProjectItem/ProjectItem';
import { home_project_donations, projects_details } from '../../../../constants/AppUrls';
import { useHistory } from 'react-router';

function MyProjects({ isTrending = false, isDonation = false }) {
  const [projects, setProjects] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [filterIsActive, setFilterIsActive] = useState('');

  let history = useHistory();

  console.log('projects', projects);

  useEffect(() => {
    getProjectsLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSearching && projects.length === 0 && page === 0 && !isFinished) {
      getProjectsLocal();
    }
    if (isSearching && projects.length === 0 && page === 0 && !isFinished) {
      callGetProjects(searchText, 0, filterIsActive, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, projects, page, isFinished]);

  useEffect(() => {
    if (filterIsActive !== null) {
      searchAndFilterFunctionality();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsActive]);

  const getProjectsLocal = () => {
    callGetProjects('', page, '');
  };

  const searchProjects = () => {
    callGetProjects(searchText, page, filterIsActive);
  };

  const callGetProjects = (query, pageParam, filterIsActiveParam, isInitialSearch = false) => {
    helpers.getProjects(query, pageParam, filterIsActiveParam, isTrending).then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          if (isInitialSearch) {
            setPage(1);
            setProjects(response.data.data);
          } else {
            let pageLocal = page + 1;
            setPage(pageLocal);
            let projectsLocal = projects.concat(response.data.data);
            setProjects(projectsLocal);
          }
          setIsFinished(true);
          if (response.data.data.length === 0 || response.data.data.length < 10) {
            setHasMore(false);
          } else {
            if (!hasMore) setHasMore(true);
          }
        }
      }
    });
  };
  const searchAndFilterFunctionality = () => {
    if (searchText === '' && filterIsActive === '') {
      if (isSearching) {
        setIsSearching(false);
        setProjects([]);
        setPage(0);
        setIsFinished(false);
        return;
      } else {
        return;
      }
    } else {
      setIsSearching(true);
      setProjects([]);
      setPage(0);
      setIsFinished(false);
    }
  };
  let nextFunc = isSearching ? searchProjects : getProjectsLocal;
  let containerMt =
    helpers.getUserType() === Constants.USER_TYPE_ADMIN ||
    helpers.getUserType() === Constants.USER_TYPE_CONTRIB
      ? '0px'
      : '';
  let isAdminOrContrib =
    helpers.getUserType() === Constants.USER_TYPE_ADMIN ||
    helpers.getUserType() === Constants.USER_TYPE_CONTRIB
      ? true
      : false;
  return (
    <div className='w-100'>
      {helpers.getUserType() === Constants.USER_TYPE_ADMIN ||
      helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? (
        <div className='d-lg-flex justify-content-lg-between align-items-lg-center'>
          <div className='d-lg-flex  align-items-lg-center col-lg-8 px-lg-0 px-sm-0 px-md-0'>  
          {isTrending === true ? 
            ''
          : 
            <div className='d-flex mr-lg-3 relative col-lg-6 mb-md-4 mb-lg-0 mb-sm-4 px-0'>
              <div className='flex-grow-1 mr-2'>
                <Search
                  className='absolute '
                  style={{ top: '15px', left: '20px', color: '#90AD98' }}
                />
                <input
                  placeholder='Search'
                  className='form-control pl-5 bg-white shadow rounded'
                  style={{ color: '#90AD98' }}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              <button
                className='btn btn-success ml-2 mr-4 rounded py-2 px-4'
                onClick={(e) => {
                  e.preventDefault();
                  searchAndFilterFunctionality();
                }}
              >
                search
              </button>
            </div>
          }
            {helpers.getUserType() === Constants.USER_TYPE_ADMIN ? (
              <div className='d-flex justify-content-between align-items-center mb-lg-0 mb-md-4 mb-sm-4 my-xs-4'>
                <label
                  htmlFor='filter_by'
                  className='mb-0'
                  style={{ color: '#90AD98', width: '100px' }}
                >
                  Filter by
                </label>
                <select
                  id='filter_by'
                  className='form-control py-2 pr-5 pl-1 ml-3 shadow border-0 rounded'
                  onChange={(e) => {
                    setFilterIsActive(e.target.value);
                  }}
                  value={filterIsActive}
                >
                  <option value='all'>All</option>
                  <option value='active'>Active</option>
                  <option value='inactive'>Disabled</option>
                </select>
              </div>
            ) : null}
          </div>
          {/* <button className='btn btn-success rounded d-flex align-items-center col-md-12 col-lg-auto'>
                            <img src={Download} alt='logo' className='mr-2  ' />
                            Download CSV
                        </button> */}
        </div>
      ) : null}
      {!isFinished ? (
        <h4 className='loading-label'>Loading...</h4>
      ) : projects !== undefined && projects !== null && projects.length > 0 ? (
        <div
          className='org-projects-section-container col-lg-12'
          style={{ marginTop: containerMt }}
        >
          <InfiniteScroll
            dataLength={projects.length}
            next={nextFunc}
            hasMore={hasMore}
            loader={<h4 className='loading-label'>Loading...</h4>}
            scrollableTarget='scrollableDiv'
          >
            {projects.map((item, index) => {
              return (
                <div
                  className='org-projects-section-item col-lg-6'
                  style={{ cursor: 'pointer' }}
                  key={'OrgProjectsSection-item-' + index}
                  onClick={() => {
                    if (!isDonation)
                      history.push(
                        projects_details.replace(':projectName', item.title).replace(':id', item.id)
                      );
                    else
                      history.push(
                        home_project_donations
                          .replace(':projectName', item.title)
                          .replace(':id', item.id)
                      );
                  }}
                >
                  <ProjectItem
                    projectId={item.id}
                    author={item.by}
                    title={item.title}
                    overview={item.description}
                    amount={item.raisedAmount}
                    totalamount={item.targetAmount}
                    endDate={item.endDate}
                    img={item.image}
                    isActive={item.isActive}
                    hasEnded={item.hasEnded}
                    isOpen={item.isOpen}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <div className='mx-auto mt-5 pt-5'>
          <NoProjects isAdmin={isAdminOrContrib} />
        </div>
      )}
    </div>
  );
}

export default MyProjects;
