import React from "react";

import "./ContributorAccount.css";
import ContributorAccountItem from "../../components/reusableComponents/ContributorAccountItem/ContributorAccountItem";

const ContributorAccount = () => {
  return (
    <ContributorAccountItem />
  );
};

export default ContributorAccount;
