import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.min.css';
import App from './App';
import './index.css';
import './common.css';
import { Provider } from 'react-redux';
import store from './store/store';

import { QueryClientContext } from './reactquery';

const app = (
  <Provider store={store}>
    <QueryClientContext>
      <App />
    </QueryClientContext>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
