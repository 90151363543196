import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import RecurringDonationItem from '../reusableComponents/RecurringDonationItem/RecurringDonationItem';
import RecurringDonationInfoCard from '../reusableComponents/RecurringDonationInfoCard/RecurringDonationInfoCard';
import './RecurringDonation.css';
import { useParams } from "react-router";
import { getData } from "../../global/helpers";
import { BASE_URL } from "../../constants/Constants";
import { useDispatch } from "react-redux";

const RecurringDonation = () => {
    const [donations, setDonations] = useState([])
    const [profile, setProfile] = useState(null)
    const { donationId } = useParams()
    const dispatch = useDispatch();

    useEffect(() => {
        getRecurringDonation();
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRecurringDonation = () => {
        let url = BASE_URL + "/donations/" + donationId + "/details"
        getData(url)
            .then((response) => {
                if (response.status === 200) {
                    setDonations(response.data.data)
                    setProfile(response.data.profile)
                }
            })
    }

    return (
        <div id='recurring_donation-div'>
            <Row className='mx-0 justify-content-between recurring_donation_row'>
                <div className='col-12 col-md-7 px-0 mr-1'>
                    {
                        donations.length > 0
                            ? donations.map((item, index) => {
                                const recurringItemClass = index === 0 ? "" : "mt-2"
                                return <div className={recurringItemClass} key={"recurring-donation-" + index}>
                                    <RecurringDonationItem details={item} />
                                </div>
                            })
                            : null
                    }
                </div>
                <Col xs={12} md={4} className='px-0'>
                    <RecurringDonationInfoCard profile={profile} />
                </Col>
            </Row>
        </div>
    );
};

export default RecurringDonation;
