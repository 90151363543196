import React from 'react';

import imgDefault from "../../../images/img-default.svg"

export default function About({ byOrg, description, orgImg }) {
  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
  }
  return (
    <div className='p-5 bg-white rounded shadow'>
      <div className='d-flex align-items-center'>
        <div>
          <img src={orgImg} alt='org' style={{ width: '100px', borderRadius: "5%" }} onError={addDefaultSrc} />
        </div>
        <div className='ml-5'>
          <p style={{ color: '#90AD98' }} className='tracking-normal '>
            Created by
          </p>
          <h1 className='tracking-normal text-normal text-md mb-4'>
            {byOrg}
          </h1>
        </div>
      </div>

      <div className='mt-5 border-top pt-5'>
        <h1 className='text-sm tracking-normal text-normal mb-3'>Description</h1>
        <p className='text-sm tracking-normal text-normal'>
          {description}
        </p>
      </div>
    </div>
  );
}
