import React, { useEffect, useState } from 'react'
import "./Reviews.css";
import * as helpers from "../../../global/helpers";
import * as Constants from "../../../constants/Constants";
import NoReviews from '../../../components/OrganizationComponents/NoReviews/NoReviews';
import ReviewsList from '../../../components/OrganizationComponents/ReviewsList/ReviewsList';
import ConfirmationModal from '../../../components/reusableComponents/ConfirmationModal/ConfirmationModal';

const Reviews = (props) => {
    const [reviews, setReviews] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [reviewId, setReviewId] = useState(null);

    useEffect(() => {
        if (!isFinished)
            getReviews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReviews = () => {
        let pageSize = 10;
        let url = Constants.REVIEWS + "?limit=10&offset=" + page * pageSize;
        let pageLocal = page + 1;
        setPage(pageLocal)
        helpers.getReviews(url)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        let reviewsLocal = reviews.concat(response.data.data)
                        setReviews(reviewsLocal);
                        if (!isFinished)
                            setIsFinished(true)

                        if (response.data.data.length === 0 || response.data.data.length < pageSize)
                            setHasMore(false);
                    }
                }
            })
    }

    const deleteReview = () => {
        helpers.deleteReview(reviewId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        helpers.successNotify("Review Removed");
                        setReviews(reviews.filter(item => item.id !== reviewId))
                    }
                }
            })
    }

    const openDeleteModal = (reviewId) => {
        setReviewId(reviewId);
        setIsOpen(true);
    }

    return (
        <>
            <div style={{ display: "block", width: "100%" }}>
                {
                    !isFinished
                        ? null
                        : reviews !== undefined && reviews !== null && reviews.length > 0
                            ? <ReviewsList reviews={reviews} getReviews={getReviews} hasMore={hasMore} openDeleteModal={openDeleteModal} />
                            : <NoReviews />
                }
            </div>

            <ConfirmationModal
                title="Remove Review"
                description="You are removing a review left by a user. This will permanently delete it & cannot be undone."
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                submitFunc={deleteReview}
                cancelBtnTitle="Keep"
                submitBtnTitle="Remove"
                type="confirmation-danger"
            />

        </>
    )
}


export default Reviews