import React, { Component } from 'react';
import "./SingleImageUploadComponent.css";
import ReplaceImageLogo from '../../../images/replace-image.png';
import { Form, Button, Image, Row } from "react-bootstrap";

class SingleImageUploadComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: null,
            defaultStyle: null,
            rawFile: null
        }
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.upload = this.upload.bind(this)
    }

    uploadSingleFile(e) {
        let file = URL.createObjectURL(e.target.files[0])
        this.setState({
            file: file,
            rawFile: e.target.files[0]
        })
    }

    getProfilePhoto() {
        return this.state.rawFile
    }

    upload(e) {
        e.preventDefault()
        console.log(this.state.file)
    }


    render() {
        let hideReplaceImageStyle;
        const handleClick = () => {
            this.setState({
                file: null,
                rawFile: null
            })
            hideReplaceImageStyle = {
                display: "none",
            }
        }
        let imgPreview;
        let defaultStyle;
        let replaceImage;
        if (this.state.file) {
            imgPreview = <Image src={this.state.file} className="uploaded_image" />
            defaultStyle = {
                display: "none",
            };
            replaceImage =
                <Row id="replace_image-div">
                    <Image src={ReplaceImageLogo} id="replace_logo-img" />
                    <Button id="replace-button" onClick={handleClick}>Replace Image</Button>
                </Row>
        }
        return (
            <>
                <Form.Group className="replace_image_form" style={hideReplaceImageStyle}>
                    {imgPreview}{replaceImage}
                </Form.Group>
                <Form.Group style={defaultStyle} id="profile_image-div">
                    <Form.Control type="file" className='profile_image_input' onChange={this.uploadSingleFile} />
                </Form.Group>
            </>
        )
    }
}

export default SingleImageUploadComponent;
