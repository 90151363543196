import axios from 'axios';

import {BASE_URL, BASE_URL_V2} from '../constants/Constants';
import {requestInterceptor} from './interceptors/request-interceptor';
import {responseErrorInterceptor, responseSuccessInterceptor} from "./interceptors/response-interceptor";

export const fetchData = axios.create({
    baseURL: BASE_URL,
});

export const fetchDataV2 = axios.create({
    baseURL: BASE_URL_V2
})

fetchData.interceptors.request.use(requestInterceptor);
fetchData.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

fetchDataV2.interceptors.request.use(requestInterceptor);
fetchDataV2.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
