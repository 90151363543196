import React from 'react';
import { BASE_URL } from '../../constants/Constants';
import { trending_projects } from '../../constants/Keys';
import useFetchTrending from '../../hooks/useFetchTrending';
import ProjectItem from '../reusableComponents/ProjectItem/ProjectItem';
import SectionTitle from '../reusableComponents/SectionTitle/SectionTitle';
import './TrendingProjectsSection.css';

const TrendingProjectsSection = (props) => {
  const url = `${BASE_URL}/trending/projects?limit=4`;

  const { data } = useFetchTrending(trending_projects, url);

  return (
    <section id='TrendingProjectsSection' className='mt-5 mb-5'>
      <div className='container'>
        <div className='col-lg-12'>
          <div className='row'>
            <div className='col-lg-8'>
              <SectionTitle title='TRENDING PROJECTS' />
            </div>
          </div>
          {data && data.data.length === 0 ? (
            <h1
              style={{ color: '#67DB88' }}
              className=' text-center tracking-normal text-normal my-5'
            >
              No Trending Projects...
            </h1>
          ) : (
            <div className='row'>
              <div className='trending-projects-section-container col-lg-12'>
                {data &&
                  data.data.map((item) => {
                    return (
                      <div
                        className='trending-projects-section-item col-lg-6'
                        key={'TrendingProjectsSection-item-' + item.id}
                      >
                        <ProjectItem
                          author={item.by}
                          title={item.title}
                          overview={item.description}
                          amount={item.raisedAmount}
                          totalamount={item.targetAmount}
                          endDate={item.endDate}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TrendingProjectsSection;
