import React from 'react';
import VerificationEmailReceived from '../components/VerificationEmailReceived/VerificationEmailReceived'
import VerificationReceivedImage from '../images/verification-email-received.svg';

const verificationEmailReceived = () => {
    return (
        <VerificationEmailReceived
            title={'Verified!'}
            image={VerificationReceivedImage}
            subtitle1={'You have successfully registered. You can log in now.'}
            subtitle2={'Don’t forget to check your spam folder.'}
            showResendEmail={false}
        />
    )
}

export default verificationEmailReceived;