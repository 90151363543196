import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./SpecificProjectsDonation.css";
import { useDispatch } from "react-redux";
import { getData, getProjectById } from "../../global/helpers";
import { useParams } from "react-router";
import InfoCard from "../../pages/SuperAdmin/ProjectDetails/InfoCard";
import { BASE_URL } from "../../constants/Constants";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from "react-router-dom";
import DonationCard from "../SAOrganization/Donations/DonationCard";
import { home_or_org_or_project_donation_summary } from "../../constants/AppUrls";

const ViewSpecificProjectsDonation = () => {
    const [projectDetails, setProjectDetails] = useState(null)
    const [donations, setDonations] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isFinished, setIsFinished] = useState(false);

    const dispatch = useDispatch();

    //const location = useLocation();
    const { id, projectName } = useParams();
    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
        getProjectDetails()
        getDonations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getProjectDetails = () => {
        getProjectById(id)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        console.log(response.data);
                        setProjectDetails(response.data);
                    }
                }

            })

    }

    const getDonations = () => {
        let pageSize = 10;
        let url = BASE_URL + "/projects/" + id + "/donations?imit=10&offset=" + page * pageSize;
        let pageLocal = page + 1;
        setPage(pageLocal)
        getData(url)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        let donationsLocal = donations.concat(response.data.data)
                        setDonations(donationsLocal);

                        if (response.data.data.length === 0 || response.data.data.length < pageSize)
                            setHasMore(false);
                    }
                }
                if (!isFinished)
                    setIsFinished(true)
            })
            .catch((error) => {
                if (!isFinished)
                    setIsFinished(true)
            })
    }

    return (
        <Container className="specific_project_container">
            <Row>
                <Col lg={7} xs={12} md={8}>
                    {
                        !isFinished
                            ? <h4 className="loading-label">Loading...</h4>
                            : donations !== undefined && donations !== null && donations.length > 0
                                ?

                                <InfiniteScroll
                                    className="px-4 pb-4"
                                    dataLength={donations.length}
                                    next={getDonations}
                                    hasMore={hasMore}
                                    loader={<h4 className="loading-label">Loading...</h4>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {
                                        donations.map((item, index) => {
                                            let linkTo = home_or_org_or_project_donation_summary
                                                .replace(":homeOrOrganizationsOrProjects", "home")
                                                .replace(":name", projectName)
                                                .replace(":id", id)
                                                .replace(":donationId", item.id)
                                            return (
                                                <Link
                                                    key={"donation-" + index}
                                                    className='link-reset'
                                                    to={linkTo}
                                                >
                                                    <DonationCard userType={"org"} donationDetails={item} />
                                                </Link>
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                                : <h4 className="center">No donations made to this project</h4>
                    }
                </Col>
                <Col lg={5} xs={12} md={12}>
                    <div className="col-12 p-3 mx-auto">
                        <InfoCard projectDetails={projectDetails} isFromProjectDonation={true} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ViewSpecificProjectsDonation;
