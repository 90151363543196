import { useMutation, useQueryClient } from 'react-query';
import { deleteImage as deletePayment } from '../../src/global/helpers';
import { PaymentsKey } from '../constants/Keys';

export const useRemovePayment = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePayment, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(PaymentsKey);
    },
  });
};
