import React from "react";
import * as helpers from "../global/helpers";
import * as Constants from "../constants/Constants";
import ContributorMainPage from './ContributorMainPage';
import Stats from "./SuperAdmin/Stats";
import DonationsHistory from "../components/DonationsHistory/DonationsHistory";

function HomeWrapper() {
    return (
        helpers.getUserType() === Constants.USER_TYPE_ORG
            ? <DonationsHistory />
            : helpers.getUserType() === Constants.USER_TYPE_CONTRIB
                ? <ContributorMainPage />
                : helpers.getUserType() === Constants.USER_TYPE_ADMIN
                    ? <Stats />
                    : <div>Invalid User</div>

    )
}

export default HomeWrapper;