import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import imgDefault from "../../../images/img-default.svg"

import ConfirmationModal from '../../../components/reusableComponents/ConfirmationModal/ConfirmationModal';
import "./RecurringDonationInfoCard.css";
import { BASE_URL } from '../../../constants/Constants';
import { endRecurringDonation, numberWithCommas } from '../../../global/helpers';
import { useHistory } from 'react-router';

export default function RecurringDonationInfoCard({ profile }) {
    const [isOpen, setIsOpen] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);
    let history = useHistory()

    let profileImg = imgDefault

    const addDefaultSrc = (ev) => {
        ev.target.src = imgDefault;
        setImgLoaded(true)
    }

    let displayImg = imgLoaded ? "inherit" : "none";

    if (profile !== null && profile.image !== undefined && profile.image !== null && profile.image !== "" && profile.image !== "guid") {
        let imgUrl = BASE_URL + "/media/" + profile.image + "/200" //first field: GUID, second is: size
        profileImg = imgUrl;
    }

    let title = profile !== null ? profile.title : ""
    let recurringTypeText = profile !== null ? profile.recurringTypeText : ""
    let amount = profile !== null ? numberWithCommas(profile.amount) : ""
    let total = profile !== null ? numberWithCommas(profile.total) : ""

    const endRecurringDonationLocal = () => {
        endRecurringDonation(profile.id)
            .then((response) => {
                setIsOpen(false)
                history.goBack()
            })
            .catch((error) => {
                setIsOpen(false)
            })
    }

    return (
        <>
            <Card className='rec_info_card'>
                <Card.Body className='rec_info_card_body'>
                    <Card.Title className='supporting_span'>You're supporting</Card.Title>
                    <div className="recurring_aligned">
                        {
                            !imgLoaded
                                ? <div className="recurring_donation-img" style={{ backgroundColor: "white" }} />
                                : null
                        }

                        <img
                            style={{ display: displayImg }}
                            src={profileImg}
                            className="recurring_donation-img" alt=""
                            onError={addDefaultSrc}
                            onLoad={() => { setImgLoaded(true) }} />

                        <span className="recurring_donation-name">{title}</span>
                    </div>
                    <hr className="recurring-hr" />
                    <p className="payment_info_lbl">Payment info</p>
                    <div className="recurring_details-div">
                        <span className="recurring_span">
                            <p className="recurring_type_lbl">Payment Type</p>
                            <p className="recurring_type_txt">{recurringTypeText}</p>
                        </span>
                        <span className="recurring_span">
                            <p className="recurring_amount_lbl">Recurring Amount</p>
                            <p className="recurring_amount_txt">${amount}</p>
                        </span>
                        <span className="recurring_span">
                            <p className="recurring_total_lbl">Total donations</p>
                            <p className="recurring_total_txt">${total}</p>
                        </span>
                    </div>
                    <div className="end_recurring-div">
                        {
                            profile !== null
                                ? profile.isEnded
                                    ? <div className="ended_recurring-div" style={{ paddingTop: "10px", paddingBottom: "10px" }} >
                                        Recurring Donation Ended
                                    </div>
                                    : <button
                                        className="end_recurring-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsOpen(true);
                                        }}
                                    >
                                        End Recurring Donation
                                    </button>
                                : null
                        }

                    </div>
                </Card.Body>
            </Card>
            <ConfirmationModal
                title='End Recurring Donation'
                description='You are ending your recurring donations to this project. Do you want to proceed?'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                cancelBtnTitle='No'
                submitBtnTitle='Yes, end it'
                type='confirmation-danger'
                submitFunc={endRecurringDonationLocal}
            />
        </>
    );
}
