import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/Greenlogo.png";
import "./FinalRegistrationPage.css";
import { verification_email_received } from "../../../constants/AppUrls";

const FinalRegistrationPage = (props) => {
  return (
    <section id="final_registration-section">
      <div className="final_registration_div">
        <div className="final_registration_inner">
          <div className="final_registration_item">
            <div id="final_registration_nav-div">
              <div className="row">
                <div className="col-lg-6" id="nav_first_col-div"></div>
                <div className="col-lg-6" id="nav_second_col-div">
                  <div className="container text-right">
                    <p className="contact_us_link">Contact Us</p>
                  </div>
                </div>
              </div>
              <div className="text-center" id="nav_logo-div">
                <img src={Logo} className="mx-auto sadaqa-logo" alt="" />
              </div>
            </div>
            <div id="main-div">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="justify-content-center">
                    <div className="text-center">
                      <img src={props.image} alt="" />
                      <h4 className="subtitle">{props.title}</h4>
                      <p className="subtitle1">{props.subtitle1}</p>
                      <p className="subtitle2">{props.subtitle2}</p>
                      <Link className="to_login" to="/Login">
                        <button
                          id="back_to_login-btn"
                          className="btn btn-primary btn-block"
                        >
                          Back to log in
                        </button>
                      </Link>
                      <Link
                        className="to_verification"
                        to={verification_email_received}
                      >
                        <span
                          style={
                            props.showResendEmail ? {} : { display: "none" }
                          }
                          className="resend_email"
                        >
                          Resend email
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinalRegistrationPage;
