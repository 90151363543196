import React, { useEffect, useState } from "react";

import { Button, Col, Form, Image, Row } from "react-bootstrap";
import topBg from "../../images/AccountBG.png"
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import states from "../../states";
import { useHistory } from "react-router-dom";
import * as AppUrls from "../../constants/AppUrls";
import * as Constants from "../../constants/Constants";
import * as helpers from "../../global/helpers";
import ReplaceImageLogo from '../../images/replace-image.png';
import PhoneInput from "react-phone-input-2";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip code is required").matches(/^\d{5}(-\d{4})?$/, "Must match 12345 or 12345-1234"),
  streetAddress: yup.string().required("Street address is required"),
  stateSelection: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .nullable()
    .required("State is required")
});

export const ContribAccountEdit = (props) => {
  let history = useHistory();

  const statesOptions = states;

  const [isFormDisabled, setIsFormDisable] = useState(false);

  const [profileImg, setProfileImg] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [rawImageFile, setRawImageFile] = useState(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [phoneNumberS, setPhoneNumberS] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const { formState: { errors }, control, handleSubmit, setValue, register, trigger } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getProfile() {
    setIsFormDisable(true)
    helpers.getProfile()
      .then((response) => {
        setIsFormDisable(false)
        if (response !== undefined) {
          if (response.status === 200) {
            let profile = response.data;
            setValue("firstName", profile.firstName !== null ? profile.firstName : "")
            setValue("lastName", profile.lastName !== null ? profile.lastName : "")
            setValue("city", profile.city !== null ? profile.city : "")
            let profileImgLocal = null;
            if (profile.imageGuid !== undefined && profile.imageGuid !== null && profile.imageGuid !== "" && profile.imageGuid !== "guid") {
              let imgUrl = Constants.BASE_URL + "/media/" + profile.imageGuid + "/200" //first field: GUID, second is: size
              profileImgLocal = imgUrl;
            }
            setProfileImg(profileImgLocal);

            let stateLocal = {
              value: profile.state.abbrev,
              label: profile.state.value
            }
            setValue("stateSelection", stateLocal);

            let fullPhoneNumberFromApi = profile.fullPhoneNumber.replace(/[+]/g, '')
            setValue("phoneNumber", fullPhoneNumberFromApi)
            setCountryCode(profile.areaCode)
            setPhoneNumberS(profile.phoneNumber)

            setValue("streetAddress", profile.streetAddress !== null ? profile.streetAddress : "")
            setValue("zipCode", profile.zipCode !== null ? profile.zipCode : "")
          }
        }
      })
      .catch((error) => {
        setIsFormDisable(false)
      })
  }

  const stateSelectionStyles = {
    control: styles => {
      return {
        ...styles,
        borderColor: !errors.stateSelection ? '' : 'red !important'
      };
    }
  };

  function replaceImg(e) {
    e.preventDefault();
    setImageFile(null);
    setProfileImg(null);
  }

  function uploadImgFile(e) {
    if (e.target.files[0] !== undefined) {
      let file = URL.createObjectURL(e.target.files[0])
      setImageFile(file);
      setRawImageFile(e.target.files[0])
    }
  }

  const onSubmit = (data) => {
    console.log("rawImage: ", rawImageFile)
    if (rawImageFile !== null)
      getImageGUID(data, rawImageFile)
    else editAccount(data, null)
  };

  function getImageGUID(data, rawImageFile) {
    setIsFormDisable(true)

    const formData = new FormData();
    formData.append(
      "file", rawImageFile
    );

    helpers.uploadMedia(formData)
      .then((response) => {
        if (response.status !== undefined && response.status === 201) {
          console.log("guid: ", response.data.guid)
          editAccount(data, response.data.guid)
        }
      })
      .catch((error) => {
        setIsFormDisable(false)
      });
  }

  function editAccount(data, profilePhotoGUID) {
    setIsSavingProfile(true)

    let postdata = {
      firstName: data.firstName,
      lastName: data.lastName,
      areaCode: countryCode,
      phoneNumber: phoneNumberS,
      city: data.city,
      stateAb: data.stateSelection.value,
      streetAddress: data.streetAddress,
      zipCode: data.zipCode,
    }

    if (profilePhotoGUID !== undefined) {
      postdata = { ...postdata, imageGuid: profilePhotoGUID }
    }

    helpers.editOrg(postdata)
      .then((response) => {
        setIsSavingProfile(false)
        setIsFormDisable(false)
        if (response !== undefined) {
          if (response.status === 200) {
            helpers.successNotifyWithTitle("Saved", "Your changes have been saved.")
            history.push(AppUrls.account)
          }
        }

      })
      .catch((error) => {
        setIsSavingProfile(false)
        setIsFormDisable(false)
      });

  }

  let imageInputDisplay = profileImg !== null || imageFile !== null ? "none" : "block";

  return (
    <div className="org-account-container edit-account" >
      <img className="org-account-top-bg" src={topBg} alt="bg" />
      <Form style={{ paddingBottom: "60px", paddingTop: "50px" }} onSubmit={handleSubmit(onSubmit)} >
        <fieldset disabled={isFormDisabled}>
          <Row className="justify-content-center">
            <Col lg={4}>
              <Form.Group >
                <Form.Label className="reg_org_lbl">Profile Picture</Form.Label>
                <>
                  {
                    profileImg !== null || imageFile !== null
                      ? <Form.Group className="replace_image_form" >
                        <Image src={profileImg || imageFile} className="uploaded_image" />
                        <Row id="replace_image-div">
                          <Image src={ReplaceImageLogo} id="replace_logo-img" />
                          <Button id="replace-button" onClick={replaceImg}>Replace Image</Button>
                        </Row>
                      </Form.Group>
                      : null
                  }

                  <Form.Group style={{ display: imageInputDisplay }} id="profile_image-div">
                    <Form.Control type="file" className='profile_image_input' onChange={uploadImgFile} />
                  </Form.Group>
                </>
              </Form.Group>
            </Col>
            <Col lg={4} />
          </Row>

          <Row className="justify-content-center">
            <Col lg={4}>

              <Form.Group className="reg_org_lbl">
                <Form.Label>First Name</Form.Label><span className="required_field"> *</span>
                <Form.Control
                  id="firstName"
                  name="firstName"
                  className="input_field"
                  style={{
                    borderColor: !errors.firstName || props.firstName ? '' : 'red'
                  }}
                  {...register('firstName')}
                />
                {errors.firstName && <p className="org_signup_error_step_1">{errors.firstName.message}</p>}
              </Form.Group>

              <Form.Group className="reg_org_lbl">
                <Form.Label>Last Name</Form.Label><span className="required_field"> *</span>
                <Form.Control
                  id="lastName"
                  name="lastName"
                  className="input_field"
                  style={{
                    borderColor: !errors.lastName || props.lastName ? '' : 'red'
                  }}
                  {...register('lastName')}
                />
                {errors.lastName && <p className="org_signup_error_step_1">{errors.lastName.message}</p>}
              </Form.Group>

              <Form.Group>
                <Form.Label className="reg_org_lbl">Your Phone Number</Form.Label><span className="required_field"> *</span>
                <Controller
                  id="phoneNumber"
                  name="phoneNumber"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      onChange={(value, country) => {
                        console.log(value)
                        setValue("phoneNumber", value)
                        trigger("phoneNumber")
                        setCountryCode(country.dialCode)
                        setPhoneNumberS(value.slice(country.dialCode.length))
                      }}
                      inputStyle={{
                        borderColor: !errors.phoneNumber ? '' : 'red'
                      }}
                      buttonStyle={{
                        borderColor: !errors.phoneNumber ? '' : 'red'
                      }}
                      country={'us'}
                      placeholder='(702) 123-4567'
                    />
                  )}

                />

                {errors.phoneNumber && <p className="org_signup_error_step_1">{errors.phoneNumber.message}</p>}
              </Form.Group>

            </Col>
            <Col lg={4}>
              <Form.Group className="reg_org_lbl">
                <Form.Label>Street Address</Form.Label><span className="required_field"> *</span>
                <Form.Control
                  id="streetAddress"
                  name="streetAddress"
                  className="input_field"
                  style={{
                    borderColor: !errors.streetAddress || props.streetAddress ? '' : 'red'
                  }}
                  {...register('streetAddress')}
                />
                {errors.streetAddress && <p className="org_signup_error_step_1">{errors.streetAddress.message}</p>}
              </Form.Group>

              <Form.Group>
                <Form.Label className="reg_org_lbl">
                  State/province <span className="required_field"> *</span>
                </Form.Label>
                <Controller
                  className="supportingCauseSelector"
                  name="stateSelection"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      maxMenuHeight={150}
                      options={statesOptions.map((state) => {
                        return {
                          value: state.value,
                          label: state.label
                        }
                      }
                      )}
                      styles={stateSelectionStyles}
                    />
                  )}
                />
                <p className="org_signup_error_step_2">{errors.stateSelection?.message}</p>
              </Form.Group>

              <div className="city_zip-div">
                <Form.Group>
                  <Form.Label className="individual_signup_label">
                    City <span className="required_field"> *</span>
                  </Form.Label>
                  <Form.Control
                    id="city"
                    className="input_field city_txt"
                    name="city"
                    style={{
                      borderColor: !errors.city?.message ? '' : 'red'
                    }}
                    {...register('city')}
                  />
                  {errors.city && <p className="org_signup_error_step_2">{errors.city.message}</p>}
                </Form.Group>

                <Form.Group className="">
                  <Form.Label className="individual_signup_label">Zip Code <span className="required_field"> *</span></Form.Label>
                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        id="zip_code"
                        className="input_field zip_code_txt"
                        name="zipCode"
                        style={{
                          borderColor: !errors.zipCode ? '' : 'red'
                        }}
                      />
                    )}
                    name="zipCode"
                    control={control}
                    defaultValue=""
                  />
                  {errors.zipCode && <p className="individual_signup_error_step_1">{errors.zipCode.message}</p>}
                </Form.Group>
              </div>

              <Row className="px-0 mx-0 mt-5" style={{ width: "100%", maxWidth: "284px" }}>
                <Col lg={6} md={6} sm={6} xs={4} style={{ textAlign: "left" }}>
                  <button className="crn-discard mt-2" type="button" onClick={(e) => {
                    e.preventDefault();
                    history.push(AppUrls.account);
                  }}>
                    Discard
                  </button>
                </Col>
                <Col lg={6} md={6} sm={6} xs={8} style={{ textAlign: "left" }}>
                  <button
                    type="submit"
                    className="crn-create"
                  >
                    {
                      isSavingProfile
                        ? "Saving..."
                        : "Save Changes"
                    }
                  </button>
                </Col>
              </Row>
            </Col>

          </Row>
        </fieldset>
      </Form>

    </div >
  )
}