import React, { useState } from 'react';
import { numberWithCommas } from '../../../global/helpers';
import DonationModal from '../DonationModal';

export default function DonationItem({ isDonationPerOrg, item, number, itemImage, donationsPerProjectsLocal }) {
    const [isOpen, setIsOpen] = useState(false);
    let donationPerOrgClickStyle = isDonationPerOrg ? ' cursor-pointer ' : ' '
    let amount = item.amount;
    let amountWithCommas = numberWithCommas(amount)
    return (
        <div className={'d-flex justify-content-between align-items-center mt-2 py-4 border-bottom'} >
            <div className='d-flex  align-items-center'>
                <h1 className='text-sm text-capitalize text-secondary mb-0'>#{number}</h1>
                {
                    isDonationPerOrg
                        ? <img src={itemImage} alt='logo' className='ml-2'
                            style={{ width: '40px', height: "40px", objectFit: "cover", borderRadius: "20px", border: "1px solid #67DB88" }} />
                        : null
                }
                <h1
                    className={'text-sm text-capitalize ml-3' + donationPerOrgClickStyle + 'mb-0 tracking-normal'}
                    onClick={() => {
                        if (isDonationPerOrg)
                            setIsOpen(true)
                    }}
                >
                    {item.title}
                </h1>
            </div>
            <h1 className='text-sm text-capitalize mb-0'>${amountWithCommas}</h1>
            <DonationModal
                title={item.title}
                image={itemImage}
                amount={amount}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                donationsPerProjectsLocal={donationsPerProjectsLocal} />
        </div >
    )

}
