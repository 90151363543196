import React from 'react';
import { Spinner } from 'react-bootstrap';

export const SpinnerDiv = ({ color }) => {
  return (
    <div className=' mt-3 text-center'>
      <Spinner animation='border' role='status' style={{ color: color }} />
    </div>
  );
};
