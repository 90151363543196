import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';

import { XLg } from 'react-bootstrap-icons';
import Green from '../../../Icons/modal-bg.png';

export default function EditPostModal({ updateId, value, isOpen, setIsOpen, editUpdate }) {
  const [textarea, setTextarea] = useState(value);
  const [textError, setTextError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (textarea.length === 280) setTextError('You have exceeded the 280 characters');
    else setTextError('');
    if (textarea.length === 0) {
      if (!isDisabled)
        setIsDisabled(true)
    } else {
      if (isDisabled)
        setIsDisabled(false)
    }
  }, [textarea, isDisabled]);

  const onSaveClicked = () => {
    editUpdate(updateId, textarea, setIsOpen)
  }

  return (
    <Modal show={isOpen} onHide={setIsOpen} centered>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0 mb-3 relative'>
        <Modal.Title>Edit Update</Modal.Title>
        <div className=' text-success '>
          <XLg
            style={{ zIndex: '2' }}
            className='relative cursor-pointer'
            onClick={() => setIsOpen(false)}
          />
        </div>
        <img
          alt='background'
          src={Green}
          className='absolute'
          style={{ top: '0px', right: '0px' }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className='relative'>
          <textarea
            value={textarea}
            onChange={(e) => setTextarea(e.target.value)}
            className='border-success p-2 rounded'
            placeholder='Write your update'
            rows='6'
            maxlength='280'
            style={{ resize: 'none', backgroundColor: '#F1FBF0', width: '100%' }}
          ></textarea>
          <span className='absolute text-success' style={{ bottom: '.5rem', right: '.5rem' }}>
            {textarea ? textarea.length : 0}/280
          </span>
        </div>
        {textError && <p className='text-danger mt-2 mb-3'>{textError}</p>}
        <div className='mt-2 d-flex justify-content-between'>
          <button
            className='btn btn-outline-success rounded text-capitalize text-sm'
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button className='btn btn-success border-success rounded text-capitalize text-sm' disabled={isDisabled} onClick={(e) => {
            e.preventDefault();
            onSaveClicked();
          }}>
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
