import React from 'react';
import { Row } from 'react-bootstrap';
// import donateProjectVector from '../../../images/projectItemImage.png';
import money from '../../../images/money.svg';
import './DonateProjectItem.css';
import { BASE_URL } from '../../../constants/Constants';

const DonateProjectItem = (props) => {
    const { title, amount, recurringText, nextDonationAt, profileImage, by, recurring } = props;
    let src = profileImage ? `${BASE_URL}/media/${profileImage}/200` : profileImage ;

    return (
        <div className='donate-project-item'>
            <Row className="donate-project-item-row">
                <div className="donate-project-col-one">
                    <div 
                        className="conatiner"
                        style={{ 
                            position: 'relative',
                            width: 96,
                            height: 96,
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>
                        <img
                            src={src}
                            alt=""
                            className='donate_project_logo'
                        />         
                        <div className="donate_project_logo_overlay">
                            <div className="donate_project_logo_overlay-text mx-auto">
                                {recurringText}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="donate-project-col-two">
                    <p className="donate_project_name">{title}</p>
                    <p className="donate_by">by<span className="donate_by_span">{by}</span></p>
                    <div className="donate_value-div">
                        <img src={money} alt="money" className="project_money_img mr-2" />
                        <p className="donate_value-p">{amount}</p>
                    </div>
                </div>
                <div className={recurring === null ? 'donate-project-col-three d-none' : 'donate-project-col-three d-inline'}>
                    <div className="project_donation_date-div">
                        <p className="project_donation_date-lbl">Next Donation:</p>
                        <p className="project_donation_date-txt">{nextDonationAt}</p>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default DonateProjectItem;