export let login = '/Login'; //TODO: Replace All Remaining static URls
export let register_individual = '/RegisterIndividual';
export let register_organization = '/RegisterOrganization';
export let pending_registration = '/PendingRegistration';
export let registration_success = '/RegistrationSuccess';
export let verification_email_received = '/VerificationEmailReceived';
export let forgot_password = '/ForgotPassword';
export let reset_password = '/reset_password';
export let reset_password_success = '/ResetPasswordSuccess';

export let any = '/';
export let home = '/home';
export let organizations = '/organizations';
export let organizations_details = '/organizations/:orgName/:id';
export let home_or_org_or_project_donation_summary =
  '/:homeOrOrganizationsOrProjects/:orgName/:id/donation_summary/:donationId';
export let org_or_project_recurring_donations =
  '/:organizationsOrProjects/:orgName/:id/recurring_donation/:donationId';
export let org_or_project_recurring_donations_summary =
  '/:organizationsOrProjects/:orgName/:id/recurring_donation/:donationId/donation_summary';

export let projects = '/projects';
export let projects_details = '/projects/:projectName/:id';
export let projects_details_from_org = '/organizations/:orgName/:orgId/:projectName/:id';

export let donate = '/donate';
export let donate_recurring_donations = '/donate/recurring_donations';
export let donate_one_time_donations = '/donate/one_time_donations';
export let donate_donations_history = '/donate/donations_history';

export let home_project_donations = '/home/:projectName/:id';
//export let home_donation_summary = '/home/:name/:id/donation_summary/:donationId';
export let home_org_donation_summary = '/home/donation_summary/:donationId';

export let org_create_new_project = '/projects/create_new_project';
export let org_reviews = '/org_reviews';
export let org_edit_project = '/projects/:projectName/:id/edit';

export let projects_about = '/projects/about';
export let projects_images = '/projects/images';
export let projects_updates = '/projects/updates';
export let projects_donations = '/projects/donations';

export let trending = '/home/trending';
export let ContributorOrganizationPage = '/home/specific_organization';

export let donations = '/donations';

export let account = '/account';
export let edit_account = '/account/edit';
export let change_password = '/account/change_password';

// SUPER ADMIN
export const updates = '/updates';
export const contributors = '/contributors';
//export const OrgAdmin = '/organizationsAdmin';
//export const LinkAdmin = '/organizationsAdmin/Humane_Society';
export const ProjectDetailsAdmin = '/organizationsAdmin/Humane_Society/Save_&_Protect_Baby_Turtles';
export const DonationSummaryAdmin = '/organizations/Humane_Society/Donation_Summary';

export const specific_project_donations = '/home/specificProjectDonations';
export const recurring_donations_details = '/organizations/recurringDonationDetails';
export const recurring_donations_summary =
  '/organizations/recurringDonationDetails/Donation_Summary';

export const donate_to_organization = '/organizations/:orgName/:orgId/donation';
export const donate_to_project = '/projects/:projectName/:projectId/donation';

export const donate_to_organization_summary =
  '/organizations/:orgName/:orgId/donation_summary/:donationId';
export const donate_to_project_summary =
  '/projects/:projectName/:projectId/donation_summary/:donationId';

export const donate_to_donation_summary = '/donation/summary';
