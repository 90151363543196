import React from "react";
import "./NoReviews.css"
import ic_no_reviews from "../../../images/ic_no_reviews.png";

function NoReviews() {

    return (
        <div className="no-projects-container">
            <img className="no-projects-img" src={ic_no_reviews} alt="No Reviews" />
            <div className="no-projects-text-1">
                There are no reviews yet.
            </div>
        </div>
    )
}

export default NoReviews;