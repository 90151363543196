import React from "react";
import "./DonationsHistory.css";
import DonationsHistoryTabs from "./DonationsHistoryTabs";
const DonationsHistory = () => {
    return (
        <DonationsHistoryTabs
            className="donations_history_tabs"
        />
    );
};

export default DonationsHistory;
