import React, { useEffect, useState } from 'react';
import "./InfoCard.css"
import { Dropdown } from 'react-bootstrap';

import { ThreeDots, XLg } from 'react-bootstrap-icons';
import Green from '../../../Icons/green2.svg';

import Disable from '../../../Icons/disable.svg';
import Activate from '../../../Icons/activate.svg';

import Mail from '../../../Icons/mail.svg';
import Location from '../../../Icons/location.svg';
import Phone from '../../../Icons/phone.svg';

import ConfirmationModal from "../../../components/reusableComponents/ConfirmationModal/ConfirmationModal";

import * as helpers from "../../../global/helpers";

export default function InfoCard({ show, setShow, contribId, name, img, isActive, addDefaultSrc, disableActivateContrib }) {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExit = (e) => {
    e.stopPropagation();
    setShow(false);
  };

  const getDetails = () => {

    helpers.getContribDetails(contribId)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            setEmail(response.data.email)
            setPhoneNumber(response.data.fullPhoneNumber)
            let addressLocal = helpers.getAddress(response.data);
            setAddress(addressLocal);
          }
        }
      })
  }

  const disableActivateContribLocal = () => {
    disableActivateContrib(contribId, isActive, setShowModal)
  }

  let disableDesc = "<span>You are disabling <b>" + name + "</b>. Disabled account will not be able to log in anymore.</span>"
  return (
    <div
      className={`${show === false ? 'd-none' : ''} absolute p-3 bg-white shadow rounded contrib-info-card`}
      style={{ top: '30px', right: '-2px', width: '93%', zIndex: '10', minHeight: '50vh', cursor: "default" }}
    >
      <img
        src={Green}
        alt='green'
        className='absolute'
        style={{ top: '0px', left: '0px', zIndex: '-10' }}
      />
      <div className='text-right text-success'>
        <XLg onClick={handleExit} style={{ cursor: "pointer" }} />
      </div>
      <div>
        <Dropdown className='ml-2'>
          <Dropdown.Toggle
            variant='light'
            id='dropdown-basic'
            style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
          >
            <ThreeDots style={{ color: '#7CB1FF', fontSize: '24px' }} />
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
            <Dropdown.Item onClick={() => setShowModal(true)}>
              {
                isActive
                  ? <>
                    <span>
                      <img src={Disable} alt='edit' className='mr-2' />
                    </span>
                    Disable User
                  </>
                  : <>
                    <span>
                      <img src={Activate} alt='edit' className='mr-2' />
                    </span>
                    Activate User
                  </>
              }

            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='text-center'>
        <img src={img} alt='Human' style={{ width: "129px", height: "129px", borderRadius: "50%" }} onError={addDefaultSrc} />
        <h1 className='text-capitalize text-md mt-5 mb-4'>{name}</h1>
        {isActive ? (
          <button
            className='bg-white px-4 py-1 mr-1 rounded '
            style={{ border: '2px solid #7CB1FF', color: '#7CB1FF', cursor: "default" }}
          >
            Active
          </button>
        ) : (
          <button
            className=' px-4 py-1 mr-1 rounded '
            style={{ border: '2px solid #FF7E7F', color: 'white', backgroundColor: '#FF7E7F', cursor: "default" }}
          >
            Disabled
          </button>
        )}
      </div>
      <div className='pr-3 pl-4 mt-5'>
        <h1
          className='text-capitalize text-sm mb-3'
          style={{ color: '#90AD98', fontWeight: 'normal' }}
        >
          Contact
        </h1>
        <div className='d-flex align-items-center mb-3'>
          <img src={Mail} alt='mail' />
          <h1 className='tracking-normal text-normal text-sm ml-2 mb-0'>{email}</h1>
        </div>
        <div className='d-flex align-items-center mb-3'>
          <img src={Phone} alt='phone' />
          <h1 className='tracking-normal text-normal text-sm ml-2 mb-0'>{phoneNumber}</h1>
        </div>
        <div className='d-flex align-items-center mb-3'>
          <img src={Location} alt='location' />
          <p className='text-normal tracking-normal text-sm ml-2 mb-0'>
            {address}
          </p>
        </div>
      </div>

      {
        isActive
          ? <ConfirmationModal
            cancelBtnTitle="keep"
            description={disableDesc}
            isOpen={showModal}
            setIsOpen={setShowModal}
            submitBtnTitle="Disable"
            submitFunc={disableActivateContribLocal}
            title="Disable"
          />
          : <ConfirmationModal
            cancelBtnTitle="keep"
            description="You are activating the account of this user. They will get access to their account and be able to donate again."
            isOpen={showModal}
            setIsOpen={setShowModal}
            submitBtnTitle="Activate"
            submitFunc={disableActivateContribLocal}
            title="Activate Account"
            type="confirmation-primary"
          />
      }

    </div>
  );
}
