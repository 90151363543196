import React, { useState } from 'react';
import logo from '../../../images/logo.png';
import './SideBar.css';
import SubMenu from './SubMenu';

function SideBar(props) {
  const pkg = require('../../../../package.json');
  const appVersion = pkg.version;
  const [activeId, setActiveId] = useState('1');

  const { toggle, setToggle } = props;

  return (
    <div className={`sidebar ${toggle ? '' : 'mobile-hidden'}`}>
      <img src={logo} alt='' className='sidebar-logo' />
      <ul className='sidebarlist' onClick={() => setToggle(!toggle)}>
        {props.SideBarData.map((item, index) => {
          return (
            <SubMenu
              item={item}
              key={'side-bar-item-' + index}
              activeId={activeId}
              setActiveId={setActiveId}
            />
          );
        })}
      </ul>
      <div className='sidebar-version'>v{appVersion}</div>
    </div>
  );
}

export default SideBar;
