import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/OpeningHome';
import Login from './pages/login';
import RegisterIndividual from './pages/register';
import registerOrganization from './pages/registerOrganization';
import ProjectUpdates from './pages/ContributorProjects/ProjectUpdates';
import ProjectImages from './pages/ContributorProjects/ProjectImages';
import pendingRegistration from './pages/pendingRegistration';
import registrationSuccess from './pages/registrationSuccess';
import verificationEmailReceived from './pages/verificationEmailReceived';
import TrendingOrganizationsAndProjectsPage from './pages/TrendingOrganizationsAndProjectsPage';

// import ContributorOrganizationPage from './pages/ContributorOrganizationPage'
import recurringDonationPage from './pages/RecurringDonationPage';

import * as Constants from './constants/Constants';
import * as AppUrls from './constants/AppUrls';
import * as helpers from './global/helpers';

import ForgotPassword from './pages/ForgotPassword';
import ResetPasswordPage from './pages/resetPassword';
import resetPasswordSuccess from './pages/resetPasswordSuccess';
import RecurringDonationsWrapper from './pages/Contributor/RecurringDonations/RecurringDonationsWrapper';
import DonateDonationsHistoryPage from './pages/DonateDonationsHistoryPage';
import CreateNewProject from './pages/Organization/Projects/CreateNewProject/CreateNewProject';
import ChangePassword from './components/reusableComponents/ChangePassword/ChangePassword';
import Reviews from './pages/Organization/Reviews/Reviews';

import PrivateRoute from './PrivateRoute';
import HomeWrapper from './pages/HomeWrapper';
import AccountWrapper from './pages/AccountWrapper';

//SUPER ADMIN
import Updates from './pages/SuperAdmin/Updates';
import Contributors from './pages/SuperAdmin/Contributors';
import ProjectsWrapper from './pages/ProjectsWrapper';
import EditAccountWrapper from './pages/EditAccountWrapper';
import DonationSummary from './pages/SuperAdmin/DonationSummary';
import ProjectDetails from './pages/SuperAdmin/ProjectDetails';
import ProjectDetailsWrapper from './pages/ProjectDetailsWrapper';
import EditProject from './pages/Organization/Projects/EditProject/EditProject';
import OrganizationWrapper from './pages/OrganizationWrapper/OrganizationWrapper';
import OrganizationDetailsWrapper from './pages/OrganizationDetailsWrapper/OrganizationDetailsWrapper';
import ViewSpecificProjectsDonation from './components/DonationsHistory/SpecificProjectsDonation';

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* Auth Routes */}
          <Route path={AppUrls.login} exact component={Login} />
          <Route path={AppUrls.register_individual} exact component={RegisterIndividual} />
          <Route path={AppUrls.register_organization} exact component={registerOrganization} />
          <Route path={AppUrls.pending_registration} exact component={pendingRegistration} />
          <Route path={AppUrls.registration_success} exact component={registrationSuccess} />
          <Route
            path={AppUrls.verification_email_received}
            exact
            component={verificationEmailReceived}
          />
          <Route path={AppUrls.forgot_password} exact component={ForgotPassword} />
          <Route path={AppUrls.reset_password} exact component={ResetPasswordPage} />
          <Route path={AppUrls.reset_password_success} exact component={resetPasswordSuccess} />

          {/* Org User Type */}

          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.home_org_donation_summary}
            userType={Constants.USER_TYPE_ORG}
            exact
          />

          <PrivateRoute
            component={CreateNewProject}
            path={AppUrls.org_create_new_project}
            userType={Constants.USER_TYPE_ORG}
            exact
          />

          <PrivateRoute
            component={Reviews}
            path={AppUrls.org_reviews}
            userType={Constants.USER_TYPE_ORG}
            exact
          />

          <PrivateRoute
            component={EditProject}
            path={AppUrls.org_edit_project}
            userType={Constants.USER_TYPE_ORG}
            exact
          />

          <PrivateRoute
            component={ViewSpecificProjectsDonation}
            path={AppUrls.home_project_donations}
            userType={Constants.USER_TYPE_ORG}
            exact
          />

          {/* Contrib User Type */}

          <PrivateRoute
            component={recurringDonationPage}
            path={AppUrls.org_or_project_recurring_donations}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />

          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.org_or_project_recurring_donations_summary}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />

          <PrivateRoute
            component={() => RecurringDonationsWrapper({ isRecurring: true })}
            path={AppUrls.donate_recurring_donations}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={() => RecurringDonationsWrapper({ isRecurring: false })}
            path={AppUrls.donate_one_time_donations}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={DonateDonationsHistoryPage}
            path={AppUrls.donate_donations_history}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={RecurringDonationsWrapper}
            path={AppUrls.donate_to_organization}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={RecurringDonationsWrapper}
            path={AppUrls.donate_to_project}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={ProjectUpdates}
            path={AppUrls.projects_updates}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={ProjectImages}
            path={AppUrls.projects_images}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={TrendingOrganizationsAndProjectsPage}
            path={AppUrls.trending}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={recurringDonationPage}
            path={AppUrls.recurring_donations_details}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          {/* <PrivateRoute
            component={ContributorOrganizationPage}
            path={AppUrls.ContributorOrganizationPage}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          /> */}

          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.recurring_donations_summary}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.donate_to_organization_summary}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.donate_to_project_summary}
            userType={Constants.USER_TYPE_CONTRIB}
            exact
          />
          {/* Super Admin User Type */}
          <PrivateRoute
            component={Updates}
            path={AppUrls.updates}
            userType={Constants.USER_TYPE_ADMIN}
            exact
          />
          <PrivateRoute
            component={Contributors}
            path={AppUrls.contributors}
            userType={Constants.USER_TYPE_ADMIN}
            exact
          />
          <PrivateRoute
            component={ProjectDetails}
            path={AppUrls.ProjectDetailsAdmin}
            userType={Constants.USER_TYPE_ADMIN}
            exact
          />
          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.DonationSummaryAdmin}
            userType={Constants.USER_TYPE_ADMIN}
            exact
          />

          {/* Common Routes*/}
          <Route
            path={AppUrls.any}
            exact
            render={() =>
              helpers.getAccessToken() !== undefined && helpers.getAccessToken() !== null ? (
                <Redirect to={AppUrls.home} />
              ) : (
                <Home />
              )
            }
          />
          <PrivateRoute
            component={AccountWrapper}
            path={AppUrls.account}
            userType={Constants.USER_TYPE_ALL}
            exact
          />
          <PrivateRoute
            component={HomeWrapper}
            path={AppUrls.home}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={ProjectsWrapper}
            path={AppUrls.projects}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={OrganizationWrapper}
            path={AppUrls.organizations}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={OrganizationDetailsWrapper}
            path={AppUrls.organizations_details}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={DonationSummary}
            path={AppUrls.home_or_org_or_project_donation_summary}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={EditAccountWrapper}
            path={AppUrls.edit_account}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={ProjectDetailsWrapper}
            path={AppUrls.projects_details}
            userType={Constants.USER_TYPE_ALL}
            exact
          />

          <PrivateRoute
            component={ProjectDetailsWrapper}
            path={AppUrls.projects_details_from_org}
            userType={Constants.USER_TYPE_ALL}
            exact
          />
          <PrivateRoute
            component={ChangePassword}
            path={AppUrls.change_password}
            userType={Constants.USER_TYPE_ALL}
            exact
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
