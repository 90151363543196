import React from 'react';
import { Link } from 'react-router-dom';
import { org_or_project_recurring_donations_summary } from '../../../constants/AppUrls';
import { getDateFromUnix, getTimeFromUnix } from '../../../global/helpers';
import "./RecurringDonationItem.css";
import { useLocation, useParams } from 'react-router';
export default function ReurringDonationItem({ details }) {
    let location = useLocation();
    let paths = location.pathname.split("/");
    let organizationsOrProjects = paths[1];
    const { orgName, id } = useParams()

    let link = org_or_project_recurring_donations_summary
        .replace(":organizationsOrProjects", organizationsOrProjects)
        .replace(":orgName", orgName)
        .replace(":id", id)
        .replace(":donationId", details.id)

    return (
        <div
            id="org_recurring_donation_item"
            className='px-md-5 px-3 py-4 d-flex justify-content-between'
        >
            <div className='d-flex align-items-center'>
                <span className='tracking-normal text-normal text-md when_recurring_donation'>
                    <p>{details.tag}</p>
                </span>
            </div>
            <div className='d-flex align-items-center recurring_donation_duration'>
                <p className="recurring_donation_hour mr-2">{getTimeFromUnix(details.createdAt)}</p>
                <p className='recurring_donation_date mr-2'>{getDateFromUnix(details.createdAt)}</p>
                <Link className="view_summary-a" to={link}>
                    <button className="view_summary-btn">View Summary</button>
                </Link>
            </div>
        </div>
    );
}
