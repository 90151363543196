import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import Logo from '../../images/Greenlogo.png';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './ResetPassword.css';

import { useResetPass } from '../../hooks/useResetPass';

const PasswordToggleSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = (props) => {
  const { mutateAsync, isLoading } = useResetPass();

  let history = useHistory();
  const {
    formState: { errors },
    control,
    handleSubmit,
    getValues,
  } = useForm({
    resolver: yupResolver(PasswordToggleSchema),
  });

  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === 'input' ? 'password' : 'input');
  };

  const showHideConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordType(confirmPasswordType === 'input' ? 'password' : 'input');
  };

  const onSubmit = async () => {
    const password = getValues().password;
    if (!errors.password && !errors.confirmPassword) {
      await mutateAsync({ password });
      history.push('/');
    }
  };

  return (
    <section id='reset_password'>
      <div className='reset_password-section'>
        <div className='reset_password-inner'>
          <div className='reset_password_item'>
            <div id='reset_password_nav_content-div'>
              <div className='row'>
                <div className='col-lg-6' id='nav_first_col-div'></div>
                <div className='col-lg-6' id='nav_second_col-div'>
                  <div className='container text-right'>
                    <p className='contact_us_link'>Contact Us</p>
                  </div>
                </div>
              </div>
              <div className='text-center' id='nav_logo-div'>
                <img src={Logo} className='mx-auto sadaqa-logo' alt='' />
              </div>
            </div>
            <div id='reset_password_form-div'>
              <div className='container'>
                <div className='row justify-content-center align-items-center'>
                  <Form className='justify-content-center' onSubmit={handleSubmit(onSubmit)}>
                    <h5 className='reset_password_title'>Reset Password</h5>
                    <div className='form-group reset_password_div'>
                      <Controller
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type={passwordType}
                            name='password'
                            className='reset_password_input'
                            placeholder='New Password'
                            style={{
                              borderColor: !errors.password ? '' : 'red',
                            }}
                          />
                        )}
                        name='password'
                        control={control}
                        defaultValue=''
                      />
                      <span className='reset_password_show' onClick={showHidePassword}>
                        {passwordType === 'input' ? (
                          <i id='eye-icon' className='fa fa-eye'></i>
                        ) : (
                          <i id='eye-icon' className='fa fa-eye-slash fa-flip-horizontal'></i>
                        )}
                      </span>
                      {errors.password && (
                        <p className='reset_password_error'>{errors.password.message}</p>
                      )}
                      <Controller
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type={confirmPasswordType}
                            className='reset_password_input'
                            placeholder='Confirm Password'
                            name='confirmPassword'
                            style={{
                              borderColor: !errors.confirmPassword ? '' : 'red',
                            }}
                          />
                        )}
                        name='confirmPassword'
                        control={control}
                        defaultValue=''
                      />
                      <span className='reset_password_show' onClick={showHideConfirmPassword}>
                        {confirmPasswordType === 'input' ? (
                          <i id='eye-icon' className='fa fa-eye'></i>
                        ) : (
                          <i id='eye-icon' className='fa fa-eye-slash fa-flip-horizontal'></i>
                        )}
                      </span>
                      {errors.confirmPassword && (
                        <p className='reset_password_error'>{errors.confirmPassword.message}</p>
                      )}
                    </div>
                    {isLoading ? (
                      <div className=' mt-1 text-center'>
                        <Spinner animation='border' role='status' style={{ color: '#67DB88' }} />
                      </div>
                    ) : (
                      <button type='submit' className='btn-primary btn-block reset_password_button'>
                        Reset password
                      </button>
                    )}

                    <Link to='/Login' className='to_login'>
                      <p className='back_to_signin'>Back to sign in</p>
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
