import React from 'react'
import Text from 'react-text';
import CircularImage from '../circularImage/circularImage'
import greenDot from '../../../images/green-dot.svg'
import './HomeOrgItem.css'

const HomeOrgItem = (props) => {
    return (
        <form className='review-item'>
            <div className='img-container'>
                <CircularImage image={props.image} size="60px"/>
            </div>
            <h1 className='name-section'>{props.name}</h1>
            <h4 className='time-section'>{props.time}</h4>
            <img src={greenDot} alt='' className='dot-icon'/>
            <h4 className='date-section'>{props.date}</h4>
            <div className='review-section'>
                <Text>{props.review}</Text>    
            </div>
        </form>
    )
}

export default HomeOrgItem;
