import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as helpers from '../../../global/helpers';
import * as Constants from '../../../constants/Constants';
import { useFetchReviews } from '../../../hooks/useFetchReviews';
import Review from './Review';
import NoReviews from '../../OrganizationComponents/NoReviews/NoReviews';
import WriteAReview from './WriteAReview/WriteAReview';
import write from '../../../images/write.svg';

export default function Reviews({ orgId, userType }) {
  const { data: reviewData, isLoading } = useFetchReviews(orgId);

  console.log('reviews', reviewData);

  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReviews = () => {
    let pageSize = 10;
    let url = Constants.ORGANIZATIONS + '/' + orgId + '/reviews?limit=10&offset=' + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal);
    helpers.getOrgReviews(url).then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          console.log(response.data.data);
          //let projectsOld = projects.slice();
          let projectsLocal = reviews.concat(response.data.data);
          setReviews(projectsLocal);
          if (!isFinished) setIsFinished(true);

          if (response.data.data.length === 0 || response.data.data.length < pageSize)
            setHasMore(false);
        }
      }
    });
  };

  function updateAfterDelete(reviewId) {
    setReviews(reviews.filter((item) => item.id !== reviewId));
  }

  return (
    <div>
      {userType === 'contributor' ? (
        <button
          type='button'
          className='btn btn-primary d-block mr-0 ml-auto'
          id='write_review-btn'
          onClick={() => setShowReviewModal(true)}
        >
          <img src={write} id='write-icon' alt='' />
          <p>Write a review</p>
        </button>
      ) : userType === 'admin' ? (
        ''
      ) : (
        ''
      )}
      {!isFinished ? null : reviews !== undefined && reviews !== null && reviews.length > 0 ? (
        <InfiniteScroll
          dataLength={reviews.length}
          next={getReviews}
          hasMore={hasMore}
          loader={<h4 className='loading-label'>Loading...</h4>}
          scrollableTarget='scrollableDiv'
        >
          {isLoading ? (
            <h1 className='tracking-normal text-normal text-sm'>loading...</h1>
          ) : (
            reviewData.map((item, index) => {
              return (
                <div
                  className='org-projects-section-item col-lg-12'
                  key={'OrgProjectsSection-item-' + index}
                >
                  <Review data={item} updateAfterDelete={updateAfterDelete} userType={userType} />
                </div>
              );
            })
          )}
        </InfiniteScroll>
      ) : (
        <NoReviews />
      )}
      <WriteAReview
        isReview={showReviewModal}
        setIsReview={setShowReviewModal}
        getReviews={getReviews}
      />
    </div>
  );
}
