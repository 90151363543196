import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import ConfirmationModal from '../../../components/reusableComponents/ConfirmationModal/ConfirmationModal';
import { BASE_URL } from '../../../constants/Constants';
import { useRemovePayment } from '../../../hooks/useRemovePayment';
import './RemovePaymentMethod.css';

export default function RemovePaymentMethod({ isRemove, setIsRemove, paymentId, paymentMethod }) {
  const [isOpen, setIsOpen] = useState(false);

  let url = `${BASE_URL}/payments/cards/${paymentId}`;
  const { mutateAsync, isLoading } = useRemovePayment();

  return (
    <>
      <Modal show={isRemove} onHide={setIsRemove} className='remove_payment_modal modal-center'>
        <Modal.Body>
          <h3 className='rp_modal-h3'>Credit Card info</h3>
          <Form style={{ paddingBottom: 15 }}>
            <Form.Group className='cc-number' controlId='formGroupCardNumber'>
              <Form.Label className='rp_input-lbl'>Card number</Form.Label>
              <Form.Control
                className='rp_input'
                type='text'
                required
                value={`**** **** **** ${paymentMethod.last4}`}
                disabled
              />
            </Form.Group>
            <button
              className='remove_card-btn'
              variant='primary'
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(true);
              }}
            >
              Remove Card
            </button>
            <button
              className='close-btn'
              onClick={(e) => {
                e.preventDefault();
                setIsRemove(false);
              }}
            >
              Close
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        title='Remove Payment Method'
        description='Removing it means you won’t be able to donate anymore & recurring payments will end.'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        cancelBtnTitle='Keep it'
        submitBtnTitle='Remove'
        spinnerLoad={isLoading}
        spinnerColor={'#FF7E7E'}
        type='confirmation-danger'
        submitFunc={async () => {
          await mutateAsync(url);
          setIsRemove(false);
        }}
      />
    </>
  );
}
