import React from 'react'
import Text from 'react-text';
import greenDot from '../../../images/green-dot.svg'
import './HomeProjectItem.css'

const HomeProjectItem = (props) => {
    return (
        <form className='project-review-item'>
            <img src={props.image} alt='' className='project-logo' />
            <h4 className='project-title'>{props.title}</h4>
            <h3 className="author-field">by</h3>
            <h1 className='project-name-section'>{props.name}</h1>
            <h4 className='project-time-section'>{props.time}</h4>
            <img src={greenDot} alt='' className='project-dot-icon'/>
            <h4 className='project-date-section'>{props.date}</h4>
            <div className='project-review-section'>
                <Text>{props.review}</Text> 
            </div>   
        </form>
    )
}

export default HomeProjectItem;
