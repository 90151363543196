import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import UploadProfileImage from "../../reusableComponents/SingleImageUploadComponent/SingleImageUploadComponent";
//import PhoneInputForm from "../../reusableComponents/PhoneInputForm/PhoneInputForm";
import states from "../../../states";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import './RegisterIndividualStep1.css';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import Select from "react-select";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const RegisterIndividualFirstStepSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup.string().nullable()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  stateSelection: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .nullable()
    .required("State is required"),
  city: yup.string().required("Required"),
  zipCode: yup.string().required("Zip code is required").matches(/^\d{5}(-\d{4})?$/, "Must match 12345 or 12345-1234"),
  streetAddress: yup.string().required("Street address is required"),
  });

const RegisterIndividualStep1 = React.forwardRef((props, ref) => {

  const [phoneNumberS, setPhoneNumberS] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const { formState: { errors }, control, setValue, trigger, handleSubmit } = useForm({
    resolver: yupResolver(RegisterIndividualFirstStepSchema)
  });

  const onSubmit = (x) => {
    props.setRegisterIndividualStep1Data(x, phoneNumberS, countryCode)
  };

  const stateSelectionStyles = {
    control: styles => {
      return {
        ...styles,
        borderColor: !errors.stateSelection ? '' : 'red !important'
      };
    }
  };

  const statesOptions = states;

  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <Container className="register_individual_step_one">
      <Row className="justify-content-center align-items-center mr-0 ml-0">
        <Form className="justify-content-center" onSubmit={handleSubmit(onSubmit)}>
          <Col lg={12}>
            <Form.Group>
              <Form.Label className="individual_signup_label">Profile Picture</Form.Label>
              <UploadProfileImage ref={ref} />
            </Form.Group>
          </Col>
          <Col lg={12} className="mt-2">
            <Row>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label className="individual_signup_label">First Name</Form.Label><span className="required_field"> *</span>
                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        id="firstName"
                        className="input_field"
                        name="firstName"
                        style={{
                          borderColor: !errors.firstName ? '' : 'red'
                        }}
                      //value={props.firstName}
                      //onChange={(e) => { props.updateState(e) }}
                      />
                    )}
                    name="firstName"
                    control={control}
                    defaultValue=""
                  />
                  {errors.firstName && <p className="individual_signup_error_step_1">{errors.firstName.message}</p>}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="individual_signup_label">Last Name</Form.Label><span className="required_field"> *</span>
                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        id="lastName"
                        className="input_field"
                        name="lastName"
                        style={{
                          borderColor: !errors.lastName ? '' : 'red'
                        }}
                      // onChange={(e) => { props.updateState(e) }}
                      />
                    )}
                    name="lastName"
                    control={control}
                    defaultValue=""
                  />
                  {errors.lastName && <p className="individual_signup_error_step_1">{errors.lastName.message}</p>}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="individual_signup_label">Your Phone Number</Form.Label><span className="required_field"> *</span>
                  {/* <Controller
                    render={({ field }) => (
                      <PhoneInputForm field={field} errors={errors} />
                    )}
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                  /> */}
                  <Controller
                    id="phoneNumber"
                    name="phoneNumber"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        onChange={(value, country) => {
                          console.log(value)
                          setValue("phoneNumber", value)
                          trigger("phoneNumber")
                          setCountryCode(country.dialCode)
                          setPhoneNumberS(value.slice(country.dialCode.length))
                        }}
                        inputStyle={{
                          borderColor: !errors.phoneNumber ? '' : 'red'
                        }}
                        buttonStyle={{
                          borderColor: !errors.phoneNumber ? '' : 'red'
                        }}
                        country={'us'}
                        placeholder='(702) 123-4567'
                      />
                    )}

                  />
                  {errors.phoneNumber && <p className="individual_signup_error_step_1">{errors.phoneNumber.message}</p>}
                </Form.Group>
              </Col>
              <Col lg={4} className="w-100"></Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label className="individual_signup_label">
                    Street Address <span className="required_field"> *</span>
                  </Form.Label>
                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        id="streetAddress"
                        className="input_field"
                        name="streetAddress"
                        style={{
                          borderColor: !errors.streetAddress?.message ? '' : 'red'
                        }}
                      />
                    )}
                    name="streetAddress"
                    control={control}
                    defaultValue=""
                  />
                  {errors.streetAddress && <p className="org_signup_error_step_1">{errors.streetAddress.message}</p>}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="individual_signup_label">State/province<span className="required_field"> *</span></Form.Label>
                  <Controller
                    name="stateSelection"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        isSearchable={false}
                        options={statesOptions.map((state) => {
                          return {
                            value: state.value,
                            label: state.label
                          }
                        }
                        )}
                        styles={stateSelectionStyles}
                      />
                    )}
                  />
                  <p className="individual_signup_error_step_1">{errors.stateSelection?.message}</p>
                </Form.Group>
                <div className="city_zip-div">
                  <Form.Group>
                    <Form.Label className="individual_signup_label">City<span className="required_field"> *</span></Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id="city"
                          className="input_field city_txt"
                          name="city"
                          style={{
                            borderColor: !errors.city ? '' : 'red'
                          }}
                        />
                      )}
                      name="city"
                      control={control}
                      defaultValue=""
                    />
                    {errors.city && <p className="individual_signup_error_step_1">{errors.city.message}</p>}
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Label className="individual_signup_label">Zip Code<span className="required_field"> *</span></Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id="zip_code"
                          className="input_field zip_code_txt"
                          name="zipCode"
                          style={{
                            borderColor: !errors.zipCode ? '' : 'red'
                          }}
                        />
                      )}
                      name="zipCode"
                      control={control}
                      defaultValue=""
                    />
                    {errors.zipCode && <p className="individual_signup_error_step_1">{errors.zipCode.message}</p>}
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </Col>
          <Button type="submit" color="primary" className="next_button">
            Next
          </Button>
        </Form>
      </Row>
    </Container>
  );
});

export default RegisterIndividualStep1;
