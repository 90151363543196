import React, { useState } from 'react';
import About from '../About';
import Donations from '../../SAOrganization/Donations';
import Images from '../Images';
import Updates from '../Updates';
import imgDefault from "../../../images/img-default.svg"
import * as Constants from "../../../constants/Constants";
import * as helpers from "../../../global/helpers";

export default function Tabs({ projectDetails }) {
  const [active, setActive] = useState('about');

  let id = projectDetails !== null ? projectDetails.id : ""
  let byOrg = projectDetails !== null ? projectDetails.byOrg : ""
  let description = projectDetails !== null ? projectDetails.description : ""
  let orgImg = imgDefault

  if (projectDetails !== null && projectDetails.orgImage !== undefined && projectDetails.orgImage !== null && projectDetails.orgImage !== "" && projectDetails.orgImage !== "guid") {
    orgImg = projectDetails !== null ? Constants.BASE_URL + "/media/" + projectDetails.orgImage + "/200" : imgDefault
  }

  return (
    <div className='pl-lg-5 pl-md-0'>
      <ul className='org-tabs'>
        <li
          onClick={() => setActive('about')}
          className={`${active === 'about' ? 'active-org-tab' : ''} `}
        >
          About
        </li>
        <li
          onClick={() => setActive('images')}
          className={`${active === 'images' ? 'active-org-tab' : ''} `}
        >
          Images
        </li>
        <li
          onClick={() => setActive('updates')}
          className={`${active === 'updates' ? 'active-org-tab' : ''} `}
        >
          Updates
        </li>
        {
          helpers.getUserType() === Constants.USER_TYPE_ADMIN || helpers.getUserType() === Constants.USER_TYPE_CONTRIB
            ? <li
              onClick={() => setActive('donations')}
              className={`${active === 'donations' ? 'active-org-tab' : ''} `}
            >
              Donations
            </li>
            : null
        }

      </ul>
      <div className='mt-4'>
        {active === 'about' ? (
          <About byOrg={byOrg} description={description} orgImg={orgImg} />
        ) : active === 'images' ? (
          <Images />
        ) : active === 'donations' ? (
          <Donations id={id} name={projectDetails.title} userType={helpers.getUserType()} isProjectsDonations={true}/>
        ) : (
          <Updates id={id}/>
        )}
      </div>
    </div>
  );
}
