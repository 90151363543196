import { useMutation } from 'react-query';
import { BASE_URL } from '../constants/Constants';

import { useLocation } from 'react-router-dom';

import { postImage as postPass } from '../global/helpers';

export const useResetPass = () => {
  let query = new URLSearchParams(useLocation().search);

  let token = query.get('t');

  let url = `${BASE_URL}/auth/changePwdReset?t=${token}`;

  console.log('url', url);

  const handlePost = async (data) => {
    await postPass(url, data).then((resp) => console.log('resp', resp));
  };

  return useMutation(handlePost);
};
