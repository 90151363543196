import React, { useEffect, useRef, useState } from "react";
import "./OrgEditAccount.css";
import "../../register/registerOrganization/registerOrganization.css"
import "../../register/registerOrganization/RegisterOrganizationStep1.css"
import "../../register/registerOrganization/RegisterOrganizationStep2.css"

import { Button, Col, Form, Image, Row } from "react-bootstrap";
import topBg from "../../../images/AccountBG.png"
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import states from "../../../states";
import { useHistory } from "react-router-dom";
import * as AppUrls from "../../../constants/AppUrls";
import * as Constants from "../../../constants/Constants";
import * as helpers from "../../../global/helpers";
import ReplaceImageLogo from '../../../images/replace-image.png';
import PhoneInput from "react-phone-input-2";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object().shape({
    orgName: yup.string().required("Organization name is required"),
    phoneNumber: yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required")
    /* .object()
        .shape({
            value: yup.string(),
            dialCode: yup.string(),
        }).nullable()
        .test(
            "error",
            "Phone number is required",
            (phoneNumber) => {
                return phoneNumber.value !== ''
            }
        ).test(
            "error",
            "Phone number is not valid",
            (phoneNumber) => {
                return phoneRegExp.test(phoneNumber.value)
            }
        ) */

        /*.required("Phone Number is required") */
        /* yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required") */,
    aboutOrg: yup.string().required("About organization is required"),
    city: yup.string().required("City is required"),
    federalTaxId: yup.string().required("Federal Tax ID is required"),
    supportingCauses: yup.array()
        .of(
            yup.object().shape({
                key: yup.string(),
                value: yup.string(),
                id: yup.number()
            })
        )
        .max(7)
        .min(1, "Minimum 1 Supporting Cause is required")
        .required('Supporting Causes are required'),
    stateSelection: yup
        .object()
        .shape({
            label: yup.string(),
            value: yup.string()
        })
        .nullable()
        .required("State is required"),
    uploadFile: yup
        .mixed()
        .test(
            "error",
            "501 (c) Document is required",
            (value) => {
                return value !== ''
            }
        )
});

function OrgEditAccount(props) {
    let history = useHistory();

    const statesOptions = states;

    const [supportingCausesOptions, setSupportingCausesOptions] = useState([]);
    const [isFormDisabled, setIsFormDisable] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const [profileImg, setProfileImg] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [rawImageFile, setRawImageFile] = useState(null);
    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const [phoneNumberS, setPhoneNumberS] = useState("");
    const [countryCode, setCountryCode] = useState("");

    const fileInputRef = useRef();

    const { formState: { errors }, control, handleSubmit, setValue, register, getValues, trigger } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        getSupportingCauses();
        if (document.getElementById("account_edit_input_file") !== null) {
            document
                .getElementById("account_edit_input_file")
                .addEventListener(
                    "change",
                    uploadSingleFile
                )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSupportingCauses = () => {
        setIsFormDisable(true)
        helpers.supportingCauses()
            .then((response) => {
                if (response !== undefined) {
                    if (response.data) {
                        getProfile()
                        setSupportingCausesOptions(response.data)
                    }
                }
            })
            .catch((error) => {
                setIsFormDisable(false)
            })
    }

    function getProfile() {
        setIsFormDisable(true)
        helpers.getProfile()
            .then((response) => {
                setIsFormDisable(false)
                if (response !== undefined) {
                    if (response.status === 200) {
                        let profile = response.data;
                        setValue("orgName", profile.name !== null ? profile.name : "")
                        setValue("aboutOrg", profile.description !== null ? profile.description : "")
                        setValue("city", profile.city !== null ? profile.city : "")
                        setValue("federalTaxId", profile.federalTaxID !== null ? profile.federalTaxID : "")
                        let profileImgLocal = null;
                        if (profile.image !== undefined && profile.image !== null && profile.image !== "" && profile.image !== "guid") {
                            let imgUrl = Constants.BASE_URL + "/media/" + profile.image + "/200" //first field: GUID, second is: size
                            profileImgLocal = imgUrl;
                        }
                        setProfileImg(profileImgLocal);

                        let stateLocal = {
                            value: profile.state.abbrev,
                            label: profile.state.value
                        }
                        setValue("stateSelection", stateLocal);

                        let supportingCausesLocal = profile.supportingCausesWeb.map((item) => {
                            return (
                                {
                                    label: item.value,
                                    value: item.id
                                }
                            )
                        })
                        setValue("supportingCauses", supportingCausesLocal);


                        let fullPhoneNumberFromApi = profile.fullPhoneNumber.replace(/[+]/g, '')
                        setValue("phoneNumber", fullPhoneNumberFromApi)
                        setCountryCode(profile.areaCode)
                        setPhoneNumberS(profile.phoneNumber)
                        setValue("uploadFile", "501 (c) Document " + profile.name + ".pdf")
                        setSelectedFile({ name: "501 (c) Document " + profile.name + ".pdf" })
                    }
                }
            })
            .catch((error) => {
                setIsFormDisable(false)
            })
    }

    const uploadSingleFile = (e) => {
        if (e.target.files.length > 0) {
            let fileUploaded = e.target.files[0];
            setSelectedFile(fileUploaded);

        }
    }

    const supportingCausesStyles = {
        control: styles => {
            return {
                ...styles,
                borderColor: !errors.supportingCauses ? '' : 'red'
            };
        }
    };

    const stateSelectionStyles = {
        control: styles => {
            return {
                ...styles,
                borderColor: !errors.stateSelection ? '' : 'red !important'
            };
        }
    };

    async function replace(e) {
        e.preventDefault();
        setSelectedFile(null);

        setValue("uploadFile", "")

    }

    function replaceImg(e) {
        e.preventDefault();
        setImageFile(null);
        setProfileImg(null);
    }

    function uploadImgFile(e) {
        if (e.target.files[0] !== undefined) {
            let file = URL.createObjectURL(e.target.files[0])
            setImageFile(file);
            setRawImageFile(e.target.files[0])
        }
    }

    const onSubmit = (data) => {
        getImageGUID(data, rawImageFile)
    };

    function getImageGUID(data, rawImageFile) {
        if (rawImageFile === null) {
            getFileGUID(data, undefined);
            return;
        }

        setIsFormDisable(true)

        const formData = new FormData();
        formData.append(
            "file", rawImageFile
        );

        helpers.uploadMedia(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    getFileGUID(data, response.data.guid);
                }
            })
            .catch((error) => {
                setIsFormDisable(false)
            });
    }

    function getFileGUID(data, profilePhotoGUID) {
        setIsFormDisable(true)
        if (typeof getValues("uploadFile") === 'string') {
            editAccount(data, profilePhotoGUID, undefined)
            return
        }
        const formData = new FormData();
        formData.append(
            "file",
        );

        helpers.uploadMedia(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    editAccount(data, profilePhotoGUID, response.data.guid)
                }
            })
            .catch((error) => {
                setIsFormDisable(false)
            });
    }

    function editAccount(data, profilePhotoGUID, fileGUID) {
        setIsSavingProfile(true)
        let supportingCausesLocal = data.supportingCauses.map((item) => {
            if (item.value !== undefined)
                return item.value
            else
                return item.key

        })

        let postdata = {
            orgName: data.orgName,
            areaCode: countryCode,
            federalTaxId: data.federalTaxId,
            description: data.aboutOrg,
            supportingCauses: supportingCausesLocal,
            phoneNumber: phoneNumberS,
            city: data.city,
            stateAb: data.stateSelection.value
        }

        if (profilePhotoGUID !== undefined) {
            postdata = { ...postdata, profilePhoto: profilePhotoGUID }
        }

        if (fileGUID !== undefined) {
            postdata = { ...postdata, documentGuid: fileGUID }
        }

        helpers.editOrg(postdata)
            .then((response) => {
                setIsSavingProfile(false)
                setIsFormDisable(false)
                if (response !== undefined) {
                    if (response.status === 200) {
                        helpers.successNotifyWithTitle("Saved", "Your changes have been saved.")
                        history.push(AppUrls.account)
                    }
                }

            })
            .catch((error) => {
                setIsSavingProfile(false)
                setIsFormDisable(false)
            });

    }

    let imageInputDisplay = profileImg !== null || imageFile !== null ? "none" : "block";
    let fileInputDisplay = getValues("uploadValue") === "" || getValues("uploadValue") === null ? "none" : "block";

    return (
        <div className="org-account-container edit-account" >
            <img className="org-account-top-bg" src={topBg} alt="bg" />
            <Form style={{ paddingBottom: "60px", paddingTop: "50px" }} onSubmit={handleSubmit(onSubmit)} >
                <fieldset disabled={isFormDisabled}>
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Form.Group >
                                <Form.Label className="reg_org_lbl">Profile Picture</Form.Label>
                                <>
                                    {
                                        profileImg !== null || imageFile !== null
                                            ? <Form.Group className="replace_image_form" >
                                                <Image src={profileImg || imageFile} className="uploaded_image" />
                                                <Row id="replace_image-div">
                                                    <Image src={ReplaceImageLogo} id="replace_logo-img" />
                                                    <Button id="replace-button" onClick={replaceImg}>Replace Image</Button>
                                                </Row>
                                            </Form.Group>
                                            : null
                                    }

                                    <Form.Group style={{ display: imageInputDisplay }} id="profile_image-div">
                                        <Form.Control type="file" className='profile_image_input' onChange={uploadImgFile} />
                                    </Form.Group>
                                </>
                            </Form.Group>
                        </Col>
                        <Col lg={4} />
                    </Row>

                    <Row className="justify-content-center">
                        <Col lg={4}>

                            <Form.Group className="reg_org_lbl">
                                <Form.Label>Organization Name</Form.Label><span className="required_field"> *</span>
                                <Form.Control
                                    id="orgName"
                                    name="orgName"
                                    className="input_field"
                                    style={{
                                        borderColor: !errors.orgName || props.orgName ? '' : 'red'
                                    }}
                                    {...register('orgName')}
                                />
                                {errors.orgName && <p className="org_signup_error_step_1">{errors.orgName.message}</p>}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="reg_org_lbl">
                                    About Organization<span className="required_field"> *</span>
                                </Form.Label>
                                <Form.Control
                                    id="aboutOrg"
                                    as="textarea"
                                    className="about_organization"
                                    name="aboutOrg"
                                    style={{
                                        borderColor: !errors.aboutOrg ? '' : 'red'
                                    }}
                                    {...register('aboutOrg')}
                                />
                                {errors.aboutOrg && <p className="org_signup_error_step_2">{errors.aboutOrg.message}</p>}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="reg_org_lbl">
                                    Supporting Cause(s)
                                    <span className="required_field"> * </span>
                                    <span className="max_number"> 7 max</span>
                                </Form.Label>
                                <Controller
                                    name="supportingCauses"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            options={supportingCausesOptions.map((state) => {
                                                return {
                                                    value: state.id,
                                                    label: state.value
                                                }
                                            }
                                            )}
                                            isMulti={true}
                                            styles={supportingCausesStyles}
                                            className="supportingCauseSelector"
                                            maxMenuHeight={150}
                                            isOptionDisabled={() => getValues("supportingCauses") !== undefined ? getValues("supportingCauses").length >= 7 : false}
                                        />
                                    )}
                                />
                                <p className="org_signup_error_step_2">
                                    {errors.supportingCauses?.message || errors.supportingCauses?.key.message}
                                </p>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="reg_org_lbl">Your Phone Number</Form.Label><span className="required_field"> *</span>
                                <Controller
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            onChange={(value, country) => {
                                                console.log(value)
                                                setValue("phoneNumber", value)
                                                trigger("phoneNumber")
                                                setCountryCode(country.dialCode)
                                                setPhoneNumberS(value.slice(country.dialCode.length))
                                            }}
                                            inputStyle={{
                                                borderColor: !errors.phoneNumber ? '' : 'red'
                                            }}
                                            buttonStyle={{
                                                borderColor: !errors.phoneNumber ? '' : 'red'
                                            }}
                                            country={'us'}
                                            placeholder='(702) 123-4567'
                                        />
                                    )}

                                />

                                {errors.phoneNumber && <p className="org_signup_error_step_1">{errors.phoneNumber.message}</p>}
                            </Form.Group>

                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label className="reg_org_lbl">
                                    State <span className="required_field"> *</span>
                                </Form.Label>
                                <Controller
                                    className="supportingCauseSelector"
                                    name="stateSelection"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            maxMenuHeight={150}
                                            options={statesOptions.map((state) => {
                                                return {
                                                    value: state.value,
                                                    label: state.label
                                                }
                                            }
                                            )}
                                            styles={stateSelectionStyles}
                                        />
                                    )}
                                />
                                <p className="org_signup_error_step_2">{errors.stateSelection?.message}</p>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="reg_org_lbl">
                                    City <span className="required_field"> *</span>
                                </Form.Label>
                                <Form.Control
                                    id="city"
                                    className="input_field"
                                    name="city"
                                    style={{
                                        borderColor: !errors.city?.message ? '' : 'red'
                                    }}
                                    {...register('city')}
                                />
                                {errors.city && <p className="org_signup_error_step_2">{errors.city.message}</p>}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="reg_org_lbl">
                                    Federal Tax ID <span className="required_field"> *</span>
                                </Form.Label>

                                <Form.Control
                                    id="federalTaxId"
                                    className="input_field"
                                    name="federalTaxId"
                                    style={{
                                        borderColor: !errors.federalTaxId?.message ? '' : 'red'
                                    }}
                                    {...register('federalTaxId')}
                                />
                                {errors.federalTaxId && <p className="org_signup_error_step_2">{errors.federalTaxId.message}</p>}
                            </Form.Group>

                            <Form.Group className="upload_file_form" style={{ maxWidth: "284px" }}>
                                <Form.Label className="reg_org_lbl">
                                    501 (c) Document (PDF or TIFF) <span className="required_field"> *</span>
                                </Form.Label>

                                <Form.Group className="replace_file_form" >
                                    <Form.Control
                                        type="text"
                                        value={selectedFile !== null ? selectedFile.name : ""}
                                        className="uploaded_file"
                                        onChange={() => {
                                            setSelectedFile(null)
                                        }}
                                    />
                                    <Button id="replace_file-button" onClick={(e) => replace(e)} />
                                </Form.Group>
                                {errors.uploadFile && <p className="file_upload_error mt-1">{errors.uploadFile.message}</p>}

                                <Controller
                                    render={({ field }) => (
                                        <Form.Group
                                            className="mt-2"
                                            style={{
                                                borderColor: !errors.uploadFile ? '' : 'red',
                                                display: fileInputDisplay
                                            }}
                                            id="fileUploadDiv"
                                            {...field}
                                        >
                                            <Form.Control
                                                id="account_edit_input_file"
                                                type="file"
                                                className='file_upload_input'
                                                ref={fileInputRef}
                                            />
                                        </Form.Group>
                                    )}
                                    name="uploadFile"
                                    control={control}
                                    defaultValue=""
                                />

                            </Form.Group>

                            <Row style={{ width: "100%" }}>
                                <Col lg={6} md={6} sm={6} xs={4} style={{ textAlign: "left" }}>
                                    <button className="crn-discard mt-2" type="button" onClick={(e) => {
                                        e.preventDefault();
                                        history.push(AppUrls.account);
                                    }}>
                                        Discard
                                    </button>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={8} style={{ textAlign: "right" }}>
                                    <button
                                        type="submit"
                                        className="crn-create w-100"
                                    >
                                        {
                                            isSavingProfile
                                                ? "Saving..."
                                                : "Save Changes"
                                        }
                                    </button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </fieldset>
            </Form>

        </div >
    )
}

export default OrgEditAccount;