import React from 'react';
import './InfoSection.css';
import { Col, Container, Row } from 'react-bootstrap';

import hand_info_section from '../../images/hand_info_section.png';
import info_section_secure from '../../images/info_section_secure.png';
import logo from '../../images/logo.png';
import ic_fb from '../../images/ic_fb.png';
import ic_instagram from '../../images/ic_instagram.png';
import ic_twitter from '../../images/ic_twitter.png';
import arrow from '../../images/arrow.png';
import ic_arrow_up from '../../images/ic_arrow_up.png';

import { Link } from 'react-router-dom';

const InfoSection = (props) => {
  const pkg = require('../../../package.json');
  const appVersion = pkg.version;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section id='InfoSection'>
      <Container style={{ zIndex: '11', position: 'relative' }}>
        <Col lg='12'>
          <Row className='justify-content-center'>
            <Col className='text-center mt-5 mb-5' lg='4' md='4' sm='12' xs='12'>
              <button className='get-app-btn google-play-btn' />
              <button className='get-app-btn app-store-btn' />
              <div className='title-text get-app-text'>Get The App!</div>
            </Col>
            <Col className='text-center mt-5 mb-5' lg='4' md='4' sm='12' xs='12'>
              <img className='hand-info-section-img' src={hand_info_section} alt='hand' />
              <div className='title-text hand-text'>Donate</div>
            </Col>
            <Col className='text-center mt-5 mb-5' lg='4' md='4' sm='12' xs='12'>
              <div className='title-text'>Ready to get started?</div>

              <div className='sub-title-text sub-title-text-div'>
                Register your non-profit or get started as an individual.
              </div>

              <Link
                style={{ height: 'auto', border: '1px solid #67DB88' }}
                to='/RegisterOrganization'
                className='info-secton-btn register-btn btn btn-primary py-2'
              >
                Register your non-profit
              </Link>

              <Link
                style={{ height: 'auto' }}
                to='/RegisterIndividual'
                className='info-secton-btn get-started-btn btn btn-primary py-2'
              >
                Get started as individual
              </Link>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col className='secure-img-container mt-5 mb-5' lg='6' md='6' sm='12' xs='12'>
              <img className='secure-img' src={info_section_secure} alt='secure' />
            </Col>

            <Col className='secure-text-container mt-5 mb-5' lg='6' md='6' sm='12' xs='12'>
              <div className='secure-text'>
                We use Stripe as our payment processor – the same company trusted by 100,000+
                companies. No one at SADAQAS will ever see your bank or card information.
              </div>
            </Col>
          </Row>

          <Row className='justify-content-center'>
            <Col
              className='transparent-section mt-5 mb-5 pl-0 pr-0'
              lg='12'
              md='12'
              sm='12'
              xs='12'
            >
              <div className='transparent-section-inner'>
                <div className='transparent-section-title'>Transparent &amp; honest pricing</div>

                <div className='transparent-section-sub-title'>
                  No setup, monthly, or hidden fees. SADAQAS is completely free to use. You don't
                  have to pay anything to create an account and run a campaign,
                </div>

                <div className='transparent-section-sub-2-title'>
                  To help us maintain the app performance, give the best experience and introduce
                  new features, 3% of donations will go to Sadaqas.{' '}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>

      <div id='landing-footer'>
        <div
          style={{
            height: '100%',
            zIndex: '1',
            position: 'absolute',
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <div className='footer-bg-1' />
            <div className='footer-bg-2' />
          </div>
        </div>
        <div className='landing-footer-items'>
          <Container>
            <Row className='landing-footer-items-container justify-content-center text-center'>
              <Col className='contact-us-container-info-section' lg='4' md='4' sm='12' xs='12'>
                <img src={logo} style={{ width: '170px', height: '130px' }} alt='' />
                <div className='contact-us-info-section'>Contact Us</div>
                <div className='contact-us-info-section-email'>info@sadaqas.com</div>
                <div className='contact-us-info-section-social-media '>
                  <img src={ic_fb} className='contact-us-info-section-social-media-item' alt='' />
                  <img
                    src={ic_instagram}
                    className='contact-us-info-section-social-media-item'
                    alt=''
                    style={{ marginLeft: '8px' }}
                  />
                  <img
                    src={ic_twitter}
                    className='contact-us-info-section-social-media-item'
                    alt=''
                    style={{ marginLeft: '8px' }}
                  />
                </div>
              </Col>
              <Col className='visit-sadaqa-container' lg='4' md='4' sm='12' xs='12'>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                  }}
                >
                  <div className='are-you-ready'>Are you ready to make a change?</div>

                  <div className='contact-us-visit-sadaqa-container'>
                    <img className='contact-us-arrow' src={arrow} alt='' />
                    <Link
                      to='/Login'
                      className='btn py-2 px-0 contact-us-visit-sadaqa-btn'
                      style={{ height: 'auto' }}
                    >
                      Donate Now
                    </Link>
                  </div>
                </div>
              </Col>
              <Col className='back-to-top' lg='4' md='4' sm='12' xs='12'>
                <div className='back-to-top-text'>Back to top</div>
                <div
                  className='back-to-top-container'
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                  }}
                >
                  <img src={ic_arrow_up} alt='' />
                </div>
              </Col>
            </Row>
            <div className='footer-app-version'>
              <p className='app-version_p'>v{appVersion}</p>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
