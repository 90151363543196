import React, { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';

//import { ProjectDetailsAdmin } from '../../../constants/AppUrls';

import InfiniteScroll from "react-infinite-scroll-component";
import ProjectItem from "../../../components/reusableComponents/ProjectItem/ProjectItem";
import * as Constants from "../../../constants/Constants";
import * as helpers from "../../../global/helpers";
import * as AppUrls from "../../../constants/AppUrls";

import NoProjects from '../../../pages/Organization/Projects/NoProjects/NoProjects';
import { useHistory, useParams } from "react-router-dom";

export default function Projects({ orgId }) {
  let history = useHistory();
  const { orgName } = useParams();
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getProjectsLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectsLocal = () => {
    let pageSize = 10;
    let url = Constants.ORGANIZATIONS + "/" + orgId + "/projects?limit=10&offset=" + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal)
    helpers.getOrgProjects(url)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            console.log(response.data.data);
            //let projectsOld = projects.slice();
            let projectsLocal = projects.concat(response.data.data)
            setProjects(projectsLocal);
            if (!isFinished)
              setIsFinished(true)

            if (response.data.data.length === 0 || response.data.data.length < pageSize)
              setHasMore(false);
          }
        }
      })
  }

  return (
    <div>
      {
        !isFinished
          ? null
          : projects !== undefined && projects !== null && projects.length > 0
            ? <InfiniteScroll
              dataLength={projects.length}
              next={getProjectsLocal}
              hasMore={hasMore}
              loader={<h4 className="loading-label">Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {projects.map((item, index) => {
                return <div
                  className="org-projects-section-item col-lg-12"
                  style={{ cursor: "pointer" }}
                  key={"OrgProjectsSection-item-" + index}
                  onClick={() => {
                    history.push(
                      AppUrls.projects_details_from_org
                        .replace(":orgName", orgName)
                        .replace(":orgId", orgId)
                        .replace(":projectName", item.title)
                        .replace(":id", item.id)
                    )
                  }}
                >
                  <ProjectItem
                    projectId={item.id}
                    author={item.by}
                    title={item.title}
                    overview={item.description}
                    amount={item.raisedAmount}
                    totalamount={item.targetAmount}
                    endDate={item.endDate}
                    img={item.image}
                    isDisabled={!item.isActive}
                    isEnded={item.hasEnded}
                    isOpened={item.isOpen}
                  />
                </div>
              })}
            </InfiniteScroll>
            : <NoProjects isAdmin={true} />
      }
    </div>
  );
}
