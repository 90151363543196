import React from "react";
/* import OrganizationsTab from "../reusableComponents/OrganizationsTab/OrganizationsTab"
 */
import OrganizationAdmin from "../../pages/SuperAdmin/OrganizationsAdmin";
import "./TrendingOrganizationTab.css";

const TrendingOrganizationTab = () => {
    return (
        <OrganizationAdmin isTrending={true} />
    );
};

export default TrendingOrganizationTab;