import React from 'react'
import "./Review.css";
import defaultImage from "../../../images/img-default.svg"
import StarRating from '../../reusableComponents/StarRating/StarRating';
import * as helpers from "../../../global/helpers";
import greenDot from '../../../images/green-dot.svg';

/* import ic_3_dots from "../../../images/ic_3_dots.png";
import ic_delete from "../../../images/ic_delete.svg";
import { Dropdown } from 'react-bootstrap'; */

const Review = (props) => {

    let img = defaultImage;
    /*     if (props.item.img !== undefined && props.item.img !== null && props.item.img !== "") {
            img = props.img
        } */

    let review = props.review;

    return (
        <div className="review-item-container mx-auto">
            <div className="d-flex justify-start w-100">
                <img className="review-rounded-img" src={img} width={"70px"} height={"70px"} alt='' />
                <div className="ml-3">
                    <div className="review-reviewer">
                        {review.reviewer}
                    </div>
                    <div className="d-flex mt-1">
                        <div className="mr-2">{helpers.getTimeFromUnix(review.createdAtUnix)}</div>
                        <img src={greenDot} alt='' className="mr-2" />
                        <div>{helpers.getDateFromUnix(review.createdAtUnix)}</div>
                    </div>
                    <StarRating size="18px" rate={review.value} isDisabled={true} />

                </div>
                {/* <Dropdown className="ml-auto mr-4">
                    <Dropdown.Toggle
                        className="review-option-dropdown"
                        style={{ background: "transparent", padding: "0px" }}
                    >
                        <img
                            src={ic_3_dots}
                            alt="options"
                            width={"20px"}
                            height={"4px"}
                            style={{ cursor: "pointer" }}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="review-option-dropdown-menu">
                        <Dropdown.Item
                            as="button"
                            className="d-flex"
                            onClick={(e) => {
                                e.preventDefault();
                                props.openDeleteModal(review.id)
                            }}>
                            <img style={{ marginTop: "3px" }} src={ic_delete} alt="remove" className="mr-2" width={"10.77px"} height={"11.67px"} />
                            <div style={{ color: "#FF7E7E" }}> Remove</div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}

            </div>
            <div className="review-item-text">
                {
                    review.feedback
                }
            </div>
        </div>
    )
}


export default Review