
import React from 'react';
import RecurringDonation from '../components/RecurringDonation/RecurringDonation'

const recurringDonationPage = () => {
    return (
        <RecurringDonation />
    )
}

export default recurringDonationPage;