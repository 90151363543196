import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Select from 'react-select';
import DonateOrganizationItem from '../reusableComponents/DonateOrganizationItem/DonateOrganizationItem';
import './DonateOrganizationsTab.css';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useFetchOrgs } from '../../hooks/useFetchOrgs';

import { SpinnerDiv } from '../SpinnerDiv';

import * as helpers from "../../global/helpers";

const donationsType = [
  { value: 'all', label: 'All' },
  { value: 'once', label: 'One-Time' },
  { value: 'recurring', label: 'Recurring' },
];

const DonateOrganizationsTab = () => {

  const [recurring, setRecurring] = useState('all');
  const { data, hasNextPage, fetchNextPage, refetch, isFetching } = useFetchOrgs({
    project: false,
    recurringType: recurring,
  },{
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch({
      refetchPage: (page, index) => index === 0,
    });
  }, [recurring, refetch]);

  const handleSelect = (e) => {
    setRecurring(e.value);
  };
  const selectedTypeStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF',
      borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      borderColor: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };

  return (
    <Container id='donate_organizations_tab-div'>
      <div className='d-lg-flex align-items-lg-center col-lg-8 px-lg-0 px-sm-0 px-md-0 donate_filter-div'>
        <Select
          options={donationsType}
          isSearchable={false}
          styles={selectedTypeStyles}
          id='donate_select_type'
          onChange={handleSelect}
          defaultValue={donationsType.filter(item => item.value === "all")[0]}
        />
      </div>
      {isFetching ? <SpinnerDiv color='#7CB1FF' /> : data && (
        <InfiniteScroll
          className='pb-4'
          style={{ overflow: 'hidden' }}
          dataLength={data.pageParams[data.pageParams.length - 1] || 0}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<SpinnerDiv color='#7CB1FF' />}
          scrollableTarget='scrollableDiv'
        >
          {data.pages.map((page) =>
              page.data.map((org) => (
                <div key={org.id} className='my-2'>
                  <DonateOrganizationItem
                    title={org.title}
                    amount={org.amount}
                    recurringText={org.recurringText}
                    nextDonationAt={helpers.dateFormmater(org.nextDonationAt)}
                    profileImage={org.profileImage}
                    recurring={org.recurring}
                  />
                </div>
              ))
            )}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default DonateOrganizationsTab;
