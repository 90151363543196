import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/Greenlogo.png";
import "./ResetPasswordSuccess.css";

const ResetPasswordSuccessPage = (props) => {
  return (
    <section id="reset_password_success-section">
      <div className="reset_password_success_div">
        <div className="reset_password_success_inner">
          <div className="reset_password_success_item">
            <div id="reset_password_success_nav-div">
              <div className="row">
                <div className="col-lg-6" id="nav_first_col-div"></div>
                <div className="col-lg-6" id="nav_second_col-div">
                  <div className="container text-right">
                    <p className="contact_us_link">Contact Us</p>
                  </div>
                </div>
              </div>
              <div className="text-center" id="nav_logo-div">
                <img src={Logo} className="mx-auto sadaqa-logo" alt="" />
              </div>
            </div>
            <div id="main-div">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="justify-content-center">
                    <div className="text-center">
                      <div className="reset_success_main_content">
                        <img src={props.image} className="reset_success_image" alt="" />
                        <h4 className="reset_success_subtitle">
                          {props.title}
                        </h4>
                        <p className="reset_success_subtitle1">
                          {props.subtitle1}
                        </p>
                        <Link className="to_login" to="/Login">
                          <button id="reset_pass_to_login-btn" className="btn btn-primary btn-block">
                            Log in
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordSuccessPage;
