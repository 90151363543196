import React, { useState } from 'react';
import About from '../About';
import Donations from '../Donations';
import Projects from '../Projects';
import Reviews from '../Reviews';
import * as helpers from "../../../global/helpers";

export default function Tabs({ orgDetails = null, userType }) {
  const [active, setActive] = useState('about');
  let status = ""
  if (orgDetails !== null && userType === "admin") {
    status = helpers.getOrgStatus(orgDetails.isActive, orgDetails.isApproved)
  }
  return (
    <div className='pl-lg-5 pl-md-0'>
      {
        orgDetails !== null
          ? userType === "admin" && (status === "pending" || status === "rejected" )
            ? <ul className='org-tabs' >
              <li
                onClick={() => setActive('about')}
                className={`${active === 'about' ? 'active-org-tab' : ''} `}
              >
                About
              </li>
            </ul>
            : <ul className='org-tabs'>
              <li
                onClick={() => setActive('about')}
                className={`${active === 'about' ? 'active-org-tab' : ''} `}
              >
                About
              </li>
              <li
                onClick={() => setActive('projects')}
                className={`${active === 'projects' ? 'active-org-tab' : ''} `}
              >
                Projects
              </li>
              <li
                onClick={() => setActive('donations')}
                className={`${active === 'donations' ? 'active-org-tab' : ''} `}
              >
                Donations
              </li>
              <li
                onClick={() => setActive('reviews')}
                className={`${active === 'reviews' ? 'active-org-tab' : ''} `}
              >
                Reviews
              </li>
            </ul>
          : null
      }

      <div className='mt-4'>
        {
          orgDetails !== null
            ?
            active === 'about' ? (
              <About orgDetails={orgDetails} userType={userType} />
            ) : active === 'projects' ? (
              <Projects orgId={orgDetails.id} />
            ) : active === 'donations' ? (
              <Donations id={orgDetails.id} name={orgDetails.name} userType={userType} isProjectsDonations={false} />
            ) : (
              <Reviews orgId={orgDetails.id} userType={userType} />
            )
            : null
        }
      </div>
    </div >
  );
}
