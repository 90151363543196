import { useParams } from 'react-router';
import { BASE_URL } from '../constants/Constants';

import { useMutation, useQueryClient } from 'react-query';
import { putImage } from '../global/helpers';

export const usePatchImage = (guid, textarea, setDisabled, setIsOpen) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  let url = `${BASE_URL}/projects/${id}/images/${guid}`;

  const handlePatch = async () => {
    setDisabled(true);
    let data = {
      imageGuid: guid,
      caption: textarea,
    };
    await putImage(url, data);
  };

  return useMutation(handlePatch, {
    onSuccess: () => {
      queryClient.invalidateQueries('images');
      setDisabled(false);
      setIsOpen(false);
    },
    onError: () => {
      setIsOpen(false);
    },
  });
};
