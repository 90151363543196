import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import { ThreeDots } from 'react-bootstrap-icons';
import {
  Link,
  useParams,
  useHistory
} from 'react-router-dom';
import Green from '../../../Icons/green2.svg';
import Disable from '../../../Icons/disable.svg';
import Activate from '../../../Icons/activate.svg';
import Coins from '../../../Icons/Coins.svg';
import LinkVector from '../../../Icons/link.svg';
import Edit from '../../../Icons/edit.svg';
import Remove from '../../../Icons/remove.svg';
import RemoveDisbled from '../../../images/remove-disabled.svg';
import share from '../../../images/share.svg';
import imgDefault from "../../../images/img-default.svg";
import icon from '../../../images/blueInformationIcon.svg';
import * as helpers from "../../../global/helpers";
import * as Constants from "../../../constants/Constants";
import * as AppUrls from "../../../constants/AppUrls";
import DeleteProjectModal from '../../../components/Projects/DeleteProjectModal/DeleteProjectModal';
import DisableProjectModal from '../../../components/Projects/DisableProjectModal/DisableProjectModal';
import DeleteIsDisabledModal from '../../../components/Projects/DeleteIsDisabledModal/DeleteIsDisabledModal';
import './InfoCard.css';

export default function InfoCard({ projectDetails = null, isFromProjectDonation = false }) {
  let userType = helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? 'contributor' : '';

  let history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [isDeleteDisableModalOpen, setIsDeleteDisableModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const deleteProject = () => {
    helpers.deleteProject(projectDetails.id)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            helpers.successNotify("Project Deleted Successfully")
            history.push(AppUrls.projects)
          }
        }
      })
  }

  const disableProject = () => {
    helpers.disableProject(projectDetails.id, isDisabled)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            setIsDisableModalOpen(false)
            setIsDisabled(!isDisabled)
          }
        }
      })
  }

  useEffect(() => {
    if (projectDetails !== null) {
      setIsDisabled(!projectDetails.isActive)
    }
  }, [projectDetails])

  let title = projectDetails !== null ? projectDetails.title : "";
  let id = projectDetails !== null ? projectDetails.id : "";
  let raisedAmount = projectDetails !== null ? projectDetails.raisedAmount : ""
  let targetAmount = projectDetails !== null ? projectDetails.targetAmount : ""
  let shareUrl = projectDetails !== null ? projectDetails.shareUrl : ""
  let endDate = projectDetails !== null ? projectDetails.endDate : ""
  let isOpen = projectDetails !== null ? projectDetails.isOpen : ""
  let createdOn = projectDetails !== null ? projectDetails.createdOn : ""
  let percentageRaised = projectDetails !== null ? (projectDetails.raisedAmount / projectDetails.targetAmount) * 100 : 0
  let isDeleteEnabled = projectDetails !== null && percentageRaised > 0 ? false : true;
  let deleteIcon = isDeleteEnabled ? Remove : RemoveDisbled;
  let deleteColor = isDeleteEnabled ? "#FF7E7E" : "#90AD98";

  /*   if (isDisabled !== isDisabledLocal) {
      setIsDisabled(isDisabledLocal)
    } */

  let disabledActivated = isDisabled ? Activate : Disable
  let hasEndedLocal = projectDetails !== null ? projectDetails.hasEnded : false

  if (hasEnded !== hasEndedLocal) {
    setHasEnded(hasEndedLocal)
  }

  let imageSrc = imgDefault

  if (projectDetails !== null && projectDetails.image !== undefined && projectDetails.image !== null && projectDetails.image !== "" && projectDetails.image !== "guid") {
    imageSrc = projectDetails !== null ? Constants.BASE_URL + "/media/" + projectDetails.image + "/200" : imgDefault
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
  }

  const endDateTooltip = (props) => (
    <Tooltip className="pjt_info_end_date-tooltip" {...props}>
      Ends at 12 AM GMT (midnight)
    </Tooltip>
  );

  const { projectName, id: projectId } = useParams();
  console.log(projectName, projectId)

  return (
    <div className='relative p-3 bg-white shadow rounded pb-5'>
      <img
        src={Green}
        alt='green'
        className='absolute'
        style={{
          top: '0px',
          left: '0px',
          zIndex: '0'
        }}
      />

      {
        isFromProjectDonation
          ? <div className="mt-4" />
          : <div className='text-right relative'>
            <Dropdown className='ml-2'>
              <Dropdown.Toggle variant='light' id='dropdown-basic'
                style={{
                  padding: '0px',
                  background: 'none',
                  boxShadow: 'none'
                }}
              >
                {
                  helpers.getUserType() === Constants.USER_TYPE_CONTRIB ?
                    ''
                    :
                    <ThreeDots
                      style={{
                        color: '#7CB1FF',
                        fontSize: '24px'
                      }}
                    />
                }
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  border: '1px solid #7CB1FF'
                }}
              >
                {
                  helpers.getUserType() === Constants.USER_TYPE_ORG
                    ? <>
                      <Dropdown.Item className="mb-1" onClick={(e) => {
                        let projectDetails = title + "/" + id
                        history.push("/projects/" + projectDetails + "/edit")
                      }}>
                        <span>
                          <img src={Edit} alt='edit' className='mr-2' />
                        </span>
                        Edit
                      </Dropdown.Item>
                      <div className="mb-1"
                        style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "#7CB1FF"
                        }}
                      />
                    </>
                    : null
                }
                <Dropdown.Item className="mb-1" onClick={(e) => {
                  e.preventDefault()
                  setIsDisableModalOpen(true)
                }}>
                  <span>
                    <img src={disabledActivated} alt='disable' className='mr-2' />
                  </span>
                  {
                    isDisabled
                      ? <>Activate</>
                      : <>Disable</>
                  }
                </Dropdown.Item>

                {
                  helpers.getUserType() === Constants.USER_TYPE_ORG
                    ?
                    <Dropdown.Item onClick={(e) => {
                      e.preventDefault()
                      if (isDeleteEnabled)
                        setIsDeleteModalOpen(true)
                      else
                        setIsDeleteDisableModalOpen(true)
                    }}
                    >
                      <span>
                        <img src={deleteIcon} alt='remove' className='mr-2' />
                      </span>
                      <span style={{ color: deleteColor }}>Delete</span>
                    </Dropdown.Item>
                    : null
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
      }


      <div className='text-center d-sm-flex d-md-block'>
        <div>
          <img
            src={imageSrc}
            alt={title}
            className='relative'
            style={{
              zIndex: '1',
              borderRadius: "4px",
              width: "210px",
              height: "210px"
            }}
            onError={addDefaultSrc}
          />
          {
            /* isDisabled
              ? <div className="project-disable-overlay text-align-center mx-auto" style={{ width: "210px", height: "210px" }}>
                <div className="project-disable-overlay-text mx-auto">
                  Disabled
                </div>
              </div>
              : null */
          }

          {
            hasEnded
              ?
              <div
                className="project-has-ended-overlay text-align-center mx-auto"
                style={{
                  width: "210px",
                  height: "210px",
                  top: "40px"
                }}
              >
                <div className="project-has-ended-overlay-text text-align-center mx-auto">
                  Ended
                </div>
              </div>
              : isDisabled
                ?
                <div
                  className="project-disable-overlay text-align-center mx-auto"
                  style={{
                    width: "210px",
                    height: "210px",
                    top: "40px"
                  }}
                >
                  <div className="project-disable-overlay-text text-align-center mx-auto">
                    Disabled
                  </div>
                </div>
                : null
          }
        </div>

        <div className='ml-sm-5 ml-md-0'>
          <h1 className='text-capitalize text-md mt-5 mb-4 tracking-normal'>{title}</h1>
        </div>
      </div>
      <div className='px-5 mt-5'>
        <div className='mt-3 d-flex align-items-center'>
          <img src={Coins} alt='money' />
          <p className='mb-0 ml-3'>
            <strong className='font-weight-bold'>${raisedAmount} raised </strong> of ${targetAmount}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <ProgressBar
            now={percentageRaised}
            style={{
              position: 'unset',
              marginTop: '10px',
              width: '100%'
            }}
          />
        </div>
        {
          shareUrl !== null ?
            <div className='mt-4'>
              <img src={LinkVector} alt='link' />
              <a href={shareUrl} className='ml-2' style={{ color: '#3284FF' }}>
                {shareUrl}
              </a>
            </div>
            :
            null
        }
        <div className="mt-5">
          <div className='d-flex justify-content-between align-items-center'>
            <h1 className='mb-0 text-xs font-weight-normal text-normal tracking-normal'>
              End Date
              <span>
                {
                  endDate !== null && endDate !== "" ?
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={endDateTooltip}
                    >
                      <img src={icon} width={15} height={15} alt='' className="ml-1" />
                    </OverlayTrigger>
                    :
                    ''
                }
              </span>
            </h1>
            <h1
              style={{ color: '#67DB88' }}
              className=' mb-0 text-xs font-weight-normal text-normal tracking-normal'
            >
              {
                endDate !== null && endDate !== "" ?
                  <span
                    style={{
                        color: hasEnded === true ? '#ff7e7e' : ''
                    }}
                  >{endDate}</span>
                  : isOpen
                    ? <span>Open</span>
                    :
                    null
              }
            </h1>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-3'>
            <h1 className='mb-0 text-xs font-weight-normal text-normal tracking-normal'>
              Created On
            </h1>
            <h1 className=' mb-0 text-xs font-weight-normal text-normal tracking-normal'>
              {createdOn}
            </h1>
          </div>
        </div>
      </div>
      {userType === 'contributor' ?
        <div className='text-center mt-4'>
          <div className="btn-group align-items-center donate_share-grp" role="group">
            <Link className="btn btn-primary mr-2" id="pi_donate-btn" to={`/projects/${projectName}/${projectId}/donation`}>
              Donate
            </Link>
            <button type="button" className="btn btn-primary d-none" id="pi_share-btn">
              <img src={share} id="share-icon" alt="" />
              <p>Share</p>
            </button>
          </div>
        </div>
        :
        ''
      }
      <DeleteProjectModal
        projectName={title}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        submitFunc={deleteProject}
      />
      <DisableProjectModal
        projectName={title}
        isDisable={!isDisabled}
        isOpen={isDisableModalOpen}
        setIsOpen={setIsDisableModalOpen}
        submitFunc={disableProject}
        type={isDisabled ? "confirmation-primary" : "confirmation-danger"}
      />
      <DeleteIsDisabledModal
        isOpen={isDeleteDisableModalOpen}
        setIsOpen={setIsDeleteDisableModalOpen}
      />
    </div>
  );
}
