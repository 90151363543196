import React, { useEffect, useState } from "react";
import "./OrgAccount.css";
import imgDefault from "../../../images/img-default.svg"
import topBg from "../../../images/AccountBG.png"
import { Badge, Col, Row } from "react-bootstrap";
import { ReactComponent as Edit } from "../../../images/edit-blue.svg";
import star from '../../../images/star.svg';
import { ReactComponent as Location } from "../../../images/Location.svg";
import { ReactComponent as Call } from "../../../images/Call.svg";
import { ReactComponent as Message } from "../../../images/Message.svg";
import * as helpers from "../../../global/helpers";
import * as Constants from "../../../constants/Constants";
import * as AppUrls from "../../../constants/AppUrls";

import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";

function OrgAccount(props) {
    let history = useHistory();

    const [imgLoaded, setImgLoaded] = useState(false);
    const [profileImg, setProfileImg] = useState(null);
    const [name, setName] = useState("");
    const [rating, setRating] = useState(null);
    const [supportingCauses, setSupportingCauses] = useState([]);
    
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCityLocal] = useState("");
    const [state, setStateLocal] = useState("");
    const [zipCode, setZipCode] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [federalTaxId, setFederalTaxId] = useState("");
    const [documen501, setDocument501] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        getProfile()
    }, [])

    function getProfile() {
        helpers.getProfile()
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        let profile = response.data;
                        let profileImgLocal = imgDefault;
                        if (profile.image !== undefined && profile.image !== null && profile.image !== "" && profile.image !== "guid") {
                            let imgUrl = Constants.BASE_URL + "/media/" + profile.image + "/200" //first field: GUID, second is: size
                            profileImgLocal = imgUrl;
                        }

                        let documen501Local = "";
                        if (profile.documentGuid !== undefined && profile.documentGuid !== null && profile.documentGuid !== "" && profile.documentGuid !== "documentGuid") {
                            let fileUrl = Constants.BASE_URL + "/media/" + profile.documentGuid
                            documen501Local = fileUrl;
                        }
                        setProfileImg(profileImgLocal);
                        setName(profile.name);
                        setRating(profile.rating);
                        setSupportingCauses(profile.supportingCausesWeb);
                        let streetAddressLocal = "";
                        let cityLocal = "";
                        let stateLocal = "";
                        let zipCodeLocal = "";

                        if (profile.streetAddress !== null && profile.streetAddress !== "") {
                            streetAddressLocal = profile.streetAddress;
                        }

                        if (profile.city !== null && profile.city !== "") {
                            cityLocal = profile.city;
                        }

                        if (profile.state !== null && profile.state !== "") {
                            stateLocal = profile.state.value;
                        }
                        if (profile.zipCode !== null && profile.zipCode !== "") {
                            zipCodeLocal = profile.zipCode
                        }

                        setStreetAddress(streetAddressLocal);
                        setCityLocal(cityLocal);
                        setStateLocal(stateLocal);
                        setZipCode(zipCodeLocal);

                        setPhoneNumber(profile.fullPhoneNumber);
                        setEmail(profile.email);
                        setFederalTaxId(profile.federalTaxID);
                        setDocument501(documen501Local);
                        setDescription(profile.description);
                    }
                }
            })
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = imgDefault;
        setImgLoaded(true)
    }

    const saveFile = () => {
        saveAs(
            documen501,
            "501 (c) Document Organization Name.pdf"
        );
    };

    let displayImg = imgLoaded ? "inherit" : "none";

    return (
        <div className="org-account-container">
            <img className="org-account-top-bg" src={topBg} alt="bg" />
            <Row>
                <Col lg={12}>
                    <button className="org-edit-item" onClick={(e) => {
                        e.preventDefault();
                        history.push(AppUrls.edit_account);
                    }}>
                        <Edit id="edit-icon" />
                        Edit
                    </button>
                </Col>
            </Row>
            <Row>
                <Col lg={4} sm={12} xs={12} style={{ display: "flex" }}>
                    {
                        !imgLoaded
                            ? <div className="account-img" style={{ backgroundColor: "white" }} />
                            : null
                    }
                    <img
                        style={{ display: displayImg }}
                        className="account-img"
                        src={profileImg}
                        alt="Profile"
                        onError={addDefaultSrc}
                        onLoad={() => { setImgLoaded(true) }} />

                    <div className="account-resp">
                        <div className="org-account-title ">
                            {name}
                        </div>
                        <div className="org-account-rate">
                            <img src={star} alt="rate" className="org-account-star mr-2" />
                            <span className="mt-2">
                                {rating}
                            </span>
                        </div>
                    </div>


                </Col>

                <Col lg={8} sm={12} xs={12} className="padding-responsive">
                    <div className="org-account-title account-not-resp">
                        {name}
                    </div>
                    <div className="org-account-rate account-not-resp">
                        <img src={star} alt="rate" className="org-account-star mr-2" />
                        <span className="mt-2">
                            {rating}
                        </span>
                    </div>

                    <div className="org-account-tag-container mt-2">
                        {
                            supportingCauses.map((item, index) => {
                                return <Badge
                                    key={"supporting-causes-key" + index}
                                    className="org-account-tag ml-1 mt-1"
                                    variant="info" >
                                    {item.value}
                                </Badge>
                            })
                        }
                    </div>

                    <div className="mt-4">
                        <div className="org-account-contact-title mb-3">
                            CONTACT
                        </div>
                        <p className='contributor-location-section ml-0 mb-2 d-flex'>
                            <Location id="location-icon" />
                            <div className="d-block">
                                <span className='d-block'>{streetAddress}</span>
                                <span>{city}, {state}, {zipCode}</span>
                            </div>
                        </p>
                        <p className="contributor-phone-section ml-0 mb-2">
                            <Call id="call-icon" />
                            {phoneNumber}
                        </p>
                        <p className="contributor-email-section ml-0 mb-2">
                            <Message id="message-icon" />
                            {email}
                        </p>
                    </div>

                    <div className="org-account-subtitle mt-3 mb-2">
                        Federal Tax ID
                    </div>
                    <div>
                        {federalTaxId}
                    </div>


                    <div className="org-account-subtitle mt-3 mb-2">
                        501 (c) Document
                    </div>

                    {
                        documen501 !== ""
                            ? <div style={{ color: "#3284FF", cursor: "pointer" }} onClick={saveFile}>501 (c) Document {name}.pdf</div>
                            : <>-</>
                    }

                </Col>
            </Row>

            <Row className="org-account-desc">
                {description}
            </Row>
        </div >
    )
}

export default OrgAccount