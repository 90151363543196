import React from "react";
import ResetPasswordSuccess from "../components/ResetPasswordSuccess/ResetPasswordSuccess";
import resetSuccessImage from "../images/resetSuccessimage.svg";

const resetPasswordSuccess = () => {
  return (
    <ResetPasswordSuccess
      title={"PASSWORD RESET"}
      subtitle1={"Your password has been reset successfully"}
      image={resetSuccessImage}
      showResendEmail={false}
    />
  );
};

export default resetPasswordSuccess;
