import React from 'react';
import "../../reusableComponents/ConfirmationModal/ConfirmationModal.css"
import { Modal } from 'react-bootstrap';
import ic_top_bg from "../../../images/ic_top_bg.svg";
import ic_close from "../../../images/ic_close.svg";

export default function DisableProjectModal(
    {
        isOpen,
        setIsOpen,
        type = "confirmation-info"
    }) {

    return (
        <Modal className={"confirmation-modal " + type} show={isOpen} onHide={setIsOpen} centered >

            <div style={{ display: "flex" }}>
                <div className="confirmation-modal-title">
                    Delete Disabled
                </div>
                <div style={{ marginLeft: "auto", }}>
                    <img src={ic_top_bg} alt="" />
                    <img src={ic_close} alt="" className="confirmation-modal-close" onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                    }} />
                </div>
            </div>
            <div style={{ marginLeft: "27px", marginRight: "27px" }}>
                <Modal.Body className='px-0 pb-0 mb-0 pt-0' style={{ letterSpacing: "0px" }}>
                    <div >
                        You cannot delete this project. It has already received donations.
                    </div>
                    <div className='d-flex text-right' style={{ justifyContent: "space-evenly", marginTop: "30px", float: "right" }}>
                        <button className="confirmation-modal-submit" onClick={(e) => {
                            e.preventDefault();
                            setIsOpen(false);
                        }}>Ok</button>
                    </div>
                </Modal.Body>
            </div>

        </Modal>
    );
}