import React, { Component } from 'react';
import "./SingleFileUploadComponent.css";
import { Form, Button } from "react-bootstrap";

class SingleFileUploadComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedFile: null,
            selectedFileName: null,
            defaultStyle: null
        }
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.documentRef = React.createRef(null);
    }

    uploadSingleFile(e) {
        let fileUploaded = e.target.files[0];
        let fileUploadedName = e.target.files[0].name;
        this.setState({
            selectedFile: fileUploaded,
            selectedFileName: fileUploadedName,
        })
        e.value = null;
    }

    getFile = () => {
        return this.state.selectedFile
    }

    render() {
        let hideFileStyle;
        let resetFile = null;
        let resetFileName = null;
        const handleClick = (e) => {
            this.setState({
                selectedFile: resetFile,
                selectedFileName: resetFileName
            })
            if (this.documentRef !== null && this.documentRef.current !== null) {
                this.documentRef.current.value = null;
            }
            hideFileStyle = {
                display: "none",
            }
        }
        let fileContent;
        let defaultStyle;
        let replaceFile;
        if (this.state.selectedFile) {
            defaultStyle = {
                display: "none",
            };
            fileContent =
                <Form.Control
                    value={this.state.selectedFileName}
                    onChange={e => this.setState({
                        selectedFile: e.target.files[0],
                        selectedFileName: e.target.files[0].name,
                    })}
                    className="uploaded_file"
                />
            replaceFile = <Button id="replace_file-button" onClick={handleClick}></Button>
        }

        let accept = this.props.accept !== undefined ? this.props.accept : ""
        return (
            <>
                <Form.Group className="replace_file_form" style={hideFileStyle}>
                    {fileContent}{replaceFile}
                </Form.Group>
                <Form.Group
                    {...this.props.field}
                    style={{
                        defaultStyle,
                        borderColor: !this.props.errors.uploadFile ? '' : 'red'
                    }}
                    id="fileUploadDiv"
                >
                    <Form.Control
                        type="file"
                        className='file_upload_input'
                        onChange={this.uploadSingleFile}
                        ref={this.documentRef}
                        accept={accept}
                    />
                </Form.Group>
            </>
        )
    }
}

export default SingleFileUploadComponent;
