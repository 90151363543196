import React, { useEffect, useState } from 'react';

import { Search } from 'react-bootstrap-icons';
//import Download from '../../../Icons/download.svg';

/* import Prev from '../../../Icons/prev.svg';
import Next from '../../../Icons/next.svg'; */

import { Link } from 'react-router-dom';

import { organizations_details } from '../../../constants/AppUrls';

import OrganizationItem from '../../../components/reusableComponents/OrganizationItem/OrganizationItem';
import * as helpers from "../../../global/helpers"
import * as Constants from "../../../constants/Constants";

import { Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function OrganizationAdmin({ isTrending = false, isTrendingItem = false }) {
  const [orgs, setOrgs] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filterKey, setFilterKey] = useState("");

  useEffect(() => {
    getOrgsLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isSearching && orgs.length === 0 && page === 0 && !isFinished) {
      getOrgsLocal()
    }
    if (isSearching && orgs.length === 0 && page === 0 && !isFinished) {
      callGetOrgs(searchText, 0, filterKey, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, orgs, page, isFinished])

  useEffect(() => {
    if (filterKey !== null) {
      searchAndFilterFunctionality()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey])

  const searchOrg = () => {
    callGetOrgs(searchText, page, filterKey)
  }

  const callGetOrgs = (query, pageParam, filterKey, isInitialSearch = false) => {
    helpers.getOrgs(query, pageParam, filterKey, isTrending)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            if (isInitialSearch) {
              setPage(1)
              setOrgs(response.data.data)
            } else {
              let pageLocal = page + 1;
              setPage(pageLocal)
              let orgsLocal = orgs.concat(response.data.data)
              setOrgs(orgsLocal)
            }
            setIsFinished(true)
            if (response.data.data.length === 0 || response.data.data.length < 10) {
              setHasMore(false)
            } else {
              if (!hasMore)
                setHasMore(true)
            }
          }
        }
      })
  }

  const getOrgsLocal = () => {
    callGetOrgs("", page)
  }



  const searchAndFilterFunctionality = () => {
    if (searchText === "" && filterKey === "") {
      if (isSearching) {
        setIsSearching(false)
        setOrgs([])
        setPage(0)
        setIsFinished(false)
        return
      } else {
        return;
      }
    } else {
      setIsSearching(true)
      setOrgs([])
      setPage(0)
      setIsFinished(false)
    }
  }

  let nextFunc = isSearching ? searchOrg : getOrgsLocal
  let userType = helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? 'contributor'
    : helpers.getUserType() === Constants.USER_TYPE_ADMIN ? 'admin' : '';

  return (
    <div className='container-fluid container-org-items' style={{ minHeight: '70vh' }}>
      <div className='d-lg-flex justify-content-lg-between align-items-lg-center'>
        <div className='d-lg-flex  align-items-lg-center col-lg-8 px-lg-0 px-sm-0 px-md-0'>
          {isTrending === true ? 
            ''
          : 
            <div className="d-flex mr-lg-3 relative col-lg-6 mb-md-4 mb-lg-0 mb-sm-4 px-0">
              <div className='flex-grow-1 mr-2'>
                <Search className='absolute ' style={{ top: '15px', left: '20px', color: '#90AD98' }} />
                <input
                  placeholder='Search'
                  className='form-control pl-5 bg-white shadow rounded'
                  style={{ color: '#90AD98' }}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
              <button className="btn btn-success ml-2 mr-4 rounded py-2 px-4" onClick={(e) => {
                e.preventDefault();
                searchAndFilterFunctionality()
              }} >
                search
              </button>
            </div>
          }
          {userType === 'admin' ? (
            <div className='d-flex justify-content-between align-items-center mb-lg-0 mb-md-4 mb-sm-4 my-xs-4'>
              <label
                htmlFor='filter_by'
                className='mb-0'
                style={{ color: '#90AD98', width: '100px' }}
              >
                Filter by
              </label>
              <select
                id='filter_by'
                className='form-control py-2 pr-5 pl-1 ml-3 shadow border-0 rounded'
                onChange={(e) => {
                  setFilterKey(e.target.value);
                }}
                value={filterKey}
              >
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Disabled</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          ) : userType === 'contributor' ? (
            ''
          ) : ''}
        </div>
        {/* userType === 'admin' ? (
          <button className='btn btn-success rounded d-flex align-items-center col-md-12 col-lg-auto'>
            <img src={Download} alt='logo' className='mr-2  ' />
            Download CSV
          </button>
        ) : userType === 'contributor' ? (
          ''
        ) : '' */}
      </div>
      {
        !isFinished
          ? <h4 className="loading-label">Loading...</h4>
          : orgs !== undefined && orgs !== null && orgs.length > 0
            ? <div>
              <InfiniteScroll
                className="row w-100"
                dataLength={orgs.length}
                next={nextFunc}
                hasMore={hasMore}
                loader={<h4 className="loading-label">Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {
                  orgs.map((data) => {
                    let orgLink = organizations_details.replace(":orgName", data.name).replace(":id", data.id);
                    let status = helpers.getOrgStatus(data.isActive, data.isApproved)
                    return (
                      <Col
                        key={"org-item-" + data.id}
                        className={
                          isTrending === true ? 
                            'col-lg-4 col-md-4 col-sm-12 col-12'
                          : 
                            'col-lg-4 col-md-4 col-sm-12 col-12 mt-4 mt-lg-5'
                        } 
                      >
                        <Link className='link-reset' to={orgLink} style={{ maxWidth: "350px", width: "100%" }}>
                          <OrganizationItem
                            data={data}
                            status={status}
                          />
                        </Link>
                      </Col>
                    )
                  })
                }
              </InfiniteScroll>
            </div>
            : null
      }

    </div >
  );
}