import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./OrganizationsDonationSummary.css";
import { getDonationSummary, getDateFromUnix, getTimeFromUnix, numberWithCommas } from "../../global/helpers";
import { useDispatch } from "react-redux";

const OrganizationsDonationSummary = ({ donationId }) => {
    const [donationReceipt, setDonationReceipt] = useState("");
    const [donationDate, setDonationDate] = useState("");
    const [donationTime, setDonationTime] = useState("");
    //donated to
    const [donatedTo, setDonatedTo] = useState("");
    const [donatedToCreatedBy, setDonatedToCreatedBy] = useState("");
    //contact details
    const [location, setLocation] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    //donation details
    const [donor, setDonor] = useState("");
    const [donorPhoneNumber, setDonorPhoneNumber] = useState("");
    const [donorEmail, setDonorEmail] = useState("");
    const [donorAddress, setDonorAddress] = useState("");
    //payment info
    const [paymentMethod, setPaymentMethod] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [amount, setAmount] = useState("");

    const [donationType, setDonationType] = useState("");

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
        getDonationDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDonationDetails = () => {
        getDonationSummary(donationId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        console.log(response.data);

                        let donationData = response?.data
                        setDonationReceipt(donationData?.donationId)
                        let date = getDateFromUnix(donationData?.createdAt);
                        setDonationDate(date);
                        let time = getTimeFromUnix(donationData?.createdAt);
                        setDonationTime(time);

                        let donatedToObj = donationData?.donatedTo
                        let donationTypeLocal = donationData?.donationCategory;
                        setDonationType(donationTypeLocal);

                        let donatedToLocal = donatedToObj?.title
                        setDonatedTo(donatedToLocal);

                        if (donationTypeLocal === "project") {
                            let donatedToCreatedByLocal = donatedToObj?.createdBy
                            setDonatedToCreatedBy(donatedToCreatedByLocal);
                        }

                        let contactDetailsObj = donationData?.contactDetails
                        setLocation(contactDetailsObj?.address);
                        setPhoneNumber(contactDetailsObj?.phone);
                        setEmail(contactDetailsObj?.email);

                        let donationDetailsObj = donationData?.donationDetails
                        setDonor(donationDetailsObj?.donor);
                        setDonorPhoneNumber(donationDetailsObj?.phone);
                        setDonorEmail(donationDetailsObj?.email);
                        setDonorAddress(donationDetailsObj?.address);

                        let paymentInfoObj = donationData?.paymentInfo
                        setPaymentMethod(paymentInfoObj?.paymentType);
                        setCardNumber(paymentInfoObj?.cardNumber);
                        setPaymentType(paymentInfoObj?.recurs);

                        let amountLocal = "$" + numberWithCommas(donationData?.amount)
                        setAmount(amountLocal);


                    }
                }
            })
    }

    return (
        <Container id="organizations_donation_summary-div">
            <Row className="orgs_donation_summary-row">
                <Col xs={6} md={4} className="donation_summary_col_one">
                    <div id="donation_time-div">
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Donation Receipt</div>
                            <div className="donation_receipt_txt detail_txt">{donationReceipt}</div>
                        </div>
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Date</div>
                            <div className="donation_date_txt detail_txt">{donationDate}</div>{/* February 12, 2021 */}
                        </div>
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Time</div>
                            <div className="time_txt detail_txt">{donationTime}</div>{/* 04:50 PM */}
                        </div>
                    </div>
                    <div id="donated_to-div">
                        <div className="donation_summary_title">Donated to</div>
                        <div className="humane_society_txt">{donatedTo}</div>
                        {
                            donationType === "project"
                                ? <div className="created_by_span">
                                    <div className='created_by_lbl'>Created by</div>
                                    <div className='created_by_txt'>{donatedToCreatedBy}</div>
                                </div>
                                : null
                        }

                    </div>
                    <hr className="donation_summary_hr" />
                    <div id="contact_details-div">
                        <p className="donation_summary_title">Contact Details</p>
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Location</div>
                            <div className="donation_summary_txt detail_txt">{location}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Phone</div>
                            <div className="donation_summary_txt detail_txt">{phoneNumber}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Email</div>
                            <div className="donation_summary_txt detail_txt">{email}</div>
                        </div>
                    </div>
                </Col>
                <Col xs={6} md={4} className="donation_summary_col_two">
                    <div id="donation_details-div">
                        <p className="donation_summary_title">Donation Details</p>
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Donor</div>
                            <div className="donation_summary_txt detail_txt">{donor}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Phone</div>
                            <div className="donation_summary_txt detail_txt">{donorPhoneNumber}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Email</div>
                            <div className="donation_summary_txt detail_txt">{donorEmail}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Address</div>
                            <div className="donation_summary_txt summary_address detail_txt">{donorAddress}</div>
                        </div>
                    </div>
                    <hr className="donation_summary_hr" id="details_info-hr" />
                    <div id="payment_info-div">
                        <p className="donation_summary_title">Payment info</p>
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Payment Method</div>
                            <div className="donation_summary_txt detail_txt">{paymentMethod}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Card number</div>
                            <div className="donation_summary_txt detail_txt">{cardNumber}</div>
                        </div>
                        <hr className="custom_hr" />
                        <div className="summary_span">
                            <div className="donation_summary_lbl">Payment Type</div>
                            <div className="donation_summary_txt detail_txt">{paymentType}</div>
                        </div>
                    </div>
                    <hr className="donation_summary_hr custom_hr_child" />
                    <div id="amount-div">
                        <div className="summary_span">
                            <div className="amount_lbl">Amount</div>
                            <div className="amount_txt">{amount}</div>
                        </div>
                    </div>
                </Col>
                <Col xs={6} md={4}></Col>
            </Row>
        </Container>
    );
};

export default OrganizationsDonationSummary;
