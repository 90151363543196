import React, { useState, useEffect } from "react";
import * as helpers from "../../global/helpers";
import * as Constants from "../../constants/Constants";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OrgName from "../SuperAdmin/OrgName";
import ContributorOrganizationPage from "../ContributorOrganizationPage";

function OrganizationDetailsWrapper() {
    const [orgDetails, setOrgDetails] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    let orgId = location.pathname.split("/").pop();

    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
        getOrgDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getOrgDetails = () => {
        helpers.getOrgById(orgId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        setOrgDetails(response.data);
                    }
                }
            })
    }

    const acceptRejectOrg = (isAccept) => {
        helpers.acceptRejectOrg(orgDetails.id, isAccept)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        let oldOrgDetails = { ...orgDetails };
                        let isApproved = isAccept ? 1 : 2
                        oldOrgDetails.isApproved = isApproved
                        setOrgDetails(oldOrgDetails)
                    }
                }
            })
    }

    const enableDisableOrg = (isDisable, setShow) => {
        helpers.disableEnableContrib(orgDetails.id, isDisable)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        let oldOrgDetails = { ...orgDetails };
                        let isActive = isDisable ? false : true
                        oldOrgDetails.isActive = isActive
                        setOrgDetails(oldOrgDetails)
                        setShow(false)
                    }
                }
            })
    }

    return (
        helpers.getUserType() === Constants.USER_TYPE_ADMIN
            ? <OrgName orgDetails={orgDetails} acceptRejectOrg={acceptRejectOrg} enableDisableOrg={enableDisableOrg} />
            : helpers.getUserType() === Constants.USER_TYPE_CONTRIB
                ? <ContributorOrganizationPage orgDetails={orgDetails} />
                : helpers.getUserType() === Constants.USER_TYPE_ORG
                    ? <div>not allowed</div>
                    : <div>Invalid User</div>

    )
}

export default OrganizationDetailsWrapper;