import React, { useState } from 'react'
import FilledStar from '../../../images/FilledStar.svg'
import UnfilledStar from '../../../images/UnfilledStar.svg'
import './StarRating.css'



const StarRating = (props) => {
    const [rating, setRating] = useState(props.rate)
    const [hover, setHover] = useState(null)
    let isDisabled = props.isDisabled !== undefined ? "star-disabled" : "";
    return (
        <div>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1
                return (
                    <label className={isDisabled}>
                        <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => setRating(!props.rate ? (ratingValue) : props.rate)}
                            style={{ display: "none" }}
                        />
                        <img src={ratingValue <= (hover || rating) ? FilledStar : UnfilledStar}
                            className="filled-star"
                            width={props.size || "20px"}
                            onMouseEnter={() => setHover(!props.rate ? (ratingValue) : props.rate)}
                            onMouseLeave={() => setHover(!props.rate ? (null) : props.rate)}
                            alt=''
                        />
                    </label>
                )
            })}
        </div>
    )
}

export default StarRating;