import React, { useState } from 'react';

import Green from '../../../Icons/green.svg';
import defaultImage from '../../../images/img-default.svg'
import * as Constants from "../../../constants/Constants";

import InfoCard from '../InfoCard';

export default function Card({ contribDetails, disableActivateContrib }) {
  const [show, setShow] = useState(false);
  let id = contribDetails !== null ? contribDetails.id : ""
  let name = contribDetails !== null ? contribDetails.fullName : "";
  let isActive = contribDetails !== null ? contribDetails.isActive : "";
  let contribImg = defaultImage
  if (contribDetails !== undefined && contribDetails.imageGuid !== null && contribDetails.imageGuid !== "" && contribDetails.imageGuid !== "guid") {
    let imgUrl = Constants.BASE_URL + "/media/" + contribDetails.imageGuid + "/200" //first field: GUID, second is: size
    contribImg = imgUrl;
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage;
  }

  return (
    <div
      className='py-4 bg-light shadow rounded px-2 relative border-0 cursor-pointer px-lg-4'
      onClick={() => setShow(true)}
    >
      <img src={Green} className='absolute ' style={{ top: '0px', left: '0px' }} alt='green' />
      <div
        className='relative d-flex justify-content-between align-items-center'
        style={{ zIndex: '10 !important' }}
      >
        <img src={contribImg} alt='human' onError={addDefaultSrc} style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
        <h1 className='text-capitalize mb-0 tracking-normal text-md text-align-left'>{name}</h1>
        {isActive ? (
          <button
            className='bg-white px-4 py-1 mr-1 rounded '
            style={{ border: '2px solid #7CB1FF', color: '#7CB1FF' }}
          >
            Active
          </button>
        ) : (
          <button
            className=' px-4 py-1 mr-1 rounded '
            style={{ border: '2px solid #FF7E7F', color: 'white', backgroundColor: '#FF7E7F' }}
          >
            Disabled
          </button>
        )}
      </div>

      {show === true ? (
        <div>
          <InfoCard
            show={show}
            setShow={setShow}
            contribId={id}
            name={name}
            img={contribImg}
            isActive={isActive}
            addDefaultSrc={addDefaultSrc}
            disableActivateContrib={disableActivateContrib} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
