import React from 'react';
import { useParams } from 'react-router';

import OrganizationsDonationSummary from '../../../components/DonationsHistory/OrganizationsDonationSummary';

export default function DonationSummary() {
  const { donationId } = useParams();

  return (
    <div>
      <OrganizationsDonationSummary donationId={donationId} />
    </div>
  );
}
