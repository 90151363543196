import React, { useState, useEffect } from 'react';

import { Dropdown } from 'react-bootstrap';
import Edit from '../../../Icons/edit.svg';
import { ThreeDots } from 'react-bootstrap-icons';

import ImageComponent from '../../../components/ImageComponent';
import ImagePlaceholder from '../../../components/ImagePlaceholder';

import useFetchImages from '../../../hooks/useFetchImages';
import { useQueryClient } from 'react-query';
import { SpinnerDiv } from '../../SpinnerDiv';

export default function Images() {
  const [isEditing, setIsEditing] = useState(false);
  const { data: images, isLoading } = useFetchImages();
  console.log('images', images);
  let totalImages = 0;
  if (images) totalImages = images.length;

  const userType = localStorage.getItem('KEY_USER_TYPE');
  const queryClient = useQueryClient();
  useEffect(() => {
    return () => queryClient.removeQueries('images');
  }, [queryClient]);

  return (
    <div className='p-5 bg-white rounded shadow '>
      {userType === 'contrib' ? (
        ''
      ) : (
        <Dropdown className='text-right mb-3'>
          <Dropdown.Toggle
            variant='light'
            id='dropdown-basic'
            style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
          >
            <ThreeDots style={{ color: '#7CB1FF', fontSize: '18px' }} />
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }} className=''>
            <Dropdown.Item onClick={() => setIsEditing(!isEditing)}>
              <span>
                <img src={Edit} alt='edit' className='mr-2' />
              </span>
              Edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      {images && images.length === 0 ? (
        <p className='text-md tracking-normal text-center text-normal' style={{ width: '100%' }}>
          No Images...
        </p>
      ) : (
        ''
      )}
      {isLoading ? (
        <SpinnerDiv color='#67DB88' />
      ) : (
        <div className='grid-images-admin mt-4'>
          {images.map((img) => (
            <ImageComponent
              key={img.imageGuid}
              image={img.imageGuid}
              imageObject={img}
              isEditing={isEditing}
              caption={img.caption}
            />
          ))}

          {isEditing && totalImages < 9 ? (
            <ImagePlaceholder isEditing={isEditing} setIsEditing={setIsEditing} />
          ) : (
            ''
          )}
        </div>
      )}
      {isEditing ? (
        <div className='d-md-flex d-block text-center text-md-left justify-content-center align-items-center mt-4'>
          <button
            onClick={() => setIsEditing(false)}
            className='py-2 px-4 rounded text-white'
            style={{ backgroundColor: '#67DB88', border: 'none', color: '#7CB1FF' }}
          >
            Done
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
