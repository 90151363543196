import React, { useState } from 'react';
import '../App.js';
import SideBar from '../components/reusableComponents/SideBar/SideBar';
import './HomePage.css';
import '../components/Tab/Tab.css';
import { connect } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as actions from '../store/actionTypes';
import { useHistory } from 'react-router-dom';
import ic_back_btn from '../images/ic_back_btn.svg';

import Burger from '../Icons/burger.svg';

function HomePage(props) {
  const [toggle, setToggle] = useState(false);
  const [isCriteria, setIsCriteria] = useState(false);

  let history = useHistory();

  const {
    location: { pathname },
  } = props;
  const pathnames = pathname.split('/').filter((x) => x);

  const isTitleShown = !props.isBreadcrumbShown ? 'inherit' : 'none';
  const isBreadcrumbShown = props.isBreadcrumbShown ? 'inherit' : 'none';

  const toggleSideBar = () => {
    console.log('toggleSideBar');
    setToggle(!toggle);
  };
  let lastItemIndex = 0;
  return (
    <>
      <SideBar SideBarData={props.SideData} toggle={toggle} setToggle={setToggle} />
      <div className='mobile-resp'>
        <div className='tabs relative main-header' style={{}}>
          <img src={Burger} alt='burger' className='burger ' onClick={toggleSideBar} />
          <h3
            className='tab-title'
            style={{ left: '40px', top: '40%', display: isTitleShown, width: 'calc(100% - 90px)' }}
          >
            {props.title}
          </h3>

          <Breadcrumb
            className='main-header-breadcrumb'
            style={{
              left: '40px',
              marginTop: '50px',
              display: isBreadcrumbShown,
              marginBottom: 'unset',
            }}
          >
            {pathnames.map((name, index) => {
              let routeIndex =
                isCriteria && pathnames.length > 3 && index === 1 ? index + 2 : index + 1;
              const routeTo = `/${pathnames.slice(0, routeIndex).join('/')}`;
              //console.log("index: " + index + " name: " + name)
              if (index === 0 && (name === 'projects' || name === 'organizations' || 'home') && !isCriteria) {
                setIsCriteria(true);
              }

              if (isCriteria && (index === 2 || index === 4)) {
                return null;
              }
              let isLast = false;
              if (isCriteria && pathnames.length === 3 && index === 1) {
                isLast = true;
                lastItemIndex = 1;
              }
              if (isCriteria && pathnames.length === 5 && index === 3) {
                isLast = true;
                lastItemIndex = 3;
              }
              if (index === pathnames.length - 1) {
                isLast = true;
                lastItemIndex = pathnames.length - 1;
              }

              if (pathnames.length === 1) {
                props.setIsBreadcrumbShown(false);
              }
              let nameLocal = name
                .split('_')
                .join(' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
              if (isLast)
                return (
                  <Breadcrumb.Item active key={'active-item-' + index}>
                    {nameLocal}
                  </Breadcrumb.Item>
                );
              else
                return (
                  <Breadcrumb.Item
                    key={'pathname-' + index}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(routeTo);
                    }}
                  >
                    {nameLocal}
                  </Breadcrumb.Item>
                );
            })}
          </Breadcrumb>

          <div className='ml-2 mb-2 mobile-header-resp' style={{ display: isBreadcrumbShown }}>
            {pathnames.map((name, index) => {
              let routeIndex = isCriteria && (pathnames.length === 3 || pathnames.length === 5) ? index : index + 1;
              const routeTo = `/${pathnames.slice(0, routeIndex).join('/')}`;

              if (index === pathnames.length - 2) {
                //to get item before last one
                let nameLocal = pathnames[lastItemIndex]
                  .split('_')
                  .join(' ')
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
                return (
                  <button
                    className='header-back-btn '
                    key={'back-btn-' + lastItemIndex}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(routeTo);
                    }}
                  >
                    <img
                      src={ic_back_btn}
                      alt='back'
                      className='mr-3'
                      style={{ width: '13.35px', height: '12.71px' }}
                    />
                    {nameLocal}
                  </button>
                );
              } else return null;
            })}
          </div>
        </div>

        <div
          id='scrollableDiv'
          style={{
            height: 'calc(100% - 140px)',
            overflowY: 'auto',
            backgroundColor: '#eefff3',
          }}
        >
          <div className='container-main'>{props.children}</div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    title: state.title,
    isBreadcrumbShown: state.isBreadcrumbShown,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsBreadcrumbShown: (isBreadcrumbShown) => {
      dispatch({
        type: actions.SET_IS_BREADCRUMB_SHOWN,
        payload: {
          isBreadcrumbShown: isBreadcrumbShown,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage));
