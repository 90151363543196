import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import Logo from '../../images/Greenlogo.png';
import BackButton from '../../images/back-button.png';
import Email from '../../images/emaiIcon.png';
import InfoVector from '../../images/feather_info.svg';
import './ForgotPassword.css';
import * as AppUrls from '../../constants/AppUrls';
import { useForgotPass } from '../../hooks/useForgotPass';

import { Spinner } from 'react-bootstrap';

const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
});

const ForgotPassword = (props) => {
  const { mutateAsync, isLoading } = useForgotPass();

  const {
    formState: { errors },
    control,
    getValues,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const [infoContent, setInfoContent] = React.useState({});
  const [forgotPasswordPage, setForgotPasswordPage] = React.useState({});
  const onSubmit = async () => {
    const email = getValues().email;
    if (!errors.email) {
      const forgotPasswordPageNewStyle = {
        height: 980,
      };
      const infoContentNewStyle = {
        display: 'inline',
      };

      await mutateAsync({ email });

      setInfoContent(infoContentNewStyle);
      setForgotPasswordPage(forgotPasswordPageNewStyle);
      // setTimeout(() => {
      //   history.push(AppUrls.reset_password);
      // }, 5000);
    }
  };
  return (
    <section id='forgot_password' style={forgotPasswordPage}>
      <div className='forgot_password-section' style={forgotPasswordPage}>
        <div className='forgot_password-inner'>
          <div className='forgot_password_item' style={forgotPasswordPage}>
            <div id='forgot_password_nav_content-div'>
              <div className='row'>
                <div className='col-lg-6' id='nav_content_first-div'>
                  <div className='container'>
                    <Link to='/'>
                      <img src={BackButton} className='back-button' alt='' />
                    </Link>
                  </div>
                </div>
                <div className='col-lg-6' id='nav_content_second-div'>
                  <div className='container text-right'>
                    <p className='contact-us-link'>Contact Us</p>
                  </div>
                </div>
              </div>
              <div className='text-center' id='nav_content_third-div'>
                <img src={Logo} className='mx-auto sadaqa-logo' alt='' />
              </div>
            </div>
            <div id='forgot_password_form-div'>
              <div className='container'>
                <div className='row justify-content-center align-items-center'>
                  <form
                    className='justify-content-center forgot_password_main_form'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className='info_content' style={infoContent}>
                      <img src={InfoVector} alt='info' className='infoIcon' />
                      <div className='info_text'>
                        <p className='info_first'>We have emailed your password reset link.</p>
                        <p className='info_second'>Don’t forget to check your spam folder.</p>
                      </div>
                    </div>
                    <h5 className='forgot_password_title'>Forgot your password?</h5>
                    <p className='forgot_password_text'>
                      Enter your email address and we’ll send you a link to reset your password
                    </p>
                    <Controller
                      render={({ field }) => (
                        <input
                          {...field}
                          name='email'
                          className='form-control email_input'
                          placeholder='Your email address'
                          style={{ borderColor: !errors.email ? '' : 'red' }}
                        />
                      )}
                      name='email'
                      control={control}
                      defaultValue=''
                    />
                    {errors.email && (
                      <p className='forgot_password_error'>{errors.email.message}</p>
                    )}
                    {isLoading ? (
                      <div className=' mt-3 text-center'>
                        <Spinner animation='border' role='status' style={{ color: '#67DB88' }} />
                      </div>
                    ) : (
                      <button
                        type='submit'
                        className='btn btn-primary btn-block forgot_password_button'
                      >
                        Send password reset link
                      </button>
                    )}

                    <Link className='back_to_sign_in' to={AppUrls.login}>
                      Back to sign in
                    </Link>
                  </form>
                </div>
              </div>
            </div>
            <div id='sign_up_as_individual-div'>
              <div className='container'>
                <div className='row justify-content-center align-items-center'>
                  <div className='justify-content-center'>
                    <h5 className='no_account'>Don't have an account?</h5>
                    <Link className='to_register_individual' to={AppUrls.register_individual}>
                      <button id='sign_up_as_individual-btn' className='btn btn-primary btn-block'>
                        <img src={Email} id='send_email-icon' alt='' />
                        <p>Sign up as individual</p>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id='forgot_password_bottom_content-div'>
              <div className='container forgot_password_bottom_card'>
                <div className='row justify-content-center align-items-center'>
                  <div className='justify-content-center'>
                    <p>Looking to accept donations?</p>
                    <Link className='to_register_organization' to={AppUrls.register_organization}>
                      <button id='register_organization-btn' className='btn btn-primary'>
                        Register your organization
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
