import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import BackButton from '../../../images/back-button.png';
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import MultiStepProgressBar from "../../reusableComponents/MultiStepProgressBar/MultiStepProgressBar";
import RegisterOrganizationStep1 from "./RegisterOrganizationStep1";
import RegisterOrganizationStep2 from "./RegisterOrganizationStep2";
import './registerOrganization.css';
import * as helpers from "../../../global/helpers";
import * as AppUrls from "../../../constants/AppUrls";

class RegisterOrganization extends Component {
    constructor(props) {
        super(props);
        // Set the intial current step
        this.state = {
            currentStep: 1,
            validated: false,
            redirect: null,
            message: "",
            profilePhoto: null,
            orgName: "",
            phoneNumber: "",
            countryCode: "",
            orgEmail: "",
            password: "",
            confirmPassword: "",
            selectedSupportingCauses: [],
            aboutOrg: "",
            selectedState: null,
            city: "",
            federalTaxId: "",
            uploadedFile: null,
            isFormDisabled: false,
            profilePhotoGUID: null,
            fileGUID: "",
            zipCode: "",
            streetAddress: null
        };

        // Bind new functions for next and back
        this._next = this._next.bind(this);
        this._back = this._back.bind(this);

        this.multiSelectRef = React.createRef();
        this.refStates = React.createRef();
        this.refCities = React.createRef();

        this.profilePhotoRef = React.createRef();
        this.fileRef = React.createRef();

    }


    // Test current step with ternary
    // _next and _back functions will be called on button click
    _next() {
        let currentStep = this.state.currentStep;
        /* if (currentStep === 1) {
            if (!this.validate()) {
                return
            }
        } */
        // If the current step is 1 or 2, then add one on "next" button click
        currentStep = currentStep >= 1 ? 2 : currentStep + 1;
        this.setState({
            currentStep: currentStep
        });
    }

    _back() {
        let currentStep = this.state.currentStep;
        // If the current step is 1 or 2, then subtract one on "back" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }

    //The "next" and "back" button functions
    get backButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "back" button
        if (currentStep !== 1) {
            return (
                <Button color="primary" className="org_back_btn" onClick={this._back}>
                    Back
                </Button>
            );
        }
        // ...else return nothing
        return null;
    }

    setRegisterOrgStep1Data = (data, phoneNumber, countryCode) => {
        console.log("setRegisterOrgStep1Data", data);
        this.setState({
            password: data.password,
            confirmPassword: data.confirmPassword,
            orgEmail: data.orgEmail,
            orgName: data.orgName,
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            profilePhoto: this.profilePhotoRef.current.getProfilePhoto()
        }, () => {
            this._next()
        })
    }

    setRegisterOrgStep2Data = (data) => {
        console.log("state: ", this.state)
        console.log("setRegisterOrgStep2Data", data);
        let supCauses = data.supportingCauses.map((item) => {
            return item.value
        })

        this.setState({
            federalTaxId: data.federalTaxId,
            city: data.city,
            zipCode: data.zipCode,
            streetAddress: data.streetAddress,
            aboutOrg: data.aboutOrg,
            selectedState: data.stateSelection.value,
            selectedSupportingCauses: supCauses,
            uploadedFile: this.fileRef.current.getFile()
        }, () => {
            this.getImageGUID()
        })
    }


    getImageGUID = () => {
        if (this.state.profilePhoto === null) {
            this.getFileGUID();
            return;
        }

        this.setState({ isFormDisabled: true })

        const formData = new FormData();
        formData.append(
            "file", this.state.profilePhoto
        );

        helpers.uploadMediaWithoudAuth(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    console.log("profilePhotoGUID: ", response.data.guid)
                    this.setState({ profilePhotoGUID: response.data.guid }, () => {
                        this.getFileGUID();
                    })
                } else {
                    console.log("error get guid: ", response.data)
                }
            })
            .catch((error) => {
                this.setState({ isFormDisabled: false })
            });
    }

    getFileGUID = () => {
        this.setState({ isFormDisabled: true })

        const formData = new FormData();
        formData.append(
            "file", this.state.uploadedFile
        );

        helpers.uploadMedia(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    console.log("fileGUID: ", response.data.guid)
                    this.setState({ fileGUID: response.data.guid }, () => {
                        this.register()
                    })
                } else {
                    console.log("error get guid: ", response.data)
                }
            })
            .catch((error) => {
                this.setState({ isFormDisabled: false })
            });
    }

    register = () => {
        let postdata = {
            profilePhoto: this.state.profilePhotoGUID,
            orgName: this.state.orgName,
            areaCode: this.state.countryCode,
            federalTaxId: this.state.federalTaxId,
            documentGuid: this.state.fileGUID,
            description: this.state.aboutOrg,
            supportingCauses: this.state.selectedSupportingCauses,
            phoneNumber: this.state.phoneNumber,
            city: this.state.city,
            zipCode: this.state.zipCode,
            streetAddress: this.state.streetAddress,
            stateAb: this.state.selectedState,
            email: this.state.orgEmail,
            password: this.state.password,
        }

        console.log("register called: ", postdata)

        helpers.registerOrg(postdata)
            .then((response) => {
                this.setState({ isFormDisabled: false })
                if (response.status === 201) {
                    console.log("register 201")
                    this.setState({ redirect: AppUrls.pending_registration })
                }

            })
            .catch((error) => {
                this.setState({ isFormDisabled: false })
                console.log("error", error);
            });

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <section id="register_organization-section">
                <div className="register_organization_section">
                    <div className="register_organization_inner">
                        <div className="register_organization_item">
                            <div id="register_organization_nav-div">
                                <Container>
                                    <Row className="justify-content-center align-items-center">
                                        <Row className="justify-content-center">
                                            <p id="contact_us-p">Contact Us</p>
                                            <Image src={Logo} className="mx-auto sadaqa_logo" />
                                        </Row>
                                    </Row>
                                    <Row className="back_path">
                                        <Link to="/Login">
                                            <Image src={BackButton} className="back_button" />
                                        </Link>
                                        <h5 className="register_organization_title">Register Organization</h5>
                                    </Row>

                                    <Col className="justify-content-center align-items-center">
                                        <div id="register_org_progress_bar" >
                                            <MultiStepProgressBar className="RSPBprogressBar" currentStep={this.state.currentStep} />
                                        </div>
                                        <RegisterOrganizationStep1
                                            currentStep={this.state.currentStep}
                                            updateState={this.updateState}
                                            _next={this._next}
                                            message={this.state.message}
                                            setRegisterOrgStep1Data={this.setRegisterOrgStep1Data}
                                            ref={this.profilePhotoRef}
                                        // orgName={this.state.orgName}
                                        // orgEmail={this.state.orgEmail}
                                        // password={this.state.password}
                                        // confirmPassword={this.state.confirmPassword}
                                        />
                                        <RegisterOrganizationStep2
                                            currentStep={this.state.currentStep}
                                            updateState={this.updateState}
                                            multiSelectRef={this.multiSelectRef}
                                            refStates={this.refStates}
                                            refCities={this.refCities}
                                            setRegisterOrgStep2Data={this.setRegisterOrgStep2Data}
                                            ref={this.fileRef}
                                            isFormDisabled={this.state.isFormDisabled}
                                        // aboutOrg={this.state.aboutOrg}
                                        // city={this.state.city}
                                        // federalTaxId={this.state.federalTaxId}
                                        />
                                    </Col>
                                    {this.backButton}
                                    {/* <Row id="stepper_buttons_step2_div">
                                        <Container className="buttons_container">
                                            
                                            {this.nextButton}
                                            {this.registerButton}
                                        </Container>
                                    </Row> */}
                                </Container>
                                <Row id="if_exists_step2-div" className="justify-content-center align-items-center">
                                    <p className="already_have_an_account">Already have an account?</p>
                                    <Link to="/Login">
                                        <Button color="primary" className="if_exist_login">
                                            Log in
                                        </Button>
                                    </Link>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default RegisterOrganization;
