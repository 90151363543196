import React from 'react';
import { Modal } from 'react-bootstrap';
import { ExclamationCircle } from 'react-bootstrap-icons';

export default function ImageConfirmModal({
  isOpen,
  setIsOpen,
  closeMainModal,
  caption,
  setTextarea,
  callback,
  disabled,
}) {
  return (
    <Modal show={isOpen} onHide={setIsOpen} centered style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0 mb-3 relative'>
        <Modal.Title>
          <span className='mx-4'>
            <ExclamationCircle style={{ color: '#FF7E7E' }} className='text-lg font-semi' />
          </span>
          <span className='tracking-normal text-normal text-md font-semi '>
            Keep the changes before leaving?
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-4 pr-3 d-flex flex-column align-items-end'>
          <button
            onClick={callback}
            className={`${
              disabled ? 'cursor-disabled' : ''
            } py-2 px-4 rounded text-white d-block mb-4 `}
            style={{ backgroundColor: '#67DB88', border: 'none', color: '#7CB1FF' }}
          >
            Keep Changes
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              closeMainModal(false);
              setTextarea(caption);
            }}
            className={`${disabled ? 'cursor-disabled' : ''} mb-4 mb-md-0 d-block`}
            style={{ backgroundColor: 'transparent', border: 'none', color: '#7CB1FF' }}
          >
            Leave without saving
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
