import React, { useEffect, useState } from 'react';
import './ContributorAccountItem.css';
import imgDefault from '../../../images/img-default.svg';
import { ReactComponent as Location } from '../../../images/Location.svg';
import { ReactComponent as Call } from '../../../images/Call.svg';
import { ReactComponent as Message } from '../../../images/Message.svg';
import { ReactComponent as Edit } from '../../../images/edit-blue.svg';
import PaymentMethodRadioBtn from '../../../components/reusableComponents/PaymentMethodRadioBtn/PaymentMethodRadioBtn';
import { useFetchPayments } from '../../../hooks/useFetchPayments';
import { BASE_URL } from '../../../constants/Constants';
import RemovePaymentMethod from './RemovePaymentMethod';
import { getProfile } from '../../../global/helpers';
import { Link } from 'react-router-dom';
import { edit_account } from '../../../constants/AppUrls';

const HomeProjectItem = (props) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [name, setName] = useState('');
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCityLocal] = useState("");
  const [state, setStateLocal] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    getProfileLocal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getProfileLocal() {
    getProfile().then((response) => {
      if (response !== undefined) {
        console.log(response)
        if (response.status === 200) {
          let profile = response.data;
          let profileImgLocal = imgDefault;
          console.log({ profile });
          if (
            profile.imageGuid !== undefined &&
            profile.imageGuid !== null &&
            profile.imageGuid !== '' &&
            profile.imageGuid !== 'guid'
          ) {
            let imgUrl = BASE_URL + '/media/' + profile.imageGuid + '/200'; //first field: GUID, second is: size
            profileImgLocal = imgUrl;
          }
          setProfileImg(profileImgLocal);
          setName(profile.firstName + ' ' + profile.lastName);

          let streetAddressLocal = "";
          let cityLocal = "";
          let stateLocal = "";
          let zipCodeLocal = "";

          if (profile.streetAddress !== null && profile.streetAddress !== "") {
            streetAddressLocal = profile.streetAddress;
          }

          if (profile.city !== null && profile.city !== "") {
            cityLocal = profile.city;
          }

          if (profile.state !== null && profile.state !== "") {
            stateLocal = profile.state.value;
          }
          if (profile.zipCode !== null && profile.zipCode !== "") {
            zipCodeLocal = profile.zipCode
          }

          setStreetAddress(streetAddressLocal);
          setCityLocal(cityLocal);
          setStateLocal(stateLocal);
          setZipCode(zipCodeLocal);

          setPhoneNumber(profile.fullPhoneNumber);
          setEmail(profile.email);
        }
      }
    });
  }

  let url = `${BASE_URL}/payments/cards`;
  const { data: paymentsData } = useFetchPayments(url);
  const [checkedId, setCheckedId] = useState('');

  const onRadioChange = (id) => {
    setCheckedId(id);
  };

  console.log('paymentsData: ', paymentsData);
  const [isRemove, setIsRemove] = useState(false);

  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
    setImgLoaded(true);
  };
  let displayImg = imgLoaded ? 'inherit' : 'none';

  return (
    <>
      <form className='account-info-container'>
        <div className='account-class d-flex'>
          <div id='contributor-picture'>
            {!imgLoaded ? (
              <div className='account-img' style={{ backgroundColor: 'white' }} />
            ) : null}
            <img
              style={{ display: displayImg }}
              className='account-img'
              src={profileImg}
              alt='Profile'
              onError={addDefaultSrc}
              onLoad={() => {
                setImgLoaded(true);
              }}
            />
          </div>
          <div className="w-100">
            <Link to={edit_account} className='org-edit-item' style={{ marginLeft: "auto" }}>
              <Edit id='edit-icon' />
              Edit
            </Link>

            <p className='contributor-Name'>{name}</p>
            <p className='contributor-Contact'>Contact</p>
            <p className='contributor-location-section mb-2 mt-2 d-flex'>
              <Location id="location-icon" />
              <div className="d-block">
                <span className='d-block'>{streetAddress}</span>
                <span>{city}, {state}, {zipCode}</span>
              </div>
            </p>
            <p className='contributor-phone-section mt-3'>
              <Call id='call-icon' />
              {phoneNumber}
            </p>
            <p className='contributor-email-section mt-3'>
              <Message id='message-icon' />
              {email}
            </p>
            <button className='contributor-account-payment-button mt-4'>
              + Add Payment Method
            </button>
            <div key='inline-radio' className='mb-3 contributor-account-payment-cards mt-3'>
              {paymentsData &&
                paymentsData.map((paymentMethod, index) => {
                  return (
                    <>
                      <div
                        className='mt-4'
                        key={'payment-method-' + index}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsRemove(true);
                        }}
                      >
                        <PaymentMethodRadioBtn
                          paymentMethod={paymentMethod}
                          checkedId={checkedId}
                          onRadioChange={onRadioChange}
                          setCheckedId={setCheckedId}
                          isCheckPayment={false}
                        />
                      </div>
                      <RemovePaymentMethod
                        isRemove={isRemove}
                        setIsRemove={setIsRemove}
                        paymentId={paymentMethod.id}
                        paymentMethod={paymentMethod}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default HomeProjectItem;
