import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./DonateDonationsHistoryTabs.css";
import DonateOrganizationsTab from './DonateOrganizationsTab';
import DonateProjectsTab from './DonateProjectsTab';

const DonateDonationsHistoryTabs = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => setTabIndex(tabIndex)}
            className="donate_donations_tabs"
        >
            <TabList>
                <Tab>Organizations</Tab>
                <Tab>Projects</Tab>
            </TabList>
            <TabPanel>
                <DonateOrganizationsTab />
            </TabPanel>
            <TabPanel>
                <DonateProjectsTab />
            </TabPanel>
        </Tabs>
    );
};

export default DonateDonationsHistoryTabs;