import React from 'react';
import { BASE_URL } from '../../../constants/Constants';
import { saveAs } from "file-saver";

export default function About({ orgDetails, userType }) {
  console.log({ orgDetails })

  let supportingCauses = orgDetails !== null && orgDetails.supportingCauses !== null ? orgDetails.supportingCauses : []
  let federalTaxID = orgDetails !== null && orgDetails.federalTaxID !== null ? orgDetails.federalTaxID : ""
  let description = orgDetails !== null && orgDetails.description !== null ? orgDetails.description : ""
  let documen501Local = "";
  if (orgDetails !== null && orgDetails.documentGuid !== undefined && orgDetails.documentGuid !== null && orgDetails.documentGuid !== "" && orgDetails.documentGuid !== "documentGuid") {
    let fileUrl = BASE_URL + "/media/" + orgDetails.documentGuid
    documen501Local = fileUrl;
  }

  let documentName = orgDetails !== null && orgDetails.name ? "501 (c) Document " + orgDetails.name + ".pdf" : "-";

  const saveFile = () => {
    saveAs(
      documen501Local,
      "501 (c) Document Organization Name.pdf"
    );
  };

  return (
    <div className='p-5 bg-white rounded shadow'>
      <h1 className='tracking-normal text-normal text-md mb-4'>Supporting Cause(s)</h1>
      <div className='grid-pill'>
        {
          supportingCauses.map((item, index) => {
            return (
              <p
                key={"supporting-cause-" + index}
                className='d-inline-block px-1 rounded text-white text-xxs mx-1 '
                style={{ backgroundColor: '#7CB1FF' }}
              >
                {item}
              </p>
            )
          })
        }
      </div>
      <div className='grid-about mt-5'>
        <h1 className=' text-sm tracking-normal text-normal'>Federal Tax ID</h1>
        {userType === 'admin' ? (
          <>
            <h1 className=' text-sm tracking-normal text-normal'>501 (c) Document</h1>
            <p className='text-sm tracking-normal text-normal'>{federalTaxID}</p>
            <p className='text-sm tracking-normal text-normal' style={{ color: "#3284FF", cursor: "pointer" }} onClick={saveFile}>
              {documentName}
            </p>
          </>
        ) : userType === 'contributor' ? (
          <>
            <br />
            <h1 className='text-sm tracking-normal text-normal font-weight-normal'>{federalTaxID}</h1>
          </>
        ) : ''}
      </div>
      <div className='mt-5'>
        <p className='text-sm tracking-normal text-normal'>
          {description}
        </p>
      </div>
    </div>
  );
}
