import React from 'react'
import './circularImage.css'
const CircularImage = (props) => {
    return (
        <div>
            <img className='image-container' src={props.image} width={props.size} height={props.size} alt=''/>

        </div>
    )
}

export default CircularImage