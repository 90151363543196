import React from 'react';
import RegistrationSuccess from '../components/RegistrationSuccess/RegistrationSuccess'
import SuccessImage from '../images/success-registration.svg';

const registrationSuccess = () => {
    return (
        <RegistrationSuccess
            title={'Success!'}
            image={SuccessImage}
            subtitle1={'You have successfully registered. To verify your account, check your email and confirm your registration.'}
            subtitle2={'Don’t forget to check your spam folder.'}
            showResendEmail={true}
        />
    )
}

export default registrationSuccess;