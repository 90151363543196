import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import UploadProfileImage from "../../reusableComponents/SingleImageUploadComponent/SingleImageUploadComponent";
//import PhoneInputForm from "../../reusableComponents/PhoneInputForm/PhoneInputForm";
// import PasswordToggle from "../../reusableComponents/PasswordToggle/PasswordToggle";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import './RegisterOrganizationStep1.css';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const RegisterOrganizationFirstStepSchema = yup.object().shape({
  orgName: yup.string().required("Organization name is required"),
  phoneNumber: yup.string().nullable()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  orgEmail: yup.string().email().required("Organization email is required"),
  password: yup
    .string()
    .trim('Password cannot include spaces')
    .strict(true)
    .required("Password is required")
    .min(8, 'Minimum of 8 characters are required')
    .max(80, 'Maximum of 80 characters are required'),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const RegisterOrganizationStep1 = React.forwardRef((props, ref) => {


  const { formState: { errors }, control, setValue, trigger, handleSubmit } = useForm({
    resolver: yupResolver(RegisterOrganizationFirstStepSchema)
  });

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [phoneNumberS, setPhoneNumberS] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === "input" ? "password" : "input")
  }

  const showHideConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordType(confirmPasswordType === "input" ? "password" : "input")
  }

  const onSubmit = (x) => {
    props.setRegisterOrgStep1Data(x, phoneNumberS, countryCode);
    /* props._next(); */
  };

  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <Container className="register_organization_step_one">
      <Row className="justify-content-center align-items-center mr-0 ml-0">
        <div className="justify-content-center">
          <Form id="register_organization-form" className="justify-content-center" onSubmit={handleSubmit(onSubmit)}>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Profile Picture</Form.Label>
                <UploadProfileImage ref={ref} />
              </Form.Group>
            </Col>
            <Col lg={12} className="mt-2">
              <Row>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Organization Name</Form.Label><span className="required_field"> *</span>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id="orgName"
                          className="input_field"
                          name="orgName"
                          style={{
                            borderColor: !errors.orgName || props.orgName ? '' : 'red'
                          }}
                        />
                      )}
                      name="orgName"
                      control={control}
                      defaultValue=""
                    />
                    {errors.orgName && <p className="org_signup_error_step_1">{errors.orgName.message}</p>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Your Phone Number</Form.Label><span className="required_field"> *</span>
                    {/* <Controller
                      render={({ field }) => (
                        <PhoneInputForm field={field} errors={errors} />
                      )}
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                    /> */}
                    <Controller
                      id="phoneNumber"
                      name="phoneNumber"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          onChange={(value, country) => {
                            console.log(value)
                            setValue("phoneNumber", value)
                            trigger("phoneNumber")
                            setCountryCode(country.dialCode)
                            setPhoneNumberS(value.slice(country.dialCode.length))
                          }}
                          inputStyle={{
                            borderColor: !errors.phoneNumber ? '' : 'red'
                          }}
                          buttonStyle={{
                            borderColor: !errors.phoneNumber ? '' : 'red'
                          }}
                          country={'us'}
                          placeholder='(702) 123-4567'
                        />
                      )}

                    />
                    {errors.phoneNumber && <p className="org_signup_error_step_1">{errors.phoneNumber.message}</p>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Organization Email</Form.Label><span className="required_field"> *</span>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id="orgEmail"
                          type="email"
                          className="input_field"
                          name="orgEmail"
                          style={{
                            borderColor: !errors.orgEmail || props.orgEmail ? '' : 'red'
                          }}
                        />
                      )}
                      name="orgEmail"
                      control={control}
                      defaultValue=""
                    />
                    {errors.orgEmail && <p className="org_signup_error_step_1">{errors.orgEmail.message}</p>}
                  </Form.Group>
                </Col>
                <Col lg={4} className="w-100"></Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="pass_lbl">
                      Password{" "}<span className="required_field">*</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type={passwordType}
                          id="password"
                          className="password_input"
                          style={{
                            borderColor: !errors.password || props.password ? '' : 'red'
                          }}
                        />
                      )}
                      name="password"
                      control={control}
                      defaultValue=""
                    />
                    <span className="password_show" onClick={showHidePassword}>
                      {passwordType === "input" ? (
                        <i id="eye-icon" className="fa fa-eye"></i>
                      ) : (
                        <i
                          id="eye-icon"
                          className="fa fa-eye-slash fa-flip-horizontal"
                        ></i>
                      )}
                    </span>
                    {errors.password && <p className="org_signup_error_step_1">{errors.password.message}</p>}
                  </Form.Group>
                  <Form.Group className="confirm_password_input_group">
                    <Form.Label className="pass_lbl">
                      Confirm Password{" "}<span className="required_field">*</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type={confirmPasswordType}
                          id="confirmPassword"
                          className="password_input"
                          style={{
                            borderColor: !errors.confirmPassword || props.confirmPassword ? '' : 'red'
                          }}
                        />
                      )}
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                    />
                    <span className="password_show" onClick={showHideConfirmPassword}>
                      {confirmPasswordType === "input" ? (
                        <i id="eye-icon" className="fa fa-eye"></i>
                      ) : (
                        <i
                          id="eye-icon"
                          className="fa fa-eye-slash fa-flip-horizontal"
                        ></i>
                      )}
                    </span>
                    {errors.confirmPassword && <p className="org_signup_error_step_1">{errors.confirmPassword.message}</p>}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Button type="submit" color="primary" className="reg_org_next_button">
              Next
            </Button>
          </Form>
        </div>
      </Row>
    </Container>
  );
});

export default RegisterOrganizationStep1;
