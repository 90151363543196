import React from "react";
import "./DonateDonationsHistory.css";
import DonateDonationsHistoryTabs from "./DonateDonationsHistoryTabs";

const DonateDonationsHistory = () => {
    return (
        <DonateDonationsHistoryTabs
            className="donate_donations_history_tabs"
        />
    );
};

export default DonateDonationsHistory;
