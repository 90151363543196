import ReactStars from 'react-rating-stars-component';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ic_top_bg from '../../../../images/ic_top_bg.svg';
import ic_close from '../../../../images/ic_close.svg';
import send from '../../../../images/Send.svg';
import './WriteAReview.css';

import { usePostReview } from '../../../../hooks/usePostReview';
import { useParams } from 'react-router';
import { BASE_URL } from '../../../../constants/Constants';

export default function WriteAReview({ isReview, setIsReview, getReviews }) {
  const [txtMsg, setTxtMsg] = useState();
  const [charCount, setCharCount] = useState(0);
  const [txtMsgRequired, setTxtMsgRequired] = useState('');
  const [txtMsgStyle, setTxtMsgStyle] = useState({});
  const [rating, setRating] = useState(0);
  const [errorRating, setErrorRating] = useState('');

  const { id } = useParams();
  let url = `${BASE_URL}/orgs/${id}/reviews`;
  const { mutateAsync } = usePostReview(url);

  const handleSubmitReview = async () => {
    if (rating === 0) {
      setErrorRating('Must give a rating for the review');
      return;
    }
    if (charCount !== 0) {
      setTxtMsgRequired('');
      setTxtMsg('');
      setCharCount(0);
      setTxtMsgStyle({
        border: '1px solid #C5E8CF',
      });

      setIsReview(false);
    } else if (charCount === 0) {
      setTxtMsgRequired('Review is required');
      setTxtMsgStyle({
        border: '1px solid red',
      });
    }
    await mutateAsync({ feedback: txtMsg, value: rating });
    // await getReviews();
    setIsReview(false);
  };

  const handleClose = () => {
    setTxtMsg('');
    setErrorRating('');
    setRating(0);
    setCharCount(0);
    setTxtMsgRequired('');
    setTxtMsgStyle({
      border: '1px solid #C5E8CF',
    });
    setIsReview(false);
  };

  return (
    <Modal show={isReview} onHide={setIsReview} id='review_modal'>
      <div style={{ display: 'flex' }}>
        <div className='review_title'>Write a review</div>
        <div style={{ marginLeft: 'auto', width: '66px', height: '68px' }}>
          <img src={ic_top_bg} alt='' />
          <img
            src={ic_close}
            alt=''
            className='confirmation-modal-close'
            onClick={(e) => {
              e.preventDefault();
              setIsReview(false);
              handleClose();
            }}
          />
        </div>
      </div>
      <div style={{ padding: '0rem 1rem' }}>
        <p className='rate_txt'>Rate this organizations</p>

        <ReactStars
          count={5}
          size={20}
          value={rating}
          onChange={(rate) => {
            setRating(rate);
            setErrorRating('');
          }}
          emptyIcon={<i className='far fa-star'></i>}
          filledIcon={<i className='fa fa-star'></i>}
          activeColor='#67DB88'
          color='#67DB88'
        />
        {errorRating && <p className='required_field'>{errorRating}</p>}
      </div>
      <Modal.Body>
        <div className='review_content'>
          <form>
            <div className='relative'>
              <textarea
                name='txtMessage'
                className='your_review_txt'
                value={txtMsg}
                onChange={(e) => {
                  setCharCount(e.target.value.length);
                  setTxtMsg(e.target.value);
                }}
                maxLength={280}
                style={txtMsgStyle}
                placeholder='Write a review'
              ></textarea>
              <span className='absolute text-success' style={{ bottom: '.5rem', right: '.5rem' }}>
                {charCount}/280
              </span>
            </div>
            <p className='required_field'>{txtMsgRequired}</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: 15,
              }}
            >
              <button
                type='button'
                className='btn btn-primary mr-2'
                id='cancel_review-btn'
                onClick={() => handleClose()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary'
                id='post-btn'
                onClick={() => handleSubmitReview()}
              >
                <img src={send} id='post-icon' alt='' />
                <p>Post</p>
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
