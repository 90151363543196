import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DonationCard from './DonationCard';
import { Link } from 'react-router-dom';
//import { DonationSummaryAdmin } from '../../../constants/AppUrls.js';
import {
  home_or_org_or_project_donation_summary,
  org_or_project_recurring_donations,
} from '../../../constants/AppUrls.js';
import './Donations.css';
import * as Constants from '../../../constants/Constants';
import * as helpers from '../../../global/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';

const donationsType = [
  { value: 'all', label: 'All' },
  { value: 'once', label: 'One Time' },
  { value: 'recurring', label: 'Recurring' },
];

export default function Donations({ userType, isProjectsDonations = false, id, name = '' }) {
  const [donations, setDonations] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  const handleSelectedType = (e) => {
    setSelectedType(e.value);
    // console.log(`Option selected:`, e.value);
  };

  useEffect(() => {
    getDonations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDonations = () => {
    let donationsFor = !isProjectsDonations ? 'orgs' : 'projects';
    let pageSize = 10;
    let url =
      Constants.BASE_URL +
      '/' +
      donationsFor +
      '/' +
      id +
      '/donations?limit=10&offset=' +
      page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal);
    helpers
      .getData(url)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let updatesLocal = donations.concat(response.data.data);
            setDonations(updatesLocal);

            if (response.data.data.length === 0 || response.data.data.length < pageSize)
              setHasMore(false);
          }
        }
        if (!isFinished) setIsFinished(true);
      })
      .catch((error) => {
        if (!isFinished) setIsFinished(true);
      });
  };

  const selectedTypeStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF',
      borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      borderColor: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? '#FFFFFF' : '#FFFFFF',
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };

  return (
    <div>
      {userType === 'admin' ? null : userType === 'contributor' ? (
        <Select
          value={donationsType.filter(function (option) {
            return option.value === selectedType;
          })}
          onChange={handleSelectedType}
          options={donationsType}
          isSearchable={false}
          styles={selectedTypeStyles}
          id='select_donations_type'
        />
      ) : (
        ''
      )}

      {!isFinished ? (
        <h4 className='loading-label'>Loading...</h4>
      ) : donations !== undefined && donations !== null && donations.length > 0 ? (
        <InfiniteScroll
          className='px-4 pb-4'
          dataLength={donations.length}
          next={getDonations}
          hasMore={hasMore}
          loader={<h4 className='loading-label'>Loading...</h4>}
          scrollableTarget='scrollableDiv'
        >
          {donations.map((item, index) => {
            let donationLink = '';
            let organizationsOrProjects = isProjectsDonations ? 'projects' : 'organizations';
            console.log({ item });
            if (
              helpers.getUserType() === Constants.USER_TYPE_CONTRIB &&
              item.recurringType !== null
            ) {
              donationLink = org_or_project_recurring_donations
                .replace(':organizationsOrProjects', organizationsOrProjects)
                .replace(':orgName', name)
                .replace(':id', id)
                .replace(':donationId', item.id);
            } else {
              donationLink = home_or_org_or_project_donation_summary
                .replace(':homeOrOrganizationsOrProjects', organizationsOrProjects)
                .replace(':orgName', name)
                .replace(':id', id)
                .replace(':donationId', item.id);
            }

            return (
              <Link className='link-reset' to={donationLink}>
                <DonationCard key={'updates-' + index} donationDetails={item} />
              </Link>
            );
          })}
        </InfiniteScroll>
      ) : (
        <>
          {isProjectsDonations ? (
            <h4 className='center mt-4'>No donations made to this projects</h4>
          ) : (
            <h4 className='center mt-4'>No donations made to this organization</h4>
          )}
        </>
      )}
    </div>
  );
}
