import React from "react"
import RecurringDonations from "./RecurringDonations";

function RecurringDonationsWrapper(props) {
    return (
        <RecurringDonations isRecurringDonations={props.isRecurring}/>
    )
}

export default RecurringDonationsWrapper;
