
import React from 'react';
import RegisterIndividual from '../components/register/registerIndividual/RegisterIndividual'

const register = () => {
    return (
        <div>
            <RegisterIndividual />
        </div>
    )
}

export default register