import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ic_visa from '../../../images/ic_visa.png';
import './PaymentMethodRadioBtn.css';

const PaymentMethodRadioBtn = (props) => {
  const { paymentMethod, checkedId, setCheckedId, isCheckPayment } = props;

  useEffect(() => {
    if (paymentMethod.isSelected && !checkedId) setCheckedId(paymentMethod.id);
  }, [setCheckedId, paymentMethod, checkedId]);

  return (
    <Form.Check
      id={'id-radio-' + props.paymentMethod.id}
      key={'key-payment-method-radio-' + props.paymentMethod.id}
      type='radio'
      className='payment-method-container'
    >
      {isCheckPayment === true ? 
        <Form.Check.Input
        id={paymentMethod.id}
        className='radio-input'
        type='radio'
        value={paymentMethod.id}
        name='change_payment'
        checked={paymentMethod.id === checkedId}
        onChange={(e) => {
          props.onRadioChange(e.target.id);
        }}
      />
      : ''}
      <Form.Check.Label className='radio-label'>
        <div className='radio-label-img-container'>
          <img className='radio-label-img' src={ic_visa} alt={props.paymentMethod.last4} />
        </div>
        <div className='radio-label-text'>**** **** **** {props.paymentMethod.last4}</div>
        <div className='radio-label-text-validity'>
          {props.paymentMethod.expMonth} {props.paymentMethod.expYear}
        </div>
      </Form.Check.Label>
    </Form.Check>
  );
};

export default PaymentMethodRadioBtn;
