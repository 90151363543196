import React from 'react';
import Login from '../components/login/login'

function LoginPage  () {
    return (
        <Login />
    )
}

export default LoginPage;
