import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Row, Container, Button } from "react-bootstrap";
// import PasswordToggle from "../../reusableComponents/PasswordToggle/PasswordToggle";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import './RegisterIndividualStep2.css';

const RegisterIndividualSecondStepSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .trim('Password cannot include spaces')
    .strict(true)
    .required("Password is required")
    .min(8, 'Minimum of 8 characters are required')
    .max(80, 'Maximum of 80 characters are required'),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const RegisterIndividualStep2 = props => {
  const { formState: { errors }, control, handleSubmit } = useForm({
    resolver: yupResolver(RegisterIndividualSecondStepSchema)
  });

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === "input" ? "password" : "input")
  }

  const showHideConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordType(confirmPasswordType === "input" ? "password" : "input")
  }

  const onSubmit = (data) => {
    props.setRegisterIndividualStep2Data(data)
  };

  if (props.currentStep !== 2) {
    return null;
  }

  return (
    <Container className="register_individual_step_two">
      <Row className="justify-content-center align-items-center mr-0 ml-0">
        <Form className="justify-content-center" onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={props.isFormDisabled} >
            <Form.Group>
              <Form.Label className="individual_signup_label">Email</Form.Label>
              {/* <Form.Control id="email" className="input_field" onChange={(e) => { props.updateState(e) }} /> */}
              <Controller
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    id="email"
                    type="email"
                    className="input_field"
                    name="email"
                    style={{
                      borderColor: !errors.email || props.email ? '' : 'red'
                    }}
                  />
                )}
                name="email"
                control={control}
                defaultValue=""
              />
              {errors.email && <p className="individual_signup_error_step_2 mt-4">{errors.email.message}</p>}
            </Form.Group>
            {/* <PasswordToggle updateState={props.updateState} /> */}
            <Form.Group>
              <Form.Label className="pass_lbl">
                Password{" "}<span className="required_field">*</span>
              </Form.Label>
              <Controller
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type={passwordType}
                    id="password"
                    className="password_input"
                    style={{
                      borderColor: !errors.password ? '' : 'red'
                    }}
                  />
                )}
                name="password"
                control={control}
                defaultValue=""
              />
              <span className="password_show" onClick={showHidePassword}>
                {passwordType === "input" ? (
                  <i id="eye-icon" className="fa fa-eye"></i>
                ) : (
                  <i
                    id="eye-icon"
                    className="fa fa-eye-slash fa-flip-horizontal"
                  ></i>
                )}
              </span>
              {errors.password && <p className="individual_signup_error_step_2">{errors.password.message}</p>}
            </Form.Group>
            <Form.Group className="confirm_password_input_group">
              <Form.Label className="pass_lbl">
                Confirm Password{" "}<span className="required_field">*</span>
              </Form.Label>
              <Controller
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type={confirmPasswordType}
                    id="confirmPassword"
                    className="password_input"
                    style={{
                      borderColor: !errors.password ? '' : 'red'
                    }}
                  />
                )}
                name="confirmPassword"
                control={control}
                defaultValue=""
              />
              <span className="password_show" onClick={showHideConfirmPassword}>
                {confirmPasswordType === "input" ? (
                  <i id="eye-icon" className="fa fa-eye"></i>
                ) : (
                  <i
                    id="eye-icon"
                    className="fa fa-eye-slash fa-flip-horizontal"
                  ></i>
                )}
              </span>
              {errors.confirmPassword && <p className="individual_signup_error_step_2">{errors.confirmPassword.message}</p>}
            </Form.Group>
            <Button type="submit" color="primary" className="register_individual_button">
              Register
            </Button>
          </fieldset>
        </Form>
      </Row>
    </Container>
  );
};

export default RegisterIndividualStep2;
