import React from "react";
import MyProjects from "../../pages/Organization/Projects/MyProjects/MyProjects";
//import ProjectsTab from "../reusableComponents/ProjectsTab/ProjectsTab"
import "./TrendingProjectsTab.css";

const TrendingProjectsTab = () => {
    return (
        <MyProjects isTrending={true} />
    );
};

export default TrendingProjectsTab;