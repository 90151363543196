import React from 'react'
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import dp from '../../../images/img-default.svg'
import coin from '../../../images/projectCoinIcon.svg'
import info from '../../../images/blueInformationIcon.svg'
import * as Constants from "../../../constants/Constants";
import './ProjectItem.css'

const ProjectItem = ({
    title,
    author,
    amount,
    totalamount,
    endDate,
    overview,
    isActive,
    hasEnded,
    isOpen,
    img
}) => {
    let projectImg = dp
    if (img !== undefined && img !== null && img !== "" && img !== "guid") {
        let imgUrl = Constants.BASE_URL + "/media/" + img + "/200" //first field: GUID, second is: size
        projectImg = imgUrl;
    }
    const addDefaultSrc = (ev) => {
        ev.target.src = dp;
    }

    const projectInfoTooltip = (props) => (
        <Tooltip className="pjt_end_date-tooltip" {...props}>
          Ends at 12 AM GMT (midnight)
        </Tooltip>
    );

    return (
        <div className='project-item'>
            <div className='d-flex'>
                <div className='p_item_first_section'>
                    <div className="project-info-container">
                        <div className='project-title-section'>{title}</div>
                        <div className="project-author-container">
                            <span className='project-author-section'>by  </span>
                            <span className='project-author-item'>{author}</span>
                        </div>
                        <div className='project-overview'>
                            {overview}
                        </div>
                    </div>
                </div>
                <div className="project-image-container">
                    <img
                        src={projectImg}
                        alt={title}
                        className='relative project-image-size img-object-fit-cover'
                        style={{ zIndex: '1', borderRadius: "4px" }}
                        onError={addDefaultSrc} />
                    {
                        hasEnded === true ?
                            <div className="project-has-ended-overlay text-align-center project-image-size project-overlay">
                                <div className="project-has-ended-overlay-text text-align-center mx-auto">
                                    Ended
                                </div>
                            </div>
                        : isActive === false ? 
                            <div className="project-disable-overlay text-align-center project-image-size project-overlay">
                                <div className="project-disable-overlay-text text-align-center mx-auto">
                                    Disabled
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
            <div>
                <span className='project_item_bottom'>
                    <div className='amount-rasied-section'>
                        <div className='raised-amount'>
                            <img src={coin} alt='' className='pitem-coin-icon' />
                            ${amount} raised <span className='of-amount'> of ${totalamount}</span>
                        </div>
                    </div>
                    <div className='enddate-section'>
                        <div className='end-on-h'>
                            <span className='end-date-on-h_span'>End Date</span>
                            {
                                endDate !== null && endDate !== "" ?
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={projectInfoTooltip}
                                    >
                                        <img
                                            className="ml-1"
                                            width={15} 
                                            height={15}
                                            src={info}
                                            alt=""
                                        />
                                    </OverlayTrigger>
                                :
                                    ''
                            }
                        </div>
                        {
                            endDate !== null && endDate !== "" ?  
                                <div 
                                    className='end_date_txt'
                                    style={{
                                        color: hasEnded === true ? '#ff7e7e' : ''
                                    }}
                                >
                                    {endDate}
                                </div>
                            : isOpen ?
                                <div className='end_date_txt'>
                                    Open
                                </div>
                            :
                                null
                        }
                    </div>
                </span>
            </div>
        </div>
    )
}

export default ProjectItem;
