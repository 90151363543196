import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as AppUrls from '../../../constants/AppUrls';

export const AdminSidebarData = [
  {
    title: 'Stats',
    path: AppUrls.home,
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Updates',
    path: AppUrls.updates,
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: 'Contributors',
    path: AppUrls.contributors,
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: 'Organizations',
    path: AppUrls.organizations,
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Projects',
    path: AppUrls.projects,
    icon: <FaIcons.FaCartPlus />,
  },
  {
    title: 'Account',
    path: AppUrls.account,
    icon: <FaIcons.FaCartPlus />,
  },
];

export const ContribSidebarData = [
  {
    title: 'Home',
    path: AppUrls.home,
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Organizations',
    path: AppUrls.organizations,
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: 'Projects',
    path: AppUrls.projects,
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: 'Donate',
    path: AppUrls.donate_recurring_donations,
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Recurring Donations',
        path: AppUrls.donate_recurring_donations,
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
      },
      {
        title: 'One Time Donations',
        path: AppUrls.donate_one_time_donations,
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
      },
      {
        title: 'Donations History',
        path: AppUrls.donate_donations_history,
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
      },
    ],
  },
  {
    title: 'Account',
    path: AppUrls.account,
    icon: <FaIcons.FaCartPlus />,
  },
];

export const OrgSidebarData = [
  {
    title: 'Donations',
    subNavTitle: 'All donations history',
    path: AppUrls.home,
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Projects',
    path: AppUrls.projects,
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'My Projects',
        path: AppUrls.projects,
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
      },
      {
        title: 'Create New Project',
        path: AppUrls.org_create_new_project,
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
      },
    ],
  },
  {
    title: 'Reviews',
    path: AppUrls.org_reviews,
    icon: <IoIcons.IoIosPaper />,
  },
  {
    title: 'Account',
    path: AppUrls.account,
    icon: <FaIcons.FaCartPlus />,
  },
];
