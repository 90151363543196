import React, { useEffect, useState } from 'react';

import postlogo from '../../../Icons/post-logo.svg';

import PostModal from '../../PostModal';
import NoUpdates from '../../NoUpdates';
import * as Constants from '../../../constants/Constants';
import * as helpers from '../../../global/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProjectUpdate from '../../SuperAdminComponents/ProjectUpdate';

export default function Updates({ id }) {
  const [showPost, setShowPost] = useState(false);

  const [updates, setUpdates] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page === 0 && updates.length === 0 && hasMore && !isFinished) getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updates, hasMore, isFinished]);

  const getUpdates = () => {
    let pageSize = 10;
    let url = Constants.PROJECTS + '/' + id + '/updates?limit=10&offset=' + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal);
    helpers.getData(url).then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          let updatesLocal = updates.concat(response.data.data);
          setUpdates(updatesLocal);
          if (!isFinished) setIsFinished(true);

          if (response.data.data.length === 0 || response.data.data.length < pageSize)
            setHasMore(false);
        }
      }
    });
  };

  const removeUpdate = (id, setShowRemove) => {
    helpers.deleteUpdate(id).then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          helpers.successNotify('Update Removed');
          setShowRemove(false);
          setUpdates(updates.filter((item) => item.id !== id));
        }
      }
    });
  };

  console.log('updates', updates);

  const createUpdate = (value) => {
    let postdata = {
      value,
    };
    console.log('postData', postdata);
    helpers.createUpdate(id, postdata).then((response) => {
      if (response !== undefined) {
        if (response.status === 201) {
          helpers.successNotifyWithTitle('Saved', 'Your changes have been saved.');
          setShowPost(false);
          setPage(0);
          setHasMore(true);
          setUpdates([]);
          setIsFinished(false);
        }
      }
    });
  };
  const user = localStorage.getItem('KEY_USER_TYPE');
  return (
    <div>
      {isFinished && id !== '' ? (
        updates.length > 0 ? (
          <div>
            <div className='text-right'>
              {user === 'admin' || user === 'contrib' ? (
                ''
              ) : (
                <button
                  onClick={() => setShowPost(true)}
                  className='py-1 px-3 border-0 text-light rounded '
                  style={{ backgroundColor: '#7CB1FF' }}
                >
                  <img src={postlogo} alt='logo' className='mr-2 ' />
                  Post an update
                </button>
              )}
            </div>
            <InfiniteScroll
              className='px-4'
              dataLength={updates.length}
              next={getUpdates}
              hasMore={hasMore}
              loader={<h4 className='loading-label'>Loading...</h4>}
              scrollableTarget='scrollableDiv'
            >
              {updates.map((item, index) => {
                return (
                  <ProjectUpdate
                    key={'updates-' + index}
                    update={item}
                    isEditEnabled={false}
                    removeUpdate={removeUpdate}
                    isProjectOrgUpdate={true}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <NoUpdates postUpdate={createUpdate} />
        )
      ) : null}

      <PostModal
        isOpen={showPost}
        setIsOpen={setShowPost}
        postUpdate={createUpdate}
        title={'Update'}
      />
    </div>
  );
}
