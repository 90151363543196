import React, { useEffect } from "react";
import "./EditAccountWrapper.css";
import * as helpers from "../global/helpers";
import * as Constants from "../constants/Constants";
import * as actions from "../store/actionTypes";
import { connect } from "react-redux";
import OrgEditAccount from "../components/OrganizationComponents/OrgAccount/OrgEditAccount";
import { Col, Row } from "react-bootstrap";
import { ContribAccountEdit } from "../components/ContribAccountEdit";
function EditAccountWrapper(props) {

    useEffect(() => {
        props.setIsBreadcrumbShown(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container-fluid edit-account-wrapper">
            <Row style={{ height: "100%" }}>
                <Col lg={12}>
                    {
                        helpers.getUserType() === Constants.USER_TYPE_ORG
                            ? <OrgEditAccount />
                            : helpers.getUserType() === Constants.USER_TYPE_CONTRIB
                                ? <ContribAccountEdit />
                                : helpers.getUserType() === Constants.USER_TYPE_ADMIN
                                    ? <div>edit account admin</div>
                                    : <div>Invalid User</div>
                    }
                </Col>

            </Row>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIsBreadcrumbShown: (isBreadcrumbShown) => {
            dispatch({
                type: actions.SET_IS_BREADCRUMB_SHOWN,
                payload: {
                    isBreadcrumbShown: isBreadcrumbShown
                }
            })
        },
    }
}

export default connect(null, mapDispatchToProps)(EditAccountWrapper)