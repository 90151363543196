import React, { useEffect, useState } from 'react';
import imgDefault from "../../../images/img-default.svg"
import next from "../../../Icons/next.svg"
import nextDisabled from "../../../Icons/next-disabled.svg"
import prev from "../../../Icons/prev.svg"
import prevDisabled from "../../../Icons/prev-disabled.svg"

import { Search } from 'react-bootstrap-icons';
import { BASE_URL } from '../../../constants/Constants';
import DonationItem from './DonationItem';
import { paginate } from '../../../global/helpers';

export default function Donations({ title, isDonationPerOrg, data = [], isFinished, donationsPerProjects = [] }) {
  const [isPopulated, setIsPopulated] = useState(false)
  const [pages, setPages] = useState([])
  const [filteredArray, setFilteredArray] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  useEffect(() => {
    if (isFinished && filteredArray.length === 0 && data.length > 0 && !isPopulated) {
      let pages = paginate([...data], 4);
      setPages(pages)
      setIsPopulated(true)
    }
  }, [isFinished, data, filteredArray, isPopulated, pageNumber])


  useEffect(() => {
    let maxNumber = pages.length - 1
    if (pageNumber === 0) {
      if (!isPrevDisabled) {
        setIsPrevDisabled(true)
      }
      if (pages.length > 1) {
        if (isNextDisabled) {
          setIsNextDisabled(false)
        }
      } else {
        if (!isNextDisabled) {
          setIsNextDisabled(true)
        }
      }
      return
    } else if (pageNumber > 0 && pageNumber < maxNumber) {
      if (isPrevDisabled) {
        setIsPrevDisabled(false)
      }
      if (isNextDisabled) {
        setIsNextDisabled(false)
      }
    } else if (pageNumber > 0 && pageNumber === maxNumber) {
      if (!isNextDisabled) {
        setIsNextDisabled(true)
      }
      if (pages.length > 1) {
        if (isPrevDisabled) {
          setIsPrevDisabled(false)
        }
      }
    }
  }, [isNextDisabled, isPrevDisabled, pageNumber, pages])

  useEffect(() => {
    if (pages.length > 0)
      setFilteredArray(pages[pageNumber])
  }, [pageNumber, pages]);

  let nextIcon = isNextDisabled ? nextDisabled : next;
  let prevIcon = isPrevDisabled ? prevDisabled : prev;

  return (
    <div className='bg-white rounded p-4 shadow my-4 flex-grow-1'>
      <h1 className='text-sm text-capitalize tracking-normal	'>{title}</h1>
      <div className='container mt-4 px-0 '>
        <div className='form-row '>
          <div className=' col-lg-8 px-0 mr-md-3 relative  col-sm-12'>
            <Search className='absolute text-success' style={{ top: '15px', left: '20px' }} />
            <input
              placeholder='Search'
              className='form-control pl-5 bg-white border border-success rounded'
              onChange={(e) => {
                let searchText = e.target.value.toLowerCase();
                let filteredArrayLocal = [];

                filteredArrayLocal = data.filter(el => {
                  return el.title.toLowerCase().includes(searchText)
                })
                setPageNumber(0)
                let pages = paginate([...filteredArrayLocal], 4);
                setPages(pages)
              }}
            />
          </div>

          {/* <div className='col-lg-3 col-sm-12 mt-sm-4 mt-md-4 mt-lg-0 px-0'>
            <select
              className=' ml-md-auto  form-control bg-white rounded'
              style={{ border: '1px solid #ced4da' }}
            >
              <option>Sort By</option>
            </select>
          </div> */}
        </div>
      </div>
      {
        filteredArray.map((item, index) => {
          let number = index + 1 + (4 * pageNumber)
          let itemImage = imgDefault;
          if (item.imageGuid !== undefined && item.imageGuid !== null && item.imageGuid !== "" && item.imageGuid !== "guid") {
            let imgUrl = BASE_URL + "/media/" + item.imageGuid + "/200" //first field: GUID, second is: size
            itemImage = imgUrl;
          }

          let donationsPerProjectsLocal = [];
          if (donationsPerProjects !== undefined && isDonationPerOrg) {
            donationsPerProjectsLocal = donationsPerProjects.filter(donationProjectItem => {
              if (donationProjectItem.orgId === item.id)
                return donationProjectItem
              else return null
            })
          }

          return (
            <DonationItem
              key={"donation-" + Math.random()}
              item={item}
              number={number}
              itemImage={itemImage}
              isDonationPerOrg={isDonationPerOrg}
              donationsPerProjectsLocal={donationsPerProjectsLocal} />
          )
        })
      }

      <div className="mt-5 d-flex mx-auto justify-content-center">
        <button
          className="mr-5"
          style={{ background: "transparent", border: "unset" }}
          disabled={isPrevDisabled}
          onClick={(e) => {
            e.preventDefault();
            let prevPage = pageNumber - 1;
            setPageNumber(prevPage)
          }}>
          <img src={prevIcon} alt="prev" style={{ width: "11.73px", height: "15px" }} />
        </button>
        <button
          style={{ background: "transparent", border: "unset" }}
          disabled={isNextDisabled}
          onClick={(e) => {
            e.preventDefault();
            let prevPage = pageNumber + 1;
            setPageNumber(prevPage)
          }}
        >
          <img src={nextIcon} alt="next" style={{ width: "11.73px", height: "15px" }} />
        </button>
      </div>
    </div>
  );
}
