import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import BackButton from '../../../images/back-button.png';
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import MultiStepProgressBar from "../../reusableComponents/MultiStepProgressBar/MultiStepProgressBar";
import RegisterIndividualStep1 from "./RegisterIndividualStep1";
import RegisterIndividualStep2 from "./RegisterIndividualStep2";
import './RegisterIndividual.css';
import * as helpers from "../../../global/helpers";
import * as AppUrls from "../../../constants/AppUrls";

class RegisterIndividual extends Component {
    constructor(props) {
        super(props);
        // Set the intial current step
        this.state = {
            currentStep: 1,
            states: [],
            redirect: null,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            streetAddress: null,
            selectedState: null,
            city: "",
            email: "",
            password: "",
            confirmPassword: "",
            profilePhotoGUID: null,
            countryCode: "",
            zipCode: "",
        };
        // Bind new functions for next and back
        this._next = this._next.bind(this);
        this._back = this._back.bind(this);
        this.refStates = React.createRef();
        this.refCities = React.createRef();
        this.profilePhotoRef = React.createRef();

    }

    // Test current step with ternary
    // _next and _back functions will be called on button click
    _next() {
        let currentStep = this.state.currentStep;

        // If the current step is 1 or 2, then add one on "next" button click
        currentStep = currentStep >= 1 ? 2 : currentStep + 1;
        // this.setState({
        //     selectedState: this.refStates.current.getSelected()
        // }, () => {
            this.setState({
                currentStep: currentStep
            });
        // });
    }

    _back() {
        let currentStep = this.state.currentStep;
        // If the current step is 1 or 2, then subtract one on "back" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }

    // The "next" and "back" button functions
    get backButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "back" button
        if (currentStep !== 1) {
            return (
                <Button color="primary" className="individual_back_btn" onClick={this._back}>
                    Back
                </Button>
            );
        }
        // ...else return nothing
        return null;
    }

    updateState = (event, isPhone = false) => {
        if (!isPhone)
            this.setState({ [event.target.id]: event.target.value })
        else
            this.setState({ phoneNumber: event })
    }

    setRegisterIndividualStep1Data = (data, phoneNumber, countryCode) => {
        console.log("setRegisterIndividualStep1Data", data, " ", phoneNumber, " ", countryCode);
        this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            profilePhoto: this.profilePhotoRef.current.getProfilePhoto(),
            selectedState: data.stateSelection.value,
            city: data.city,
            zipCode: data.zipCode,
            streetAddress: data.streetAddress,
        }, () => {
            this._next()
        })
    }

    setRegisterIndividualStep2Data = (data) => {
        console.log("setRegisterIndividualStep2Data", data);
        this.setState({
            email: data.email,
            password: data.password,
        }, () => {
            this.getImageGUID()
        })
    }

    getImageGUID = () => {
        if (this.state.profilePhoto === null) {
            this.registerUser()
            return;
        }

        this.setState({ isFormDisabled: true })

        const formData = new FormData();
        formData.append(
            "file", this.state.profilePhoto
        );

        helpers.uploadMediaWithoudAuth(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    console.log("profilePhotoGUID: ", response.data.guid)
                    this.setState({ profilePhotoGUID: response.data.guid }, () => {
                        this.registerUser()
                    })
                } else {
                    console.log("error get guid: ", response.data)
                }
            })
            .catch((error) => {
                this.setState({ isFormDisabled: false })
            });
    }


    registerUser = () => {
        let postdata = {
            email: this.state.email,
            password: this.state.password,
            profilePhoto: this.state.profilePhotoGUID,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            areaCode: this.state.countryCode,
            phoneNumber: this.state.phoneNumber,
            streetAddress: this.state.streetAddress,
            city: this.state.city,
            zipCode: this.state.zipCode,
            stateAb: this.state.selectedState
        }

        console.log("register called: ", postdata)

        helpers.registerUser(postdata)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 201) {
                        this.setState({ redirect: AppUrls.registration_success })
                    }
                }
            })
            .catch((error) => {
                this.setState({ isFormDisabled: false })
            });;
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <section id="register_individual-section">
                <div className="register_individual_section">
                    <div className="register_individual_inner">
                        <div className="register_individual_item">
                            <div id="register_individual_nav-div">
                                <Container>
                                    <Row className="justify-content-center align-items-center">
                                        <Row className="justify-content-center">
                                            <p id="contact_us-p">Contact Us</p>
                                            <Image src={Logo} className="mx-auto sadaqa_logo" />
                                        </Row>
                                    </Row>
                                    <Row className="back_path">
                                        <Link to="/Login">
                                            <Image src={BackButton} className="back_button" />
                                        </Link>
                                        <h5 className="signup_title">Sign up</h5>
                                    </Row>
                                    <div id="register_individual-form">
                                        <Col className="justify-content-center align-items-center">
                                            <div id="register_individual_progress_bar">
                                                <MultiStepProgressBar className="RSPBprogressBar" currentStep={this.state.currentStep} />
                                            </div>
                                            <RegisterIndividualStep1
                                                currentStep={this.state.currentStep}
                                                refStates={this.refStates}
                                                refCities={this.refCities}
                                                updateState={this.updateState}
                                                states={this.state.states}
                                                _next={this._next}
                                                firstName={this.state.firstName}
                                                setRegisterIndividualStep1Data={this.setRegisterIndividualStep1Data}
                                                ref={this.profilePhotoRef}
                                            />
                                            <RegisterIndividualStep2
                                                currentStep={this.state.currentStep}
                                                updateState={this.updateState}
                                                isFormDisabled={this.state.isFormDisabled}
                                                setRegisterIndividualStep2Data={this.setRegisterIndividualStep2Data}
                                            />
                                        </Col>
                                    </div>
                                    {this.backButton}
                                    {/* <Row id="stepper_buttons_step1_div">
                                        {this.nextButton}
                                        {this.registerButton}
                                    </Row> */}
                                </Container>
                                <Row id="if_exists_step1-div" className="justify-content-center align-items-center">
                                    <p className="already_have_an_account">Already have an account?</p>
                                    <Link to={AppUrls.login}>
                                        <Button color="primary" className="if_exist_login">
                                            Log in
                                        </Button>
                                    </Link>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RegisterIndividual;
