import React from 'react';
import './SectionTitle.css';

const SectionTitle = (props) => {

    return (
        <h3 className="title-of-section">
            {props.title}
        </h3>

    )

}

export default SectionTitle;