import React, { useState, useEffect } from "react";
import * as helpers from "../global/helpers";
import ProjectDetails from './SuperAdmin/ProjectDetails';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

function ProjectDetailsWrapper() {
    const [projectDetails, setProjectDetails] = useState(null);

    const dispatch = useDispatch();
    const location = useLocation();

    let projectId = location.pathname.split("/").pop();

    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
        getProjectDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getProjectDetails = () => {
        helpers.getProjectById(projectId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        console.log(response.data);
                        setProjectDetails(response.data);
                    }
                }
            })
    }

    return (
        <ProjectDetails projectDetails={projectDetails} />
    )
}

export default ProjectDetailsWrapper;