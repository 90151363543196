import React from 'react';

export default function Card({ title, icon, total }) {
  return (
    <div className='bg-white rounded p-3 shadow mt-4 d-flex justify-content-between align-items-center'>
      <div>
        <h1 className='text-success text-sm tracking-normal'>{total}</h1>
        <h1 className='text-sm mt-4 text-capitalize	tracking-normal'>{title}</h1>
      </div>
      <div className='ml-5'>
        <img src={icon} alt='logo' className='w-full h-auto' />
      </div>
    </div>
  );
}
