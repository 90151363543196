import './helpers.css';
import * as Constants from '../constants/Constants';
import * as AppUrls from '../constants/AppUrls';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ic_notify_success from '../images/ic_notify_success.png';
import ic_notify_failed from '../images/ic_notify_failed.png';
import moment from 'moment';
import { fetchData, fetchDataV2 } from '../axios';

toast.configure();

function getHeaders() {
  let options = {
    headers: {
      Authorization: getAccessToken(),
    },
  };
  return options;
}

/* Section: LocalStorage Getters/Setters */
export function setUserType(user_type) {
  localStorage.setItem(Constants.KEY_USER_TYPE, user_type);
}

export function getUserType() {
  return localStorage.getItem(Constants.KEY_USER_TYPE);
}

export function isOrg() {
  return getUserType() === Constants.USER_TYPE_ORG;
}

export function setAccessToken(access_token) {
  localStorage.setItem(Constants.KEY_ACCESS_TOKEN, access_token);
}

export function getAccessToken() {
  let access_token = undefined;
  if (
    localStorage.getItem(Constants.KEY_ACCESS_TOKEN) !== undefined &&
    localStorage.getItem(Constants.KEY_ACCESS_TOKEN) !== null
  ) {
    access_token = 'Bearer ' + localStorage.getItem(Constants.KEY_ACCESS_TOKEN);
  }
  return access_token;
}

export function isLogin() {
  if (getAccessToken() !== null && getAccessToken() !== undefined) {
    return true;
  }
  return false;
}

export function setRefreshToken(refresh_token) {
  localStorage.setItem(Constants.KEY_REFRESH_TOKEN, refresh_token);
}

export function getRefreshToken() {
  return localStorage.getItem(Constants.KEY_REFRESH_TOKEN);
}

/* Section: API Calls */
export function signin(params) {
  return NewHttpPost(Constants.SIGN_IN, params, null);
}

export function registerOrg(params) {
  return NewHttpPost(Constants.REGISTER_ORG, params, null);
}

export function editOrg(params) {
  return NewHttpPut(Constants.PROFILE, params, getHeaders());
}

export function adminChangeProfile(params) {
  return NewHttpPut(Constants.PROFILE, params, getHeaders());
}

export function registerUser(params) {
  return NewHttpPost(Constants.REGISTER_USER, params, null);
}

export function uploadMedia(params) {
  return NewHttpPost(Constants.UPLOAD, params, getHeaders());
}

export function uploadMediaWithoudAuth(params) {
  return NewHttpPost(Constants.UPLOAD, params, null);
}

export function createProject(params) {
  return NewHttpPost(Constants.PROJECTS, params, getHeaders());
}

export function editProject(id, params) {
  return NewHttpPut(Constants.PROJECTS + '/' + id, params, getHeaders());
}

export function getProjects(query = '', page, filterIsActiveParam = '', isTrending) {
  let offset = page * 10;
  let url = isTrending ? Constants.TRENDING_PROJECTS : Constants.PROJECTS;
  return NewHttpGet(
    url + '?q=' + query + '&status=' + filterIsActiveParam + '&limit=10&offset=' + offset,
    getHeaders()
  );
}

export function getProjectById(projectId) {
  let url = Constants.PROJECTS + '/' + projectId;
  return NewHttpGet(url, getHeaders());
}

export function deleteProject(id) {
  return NewHttpDelete(Constants.PROJECTS + '/' + id, getHeaders());
}

export function disableProject(id, isEnabled) {
  return NewHttpPut(
    Constants.PROJECTS + '/' + id + '/enabled',
    { enabled: isEnabled },
    getHeaders()
  );
}

export function getReviews(url) {
  return NewHttpGet(url, getHeaders());
}

export function deleteReview(id) {
  return NewHttpDelete(Constants.REVIEWS + '/' + id, getHeaders());
}

export function updates() {
  return NewHttpGet(Constants.UPDATES, getHeaders());
}

export function postUpdate(params) {
  return NewHttpPost(Constants.UPDATES, params, getHeaders());
}

export function editUpdate(id, params) {
  return NewHttpPut(Constants.UPDATES + '/' + id, params, getHeaders());
}

export function deleteUpdate(id) {
  return NewHttpDelete(Constants.UPDATES + '/' + id, getHeaders());
}

export function createUpdate(id, params) {
  let url = Constants.PROJECTS;
  url += '/' + id;
  url += '/updates';
  return NewHttpPost(url, params, getHeaders());
}

export function supportingCauses() {
  return NewHttpGet(Constants.SUPPORTING_CAUSES, null);
}

export function getProfile() {
  return NewHttpGet(Constants.PROFILE, getHeaders());
}

export function changePassword(params) {
  return NewHttpPut(Constants.PASSWORD, params, getHeaders());
}

export function getOrgs(query = '', page, filterKey = '', isTrending) {
  let offset = page * 10;
  let url = isTrending ? Constants.TRENDING_ORGANIZATIONS : Constants.ORGANIZATIONS;
  return NewHttpGet(
    url + '?q=' + query + '&status=' + filterKey + '&limit=10&offset=' + offset,
    getHeaders()
  );
}

export function getContribs(query = '', page, isActive = '') {
  let offset = page * 10;
  return NewHttpGet(
    Constants.CONTRIBUTORS +
      '?search=' +
      query +
      '&isActive=' +
      isActive +
      '&limit=10&offset=' +
      offset,
    getHeaders()
  );
}

export function getContribDetails(id) {
  return NewHttpGet(Constants.CONTRIBUTORS + '/' + id, getHeaders());
}

export function disableEnableContrib(id, isDisable) {
  let enableDisable = isDisable ? 'disable' : 'enable';
  return NewHttpPut(Constants.USERS + '/' + id + '/' + enableDisable, null, getHeaders());
}

export function getOrgById(id) {
  return NewHttpGet(Constants.ORGANIZATIONS + '/' + id, getHeaders());
}

export function getOrgProjects(url) {
  return NewHttpGet(url, getHeaders());
}

export function acceptRejectOrg(id, isAccept) {
  let acceptReject = isAccept ? 'accept' : 'reject';
  return NewHttpPut(Constants.ORGANIZATIONS + '/' + id + '/' + acceptReject, null, getHeaders());
}

export function getDonationSummary(id) {
  let url = Constants.DONATIONS + '/' + id;
  return NewHttpGet(url, getHeaders());
}

export function getOrgReviews(url) {
  return NewHttpGet(url, getHeaders());
}

export function getData(url) {
  return NewHttpGet(url, getHeaders());
}

export function endRecurringDonation(id) {
  let url = Constants.DONATIONS + '/' + id;
  return NewHttpDelete(url, getHeaders());
}

export function getDashboardHeaders() {
  return NewHttpGet(Constants.DASHBOARD_HEADERS, getHeaders());
}

export function getDonationsPerProject() {
  return NewHttpGet(Constants.DASHBOARD_DONATIONS_PER_PROJECT, getHeaders());
}

export function getDonationsPerOrg() {
  return NewHttpGet(Constants.DASHBOARD_DONATIONS_PER_ORG, getHeaders());
}

export function getImages(url) {
  return NewHttpGet(url, getHeaders());
}

export async function getDonationHistory(params) {
  return await fetchDataV2.get('/donations/history', {
    params: {
      limit: 10,
      ...params,
    },
  });
}

export async function getAutocomplete(type, q) {
  return await fetchData.get('/autocomplete', {
    params: {
      type: type,
      q: q,
    },
  });
}

export function putImage(url, params) {
  return NewHttpPut(url, params, getHeaders());
}

export function postImage(url, params) {
  return NewHttpPost(url, params, getHeaders());
}
export function deleteImage(url) {
  return NewHttpDelete(url, getHeaders());
}

export function getOverallDonations(period = 'year') {
  return NewHttpGet(Constants.DASHBOARD_OVERALL_DONATIONS + '?period=' + period, getHeaders());
}

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    catchErr(error);
    return Promise.reject(error);
  }
);

export async function NewHttpGet(url, options) {
  let getRequest = await axios.get(url, options); /* .catch((err) => {
    catchErr(err);
  }); */
  return getRequest;
}

export async function NewHttpPost(url, params, options) {
  let postRequest = await axios.post(url, params, options); /* .catch((err) => {
    catchErr(err);
  }); */
  return postRequest;
}

export async function NewHttpPut(url, params, options) {
  let putRequest = await axios.put(url, params, options); /* .catch((err) => {
    catchErr(err);
  }); */
  return putRequest;
}

export async function NewHttpDelete(url, options) {
  let deleteRequest = await axios.delete(url, options); /* .catch((err) => {
    catchErr(err);
  }); */
  return deleteRequest;
}

function catchErr(err) {
  if (err.response !== undefined) {
    responseProcess(err.response.status);
  }

  if (
    err.response !== undefined &&
    err.response.data !== undefined &&
    err.response.data.error !== undefined &&
    err.response.data.error.message !== undefined
  ) {
    errorNotify(err.response.data.error.message);
  } else {
    errorNotify('An error has occurred.');
  }
}

export async function responseProcess(resStatus) {
  switch (resStatus) {
    case 200:
      return true;
    case 201:
      break;
    case 400:
      console.log('Bad Request');
      break;
    case 408:
      alert('Request Timeout');
      break;
    case 401:
      if (window.location.href.split('/').pop() === 'Login') {
        return;
      } else {
        logoutAndRedirect();
      }
      break;
    case 500:
      console.log('server error, try again');
      break;
    default:
      console.log('unhandled error code: ', resStatus);
      break;
  }
  return false;
}

export function removeAfterLogout() {
  localStorage.removeItem(Constants.KEY_USER_TYPE);
  localStorage.removeItem(Constants.KEY_ACCESS_TOKEN);
  localStorage.removeItem(Constants.KEY_REFRESH_TOKEN);
}

export function emptyCache(isToLogin = false) {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  if (!isToLogin) {
    setTimeout(() => window.location.reload());
  } else {
    setTimeout(() => window.location.assign(AppUrls.login));
  }
}

export function logoutAndRedirect() {
  removeAfterLogout();
  emptyCache();
}

export function logoutAndRedirectToLogin() {
  removeAfterLogout();
  emptyCache(true);
}

export function successNotify(message) {
  toast.success(
    <div className='notify-container'>
      <img className='notify-img' src={ic_notify_success} alt='success' />
      <div className='notify-text-container notify-message-success'>
        <div className='notify-title-success'>{message}</div>
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 1000,
      rtl: false,
    }
  );
}

export function successNotifyWithTitle(title, message) {
  toast.success(
    <div className='notify-container'>
      <img className='notify-img' src={ic_notify_success} alt='success' />
      <div className='notify-text-container notify-message-success'>
        <div style={{ marginTop: '-10px' }}>
          <div className='notify-title-success'>{title}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 1000,
      rtl: false,
    }
  );
}

export function errorNotify(message) {
  toast.error(
    <div className='notify-container'>
      <img className='notify-img' src={ic_notify_failed} alt='failed' />
      <div className='notify-text-container'>
        <div className='notify-text-inner'>
          <div className='notify-title-error'>Error</div>
          <div>{message}</div>
        </div>
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 3000,
      rtl: false,
    }
  );
}

export function dateFormmater(date) {
  if (date !== undefined && date !== null && date !== '') return moment(date).format('MM/DD/yyyy');
  else return null;
}

export function getTimeFromUnix(createdAtUnix) {
  return moment.unix(createdAtUnix).format('LT');
}

export function getDateFromUnix(createdAtUnix) {
  return moment.unix(createdAtUnix).format('L');
}

export function getDateFromUnixAsDate(createdAtUnix) {
  let date = '';
  if (createdAtUnix !== null && createdAtUnix !== undefined) {
    date = new Date(createdAtUnix * 1000);
  }
  return date;
}

export function getAddress(objDetails) {
  if (!objDetails) {
    return '-';
  }
  let comps = [objDetails.streetAddress, objDetails.city, objDetails.state?.abbrev];
  return comps
    .filter((item) => item)
    .map((item) => item.trim())
    .join(', ');
}

export function getOrgStatus(isActive = null, isApproved = null) {
  if (isActive !== null && isActive !== undefined) {
    if (!isActive) {
      return 'disabled';
    }
  }
  if (isApproved !== null && isApproved !== undefined) {
    switch (isApproved) {
      case 0:
        return 'pending';
      case 1:
        return 'active';
      case 2:
        return 'rejected';
      default:
        return 'active';
    }
  }
  return 'active';
}

export function numberWithCommas(x) {
  if (x === '' || x === null || x === undefined) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function paginate(arr, size) {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}
