import { useMutation } from 'react-query';
import { useStore } from '../donationStore';

import { postImage as postDonate } from '../global/helpers';

export const useDonate = (url) => {

  const setDonationSummary = useStore(state=> state.setDonationSummary)

  const handlePost = async (data) => {
    await postDonate(url, data).then(resp=>{
      console.log("resp",resp.data)
      setDonationSummary(resp.data)
    });
  };

  return useMutation(handlePost);
};
