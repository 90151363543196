import React, { useEffect, useState } from 'react';

export default function Textarea({ textarea, setTextarea, placeholder, maxLength }) {
  const [textError, setTextError] = useState('');
  useEffect(() => {
    if (maxLength && textarea.length === maxLength)
      setTextError(`You have exceeded the ${maxLength} characters`);
  }, [textarea, maxLength]);
  return (
    <div>
      <div className='relative'>
        <textarea
          value={textarea}
          onChange={(e) => setTextarea(e.target.value)}
          className='border-success p-2 rounded'
          placeholder={placeholder}
          rows='6'
          maxLength={maxLength}
          style={{ resize: 'none', backgroundColor: '#F1FBF0', width: '100%' }}
        ></textarea>
        <span className='absolute text-success' style={{ bottom: '.5rem', right: '.5rem' }}>
          {textarea ? textarea.length : 0} {maxLength ? `/ ${maxLength}` : ''}
        </span>
      </div>
      {textError && <p className='text-danger mt-2 mb-3'>{textError}</p>}
    </div>
  );
}
