import React, { useState } from 'react';
import { PlusCircle } from 'react-bootstrap-icons';

import ImageComponent from '../ImageComponent';
import ImageDetailsModal from '../ImageDetailsModal';

export default function ImagePlaceholder({ isEditing, setIsEditing }) {
  const [showAdd, setShowAdd] = useState(false);
  const [imageFile, setImageFile] = useState('');
  const [imageUpload, setImageUpload] = useState('');
  const [isUploaded, setIsUploaded] = useState('');

  const handleUpload = (e) => {
    setImageUpload(URL.createObjectURL(e.target.files[0]));
    setIsUploaded(true);
    setImageFile(e.target.files[0]);
    setShowAdd(true);
  };
  // console.log('image upload', imageFile.split('fakepath').pop().substring(1));
  return (
    <React.Fragment>
      {imageUpload ? (
        <ImageComponent
          image={imageUpload}
          alt='thumbnail'
          isEditing={isEditing}
          isUploaded={isUploaded}
        />
      ) : (
        <div style={{ width: '100%' }} className=' ml-3 ml-md-0'>
          <label
            htmlFor='image-placeholder'
            className='p-5 d-flex align-items-center justify-content-center cursor-pointer'
            style={{ border: '1px dashed #9BB1A1', width: '100%', height: '100%' }}
          >
            <PlusCircle className='text-lg' style={{ color: '#9BB1A1' }} />
          </label>
          <input
            accept='image/gif, image/jpeg, image/png'
            className='d-none'
            type='file'
            name='image-placeholder'
            id='image-placeholder'
            value={imageUpload}
            onChange={handleUpload}
          />
        </div>
      )}
      <ImageDetailsModal
        isOpen={showAdd}
        setIsOpen={setShowAdd}
        title={'Image'}
        image={imageUpload}
        alt='thumbnail'
        hasConfirmModal={false}
        imageFile={imageFile}
        isUploaded={isUploaded}
        setIsEditing={setIsEditing}
      />
    </React.Fragment>
  );
}
