import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import contactVector from '../../../images/contact.svg';
import './WriteYourMessage.css';
  
export default function WriteYourMessage({ isContact, setIsContact }) {
    const [txtMsg, setTxtMsg] = useState();
    const [charCount, setCharCount] = useState(0);
    const [txtMsgRequired, setTxtMsgRequired] = useState('');
    const [txtMsgStyle, setTxtMsgStyle] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);

    const handleOk = () => {
        setIsContact(false);
        setShowSuccess(false);
        setTxtMsg('');
        setCharCount(0);
    }
    
    const handleSendMsg = () => {
        if (charCount !== 0) {
            setTxtMsgRequired('');
            setTxtMsgStyle({
                border: '1px solid #C5E8CF'
            })
            setShowSuccess(true);
        } else if (charCount === 0) {
            setTxtMsgRequired('Text message is required');
            setTxtMsgStyle({
                border: '1px solid red'
            })
        }
    }

    const handleClose = () => {
        setTxtMsg('');
        setCharCount(0);
        setTxtMsgRequired('');
        setTxtMsgStyle({
            border: '1px solid #C5E8CF'
        })
        setShowSuccess(false);
        setIsContact(false);
    }

    return (
        <Modal
            show={isContact}
            onHide={setIsContact}
            id="contact_modal"
        >
            <Modal.Body>
                {showSuccess === true ? 
                    <div className='contact_success'>
                        <img src={contactVector} id="contact-vector" alt="" />
                        <h3 className="msg_sent">Message Sent</h3>
                        <div>
                            <p className="contacted_lbl">You have contacted</p>
                            <p className="contacted_txt">Together One Hand</p>
                            <button
                                className='btn btn-primary ok-btn'
                                onClick={handleOk}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                : 
                <div className='contact_info'>
                    <form>
                        <p className="contact_name_lbl">Your name</p>
                        <p className="contact_name_txt">Albert Flores</p>
                        <p className="contact_info_lbl">Contact info</p>
                        <p className="contact_email_txt">albertflores@gmail.com</p>
                        <p className="your_msg_lbl">Your message</p>
                        <div className='relative'>
                            <textarea
                                name="txtMessage"
                                className="your_msg_txt"
                                value={txtMsg}
                                onChange={(e) => {
                                    setCharCount(e.target.value.length)
                                    setTxtMsg(e.target.value)
                                }}
                                maxLength={2000}
                                style={txtMsgStyle}
                            ></textarea>
                            <span className='absolute text-success' style={{ bottom: '.5rem', right: '.5rem' }}>
                                {charCount}/2000
                            </span>
                        </div>
                        <p className="required_field">{txtMsgRequired}</p>
                        <button
                            type="button"
                            className='btn btn-primary btn-block mt-4 send_msg-btn'
                            onClick={handleSendMsg}
                        >
                            Send
                        </button>
                        <button
                            type="reset"
                            className='btn btn-primary mt-3 discard_msg-btn'
                            onClick={handleClose}
                        >
                            Discard
                        </button>
                    </form>
                </div>
                }
            </Modal.Body>
        </Modal>
    );
}
