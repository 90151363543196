import React, { useState, useEffect } from 'react';
import './ChangePassword.css';
import topBg from '../../../images/AccountBG.png';
import { Col, Row, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actionTypes';
import * as helpers from '../../../global/helpers';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Current password is required')
    .matches(/^\S*$/, '* This field cannot contain blankspaces'),
  newPassword: yup
    .string()
    .min(8, 'Must be at least 8 characters')
    .matches(/^\S*$/, '* This field cannot contain blankspaces')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  confirmNewPassword: yup
    .string()
    .min(8, 'Must be at least 8 characters')
    .matches(/^\S*$/, '* This field cannot contain blankspaces')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = (props) => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [passwordType, setPasswordType] = useState('password');
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [confirmNewPasswordType, setConfirmNewPasswordType] = useState('password');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === 'input' ? 'password' : 'input');
  };

  const showHideNewPassword = (e) => {
    e.preventDefault();
    setNewPasswordType(newPasswordType === 'input' ? 'password' : 'input');
  };

  const showHideConfirmNewPassword = (e) => {
    e.preventDefault();
    setConfirmNewPasswordType(confirmNewPasswordType === 'input' ? 'password' : 'input');
  };

  useEffect(() => {
    props.setIsBreadcrumbShown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callChangePassword = (data) => {
    setBtnDisabled(true);
    let putData = {
      currentPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    helpers
      .changePassword(putData)
      .then((response) => {
        setBtnDisabled(false);
        if (response !== undefined) {
          if (response.status === 200) {
            helpers.logoutAndRedirectToLogin();
          }
        }
      })
      .catch((error) => {
        setBtnDisabled(false);
      });
  };
  return (
    <div className='change-password-container'>
      <img className='change-password-top-bg' src={topBg} alt='bg' />
      <Row style={{ marginTop: '66px' }}>
        <Col lg={6} className='mx-auto'>
          <Form
            className='mx-auto'
            style={{ width: 'fit-content' }}
            onSubmit={handleSubmit((e) => {
              callChangePassword(e);
            })}
            onReset={(e) => {
              reset();
            }}
          >
            <Form.Group
              controlId='oldPassword'
              className='mx-auto mb-2'
              style={{ width: 'fit-content' }}
            >
              <Form.Label className='pass_lbl'>
                Current Password <span className='required_field'>*</span>
              </Form.Label>
              <Form.Control
                {...register('oldPassword')}
                type={passwordType}
                name='oldPassword'
                className='password_input'
              />
              <span className='password_show' onClick={showHidePassword}>
                {passwordType === 'input' ? (
                  <i id='eye-icon' className='fa fa-eye'></i>
                ) : (
                  <i id='eye-icon' className='fa fa-eye-slash fa-flip-horizontal'></i>
                )}
              </span>
            </Form.Group>
            <div
              className='required_field text-xs mb-2 mr-auto'
              style={{ width: 'fit-content', marginTop: '-20px' }}
            >
              {errors.oldPassword?.message}
            </div>

            <Form.Group className='mx-auto mb-2' style={{ width: 'fit-content' }}>
              <Form.Label className='pass_lbl'>
                New Password <span className='required_field'>*</span>
              </Form.Label>
              <Form.Control
                {...register('newPassword')}
                type={newPasswordType}
                id='newPassword'
                className='password_input'
              />
              <span className='password_show' onClick={showHideNewPassword}>
                {newPasswordType === 'input' ? (
                  <i id='eye-icon' className='fa fa-eye'></i>
                ) : (
                  <i id='eye-icon' className='fa fa-eye-slash fa-flip-horizontal'></i>
                )}
              </span>
            </Form.Group>
            <div
              className='required_field text-xs mb-2 mr-auto'
              style={{ width: 'fit-content', marginTop: '-20px' }}
            >
              {errors.newPassword?.message}
            </div>

            <Form.Group className='mx-auto mb-2' style={{ width: 'fit-content' }}>
              <Form.Label className='pass_lbl'>
                Confirm New Password <span className='required_field'>*</span>
              </Form.Label>
              <Form.Control
                {...register('confirmNewPassword')}
                type={confirmNewPasswordType}
                id='password'
                className='password_input'
              />
              <span className='password_show' onClick={showHideConfirmNewPassword}>
                {confirmNewPasswordType === 'input' ? (
                  <i id='eye-icon' className='fa fa-eye'></i>
                ) : (
                  <i id='eye-icon' className='fa fa-eye-slash fa-flip-horizontal'></i>
                )}
              </span>
            </Form.Group>
            <div
              className='required_field text-xs mb-2 mr-auto'
              style={{ width: 'fit-content', marginTop: '-20px' }}
            >
              {errors.confirmNewPassword?.message}
            </div>

            <Row className='crn-bottom-container mt-4'>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Row>
                  <Col lg={5} md={5} sm={5} xs={5} style={{ textAlign: 'left' }}>
                    <button className='crn-discard mt-2' type='reset'>
                      Clear
                    </button>
                  </Col>
                  <Col lg={7} md={7} sm={7} xs={7} style={{ textAlign: 'right' }}>
                    <button disabled={btnDisabled} type='submit' className='crn-create w-100'>
                      {btnDisabled ? 'Changing...' : 'Change Password'}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsBreadcrumbShown: (isBreadcrumbShown) => {
      dispatch({
        type: actions.SET_IS_BREADCRUMB_SHOWN,
        payload: {
          isBreadcrumbShown: isBreadcrumbShown,
        },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
