import { useMutation } from 'react-query';
import { BASE_URL } from '../constants/Constants';

import { postImage as postPass } from '../global/helpers';

export const useForgotPass = () => {
  let url = `${BASE_URL}/auth/resetPassword`;

  const handlePost = async (data) => {
    await postPass(url, data).then((resp) => console.log('resp', resp));
  };

  return useMutation(handlePost);
};
