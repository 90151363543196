import React, { useEffect, useState } from 'react';
import Card from '../../../components/SuperAdminComponents/Card';
import BarChart from '../../../components/SuperAdminComponents/BarChart';
import TotalDonations from '../../../components/SuperAdminComponents/TotalDonations';
import Donations from '../../../components/SuperAdminComponents/Donations';

//SVG ICONS
import Contributers from '../../../Icons/contributers.svg';
import Orgs from '../../../Icons/orgs.svg';
import Projects from '../../../Icons/projects.svg';
import Money from '../../../Icons/si-glyph_money-coin.svg';

import { getDashboardHeaders, getDonationsPerOrg, getDonationsPerProject, numberWithCommas } from "../../../global/helpers";
import { Col, Row } from 'react-bootstrap';

import DatePicker from 'react-date-picker';
import arrowDownBlue from "../../../Icons/arrow-down.svg";
import arrowDownGreen from "../../../Icons/arrow-down-green.svg";
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export default function Stats() {

  const [totalContribs, setTotalContribs] = useState("");
  const [totalOrg, setTotalOrg] = useState("");
  const [totalProjects, setTotalProjects] = useState("");
  const [totalOrgsDonationsAmount, setTotalOrgsDonationsAmount] = useState("");
  const [totalProjectsDonationsAmount, setTotalProjectsDonationsAmount] = useState("");

  const [donationsPerOrganization, setDonationsPerOrganization] = useState([]);
  const [donationsPerProjects, setDonationsPerProjects] = useState([]);
  const [isFinishedPerOrg, setIsFinishedPerOrg] = useState(false);
  const [isFinishedPerProject, setIsFinishedPerProject] = useState(false);

  const [yearSelectorDisabled, setYearSelectorDisabled] = useState(false)
  const [selectedYear, setSelectedYear] = useState(null)
  const [radioValue, setRadioValue] = useState("Last 12 Months")
  const [months] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])


  useEffect(() => {
    getDashboardHeaders().then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          let dashboardHeaders = response?.data
          setTotalContribs(numberWithCommas(dashboardHeaders?.totalContribs))
          setTotalOrg(numberWithCommas(dashboardHeaders?.totalOrg))
          setTotalProjects(numberWithCommas(dashboardHeaders?.totalProjects))
          setTotalOrgsDonationsAmount(numberWithCommas(dashboardHeaders?.totalOrgsDonationsAmount))
          setTotalProjectsDonationsAmount(numberWithCommas(dashboardHeaders?.totalProjectsDonationsAmount))
        }
      }
    })

    getDonationsPerOrg().then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          let data = response?.data
          setDonationsPerOrganization(data)
          setIsFinishedPerOrg(true)

        }
      }
    })

    getDonationsPerProject().then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          let data = response?.data
          setDonationsPerProjects(data)
          setIsFinishedPerProject(true)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (radioValue !== null && radioValue === "Last 12 Months") {
      setYearSelectorDisabled(true)
      setSelectedYear(null)
    } else {
      if (yearSelectorDisabled) {
        setYearSelectorDisabled(false)
      }
    }
  }, [radioValue, yearSelectorDisabled])

  return (
    <div className='container-fluid'>
      <Row>
        <Col lg={9} >
          <BarChart />
        </Col>
        <Col>
          <div id="stats_modal"
            className={`p-3 bg-white shadow rounded`}
            style={{ cursor: "default" }}
          >
            <DatePicker
              className={"cc-input px-1 mt-2 z-index-2"}
              clearIcon={null}
              calendarIcon={<img src={yearSelectorDisabled ? arrowDownGreen : arrowDownBlue} alt="Calendar" />}
              format="yy"
              defaultView="decade"
              maxDetail="decade"
              yearPlaceholder={"Select year"}
              monthPlaceholder={null}
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e)
              }}
              disabled={yearSelectorDisabled}
              required
              maxDate={new Date()}
            />
            <div>
              <ButtonGroup toggle className="d-block mt-2">
                <ToggleButton
                  className="stats-month-selector"
                  type="radio"
                  name="radio"
                  value="Last 12 Months"
                  checked={radioValue === "Last 12 Months"}
                  onChange={e => setRadioValue(e.currentTarget.value)}>
                  Last 12 Months
                </ToggleButton>

                <ButtonGroup toggle className="d-flex flex-wrap mt-2">
                  {
                    months.map((item, index) => {
                      return (
                        <ToggleButton
                          key={"month-" + index}
                          className="stats-month-selector"
                          type="radio"
                          name="radio"
                          value={item}
                          checked={radioValue === item}
                          onChange={e => setRadioValue(e.currentTarget.value)}
                        >
                          {item}
                        </ToggleButton>
                      )
                    })
                  }

                </ButtonGroup>
              </ButtonGroup>
            </div>

            <div className="my-3" style={{ height: "1px", background: "#DDF3E3" }} />
            <div className="d-flex" style={{ justifyContent: "end" }}>
              <button className="crn-create">Update</button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Card title='Contributors' total={totalContribs} icon={Contributers} />
        </Col>
        <Col lg={4}>
          <Card title='Organizations' total={totalOrg} icon={Orgs} />
        </Col>
        <Col lg={4}>
          <Card title='Projects' total={totalProjects} icon={Projects} />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TotalDonations amount={totalOrgsDonationsAmount} title='Total Organizations Donations' icon={Money} />
        </Col>
        <Col lg={6}>
          <TotalDonations amount={totalProjectsDonationsAmount} title='Total Projects Donations' icon={Money} />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Donations
            title='Donations per organization'
            isDonationPerOrg={true}
            data={donationsPerOrganization}
            isFinished={isFinishedPerOrg}
            donationsPerProjects={donationsPerProjects}
          />
        </Col>
        <Col lg={6}>
          <Donations
            title='Donations per Project'
            isDonationPerOrg={false}
            data={donationsPerProjects}
            isFinished={isFinishedPerProject} />
        </Col>
      </Row>
    </div>
  );
}
