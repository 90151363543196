import React from 'react';
import PendingRegistration from '../components/PendingRegistration/PendingRegistration'
import PendingImage from '../images/pending-image.svg';

const pendingRegistration = () => {
    return (
        <PendingRegistration
            title={'Pending Registration'}
            subtitle1={'Your account has not been published yet. Sadaqas will review your organization and get back to you.'}
            subtitle2={'Don’t forget to check your spam folder.'}
            image={PendingImage}
            showResendEmail={false}
        />
    )
}

export default pendingRegistration;