import React from 'react';
import { Container } from 'react-bootstrap';
import DonateProjectItem from '../reusableComponents/DonateProjectItem/DonateProjectItem';
import './DonateProjectsTab.css';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { SpinnerDiv } from '../SpinnerDiv';

import * as helpers from "../../global/helpers";

const DonateProjectsTab = () => {

  const { data, hasNextPage, fetchNextPage, isFetching } = useFetchProjects({
    projects: true,
  });
  
  return (
    <Container id='donate_projects_tab-div'>
      {isFetching && !data ? <SpinnerDiv color='#7CB1FF' /> : ''}
      {data && (
        <InfiniteScroll
          className='pb-4'
          style={{ overflow: 'hidden' }}
          dataLength={data.pageParams[data.pageParams.length - 1] || 0}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<SpinnerDiv color='#7CB1FF' />}
          scrollableTarget='scrollableDiv'
        >
          {data &&
            data.pages.map((page) =>
              page.data.map((project) => (
                <div key={project.id} className='my-2'>
                  <DonateProjectItem 
                    title={project.title}
                    amount={project.amount}
                    recurringText={project.recurringText}
                    nextDonationAt={helpers.dateFormmater(project.nextDonationAt)}
                    profileImage={project.profileImage}
                    by={project.by}
                    recurring={project.recurring}
                  />
                </div>
              ))
            )}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default DonateProjectsTab;
//!data ? 0 : data.pageParams[data.pageParams.length - 1]
