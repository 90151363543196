import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Coin from '../../../Icons/Coins.svg';
import ic_no_projects from "../../../images/ic_no_projects.png";
import next from "../../../Icons/next.svg"
import nextDisabled from "../../../Icons/next-disabled.svg"
import prev from "../../../Icons/prev.svg"
import prevDisabled from "../../../Icons/prev-disabled.svg"
import { XLg } from 'react-bootstrap-icons';
import Green from '../../../Icons/modal-bg.png';
import { numberWithCommas, paginate } from '../../../global/helpers';

export default function DonationModal({ amount, title, image, isOpen, setIsOpen, donationsPerProjectsLocal }) {
  const [isPopulated, setIsPopulated] = useState(false)
  const [pages, setPages] = useState([])
  const [donationsArray, setDonationsArray] = useState([...donationsPerProjectsLocal])
  const [pageNumber, setPageNumber] = useState(0)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  useEffect(() => {
    if (donationsPerProjectsLocal.length > 0 && !isPopulated) {
      let pages = paginate([...donationsPerProjectsLocal], 4);
      setPages(pages)
      setIsPopulated(true)
    }
  }, [isPopulated, donationsPerProjectsLocal])

  useEffect(() => {

    let maxNumber = pages.length - 1
    if (pageNumber === 0) {
      if (!isPrevDisabled) {
        setIsPrevDisabled(true)
      }
      if (pages.length > 1) {
        if (isNextDisabled) {
          setIsNextDisabled(false)
        }
      } else {
        if (!isNextDisabled) {
          setIsNextDisabled(true)
        }
      }
      return
    } else if (pageNumber > 0 && pageNumber < maxNumber) {
      if (isPrevDisabled) {
        setIsPrevDisabled(false)
      }
      if (isNextDisabled) {
        setIsNextDisabled(false)
      }
    } else if (pageNumber > 0 && pageNumber === maxNumber) {
      if (!isNextDisabled) {
        setIsNextDisabled(true)
      }
      if (pages.length > 1) {
        if (isPrevDisabled) {
          setIsPrevDisabled(false)
        }
      }
    }

  }, [isNextDisabled, isPrevDisabled, pageNumber, pages])

  useEffect(() => {
    if (pages.length > 0)
      setDonationsArray(pages[pageNumber])
  }, [pageNumber, pages]);

  let nextIcon = isNextDisabled ? nextDisabled : next;
  let prevIcon = isPrevDisabled ? prevDisabled : prev;

  let totalAmount = amount;
  donationsPerProjectsLocal.map((item) => {
    return totalAmount += item.amount
  })

  totalAmount = numberWithCommas(totalAmount)

  return (
    <Modal show={isOpen} onHide={setIsOpen} centered style={{ borderRadius: "5px" }}>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0 mb-3 relative'>
        <Modal.Title>Organization</Modal.Title>
        <div className=' text-success '>
          <XLg
            style={{ zIndex: '2' }}
            className='relative cursor-pointer'
            onClick={() => setIsOpen(false)}
          />
        </div>
        <img
          alt='background'
          src={Green}
          className='absolute'
          style={{ top: '0px', right: '0px' }}
        />
      </Modal.Header>
      <Modal.Body className='px-0 pb-0 mb-0 '>
        <div className='d-flex px-2'>
          <div>
            <img src={image} alt='logo' className='ml-2' style={{ width: '100px', height: "100px", borderRadius: "50px" }} />
          </div>
          <div className='ml-3 '>
            <h1 className='text-sm text-capitalize  mb-4 tracking-normal'>{title}</h1>
            <p className='text-capitalize mb-2 tracking-normal'>Total Donated Amount</p>
            <h1 className='text-sm text-capitalize tracking-normal'>${numberWithCommas(amount)}</h1>
          </div>
        </div>
        <div className='px-3'>
          <h1 className='text-sm text-capitalize  mt-5 mb-4 tracking-normal'>Project(s)</h1>

          {
            donationsArray.length > 0
              ? donationsArray.map((item, index) => {
                return (
                  <div
                    className='d-flex align-items-center mt-2 py-2 border-bottom'
                    key={"donation-modal-item-" + Math.random()}
                  >
                    <div className='d-flex  align-items-center'>
                      <h1 className='text-sm text-capitalize text-green-light mb-0'>#{index + 1 + (4 * pageNumber)}</h1>
                      <p className='text-sm text-capitalize ml-2 mb-0 text-dark tracking-normal'>
                        {item.title}
                      </p>
                    </div>
                    <h1 className='text-sm text-capitalize ml-auto mb-0 tracking-normal'>${numberWithCommas(item.amount)}</h1>
                    <img src={Coin} alt='logo' className='ml-2' />
                  </div>
                )
              })
              : <div className="mx-auto d-flex ">
                <img className="no-projects-img" src={ic_no_projects} alt="No Projects" style={{ width: "110px", height: "110px" }} />
                <div className="mt-5" style={{ color: "#263238" }}>
                  This organization doesn't have project(s)
                </div>
              </div>
          }

          {
            donationsArray.length > 0
              ? <div className="mt-4 d-flex mx-auto justify-content-center">
                <button
                  className="mr-5"
                  style={{ background: "transparent", border: "unset" }}
                  disabled={isPrevDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    let prevPage = pageNumber - 1;
                    setPageNumber(prevPage)
                  }}>
                  <img src={prevIcon} alt="prev" style={{ width: "11.73px", height: "15px" }} />
                </button>
                <button
                  style={{ background: "transparent", border: "unset" }}
                  disabled={isNextDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    let prevPage = pageNumber + 1;
                    setPageNumber(prevPage)
                  }}
                >
                  <img src={nextIcon} alt="next" style={{ width: "11.73px", height: "15px" }} />
                </button>
              </div>
              : null
          }


        </div>
        <div
          className='py-4 px-3 mt-3 mb-0 mx-0 d-flex justify-content-between '
          style={{ backgroundColor: '#7CB1FF' }}
        >
          <h1 className='text-light text-sm text-capitalize mb-0 tracking-normal'>Total Amount</h1>
          <h1 className='text-light text-sm text-capitalize mb-0 tracking-normal'>${totalAmount}</h1>
        </div>
      </Modal.Body>
    </Modal >
  );
}
