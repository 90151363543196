import React, { useEffect, useState } from "react";
import HomeProjectItem from '../reusableComponents/HomeProjectItem/HomeProjectItem';
import HomeOrgItem from '../reusableComponents/HomeOrgItem/HomeOrgItem';
import TrendingOrgProject from '../reusableComponents/TrendingOrgProject/TrendingOrgProject';
import TrendingProjectsOrgsStat from '../reusableComponents/TrendingProjectsOrgsStat/TrendingProjectsOrgsStat';
import UserImage from '../../images/sadaqasbg.png';
import './ContributorHomePage.css';
import * as Constants from "../../constants/Constants";
import * as helpers from "../../global/helpers";
import icon from '../../images/blueInformationIcon.svg';
import { Tooltip, OverlayTrigger  } from 'react-bootstrap';

const ContributorHomePage = (props) => {
    const [organizations, setOrganizations] = useState("");
    const [projects, setProjects] = useState("");
    const [total, setTotal] = useState("");
    const [data, setData] = useState([]);

    useEffect(() => {
        helpers.updates()
            .then((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        console.log(response.data);
                        setOrganizations(response.data.header.organizations);
                        setProjects(response.data.header.projects);
                        setTotal(response.data.header.total);
                        setData(response.data.data)
                    }
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updatesTooltip = (props) => (
        <Tooltip className="updates_tooltip" {...props}>
          You will only see updates from Sadaqas team & projects you have donated to
        </Tooltip>
    );

    return (
        <div id="contributor-home">
            <div className="left-section">
                <div className="title-items-container">
                    <h2 className='Home-update-item'>Updates</h2>
                    <div className='Home-info-item'>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={updatesTooltip}
                        >
                            <img src={icon} width={props.size} height={props.size} alt=''/>
                        </OverlayTrigger>
                    </div>
                </div>
                {
                    data.map((item) => {
                        let time = "-";
                        let date = "-";
                        if (item.createdAtUnix !== null && item.createdAtUnix !== undefined) {
                            let convertedDate = new Date(item.createdAtUnix * 1000);
                            let pm = "PM"
                            if (convertedDate.getHours() > 12) {
                                pm = "AM"
                            }
                            let hours = convertedDate.getHours() > 12 ? convertedDate.getHours() - 12 : convertedDate.getHours();
                            let minutes = "0" + convertedDate.getMinutes();
                            minutes = minutes.substr(-2);
                            time = hours + ":" + minutes + " " + pm;
                            date = convertedDate.getMonth() + "/" + convertedDate.getDay() + "/" + convertedDate.getFullYear();
                        }
                        switch (item.type) {
                            case Constants.TYPE_PROJECT:
                                return <div className='Home-Org-Item' key={"project-" + item.id}>
                                    <HomeProjectItem
                                        image={UserImage}
                                        title={item.title}
                                        name={item.by}
                                        time={time}
                                        date={date}
                                        review={item.value}
                                    />
                                </div>;
                            case Constants.TYPE_SADAQA:
                                return <div className="Home-Proj-Item" key={"sadaqa-" + item.id}>
                                    <HomeOrgItem
                                        image={UserImage}
                                        name={item.title}
                                        time={time}
                                        date={date}
                                        review={item.value}
                                    />
                                </div>;
                            default:
                                return null;
                        }
                    })
                }
                <div style={{ height: "20px" }} />
            </div>
            <div className="right-section">
                <div className='home-stats'>
                    <TrendingProjectsOrgsStat
                        projects={projects}
                        organizations={organizations}
                        amount={total}
                    />
                </div>
                <div className='home-trending'>
                    <TrendingOrgProject
                        setIsTrendingOrganizationsAndProjects={props.setIsTrendingOrganizationsAndProjects}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContributorHomePage;