import React from 'react'
import icon from '../../../images/trendingOrgIcon.svg'
import ribbon from '../../../images/projectRibbon.svg'
import arrow from '../../../images/trendingArrow.svg'
import { Link } from 'react-router-dom'
import './TrendingOrgProject.css'

export default function TrendingOrgProject(props) {
    return (
        <Link to='/home/trending'>
            <div className='trending-section'>
                <img src={icon} alt='' className='trendin-icon' />  
                <img src={ribbon} alt='' className='trending-ribbon' />
                <h2 className='trending-field'>Trending Organizations &#38; Projects</h2> 
                <Link to='/'><img src={arrow} alt='' className='trending-arrow' /> </Link>      
            </div>
        </Link>
    )
}
