import React, { useState } from 'react';

import EditImageDropdown from '../EditImageDropdown';
import ImageDetailsModal from '../ImageDetailsModal';
import ImageModal from '../ImageModal';
import ConfirmRemoveModal from '../ConfirmRemoveModal';

import { BASE_URL } from '../../constants/Constants';

export default function ImageComponent({ image, alt, isEditing, isUploaded, caption }) {
  const [showImg, setShowImg] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  let src = isUploaded ? image : `${BASE_URL}/media/${image}/200`;

  return (
    <div className='relative '>
      {isEditing ? (
        <EditImageDropdown setShowEdit={setShowEdit} setShowRemove={setShowRemove} />
      ) : (
        ''
      )}
      
      <img
        onClick={() => setShowImg(true)}
        className='rounded cursor-pointer'
        style={{ width: '100%' }}
        src={src}
        alt={alt}
      />

      <ImageModal
        image={image}
        isOpen={showImg}
        setIsOpen={setShowImg}
        isUploaded={isUploaded}
        caption={caption}
      />

      {
        //Edit Image Modal
        <ImageDetailsModal
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          title='Image'
          image={image}
          alt={alt}
          hasConfirmModal={true}
          isUploaded={isUploaded}
          caption={caption}
        />
      }
      {
        //Remove Image Modal
        <ConfirmRemoveModal
          isOpen={showRemove}
          setIsOpen={setShowRemove}
          title='Removing image'
          msg='Are you sure?'
          discard='No'
          confirm='Yes, Remove it'
          guid={image}
        />
      }
    </div>
  );
}
