import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo.png';
import Arrow from '../../images/arrow.png';
import './OpeningSection.css';
import * as AppUrls from '../../constants/AppUrls';
/*import * as Constants from "../../constants/Constants";
import * as helpers from "../../global/helpers"; */

const OpeningSec = () => {
  return (
    <section id='main'>
      <div className='main-section'>
        <div className='main-inner'>
          <div className='item'>
            <div className='first_item'>
              <div id='top_content-div'>
                <div className='row'>
                  <div className='col-6 col-md-4' id='top_first-div'></div>
                  <div className='col-6 col-md-4' id='top_second-div'>
                    <div className='text-center'>
                      <img src={Logo} className='mx-auto sadaqa-logo' alt='' />
                    </div>
                  </div>
                  <div className='col-6 col-md-4' id='top_third-div'>
                    <div className='text-center'>
                      <div className='btn-group auth-btns' role='group'>
                        <Link to={AppUrls.login} className='authentication_top_link'>
                          <button type='button' className='btn btn-primary' id='login-btn'>
                            Log in
                          </button>
                        </Link>
                        <Link to={AppUrls.register_individual} className='authentication_top_link'>
                          <button type='button' className='btn btn-primary' id='register-btn'>
                            Register - <span className='its-free'>it's free</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='about_content-div'>
                <div className='container'>
                  <div className='col-lg-12'>
                    <div className='text-center'>
                      <h3 className='main-title'>Are you ready to make a change?</h3>
                      <p className='main-desc'>
                        Sadaqas helps you to discover, donate &#38; create fundraising for the
                        people and causes you care about
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div id='start_content-div'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='card start_register_card text-center'>
                        <div className='card-body d-flex flex-column'>
                          <h3 className='individuals_start-h3'>Individuals</h3>
                          <Link
                            to={AppUrls.register_individual}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                            className='startRegisterLink'
                          >
                            <button type='button' className='btn btn-default' id='get_started-btn'>
                              Get Started
                            </button>
                          </Link>
                          <p className='login_link'>
                            or{' '}
                            <Link to={AppUrls.login} className='login_url'>
                              log in with email
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 card_second_col'>
                      <div className='card start_register_card text-center'>
                        <div className='card-body d-flex flex-column'>
                          <h3 className='non_profit-h3'>Non Profit</h3>
                          <Link
                            to={AppUrls.register_organization}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                            className='startRegisterLink'
                          >
                            <button
                              type='button'
                              className='btn btn-primary mt-auto'
                              id='start_campaign-btn'
                            >
                              Start Your Campaign
                            </button>
                          </Link>
                          <p className='login_link'>
                            or{' '}
                            <Link to={AppUrls.login} className='login_url'>
                              log in with email
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='second_item'>
              <div id='how_it_works-div'>
                <div className='container'>
                  <div className='col-lg-12'>
                    <div className='text-center'>
                      <div className='row'>
                        <div className='col-lg-8'>
                          <h3 className='work_h3'>How it works</h3>
                          <p className='work_desc'>
                            Explore organizations and projects and donate to one of your choosing
                            one time or on recurring basis.
                          </p>
                        </div>
                        <div className='col-lg-4' style={{ opacity: 0 }}>
                          <h3 className='learn_more'>Learn more</h3>
                          <div className='text-center'>
                            <div className='btn-group' role='group'>
                              <img src={Arrow} className='mx-auto arrow-sign' alt='' />
                              <button
                                type='button'
                                className='btn btn-primary d-none'
                                id='visit-btn'
                              >
                                visit sadaqas.org
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpeningSec;
