import React from 'react';
import "../../reusableComponents/ConfirmationModal/ConfirmationModal.css"
import { Modal } from 'react-bootstrap';
import ic_top_bg from "../../../images/ic_top_bg.svg";
import ic_close from "../../../images/ic_close.svg";

export default function DisableProjectModal(
    {
        projectName,
        isDisable,
        isOpen,
        setIsOpen,
        submitFunc,
        type = "confirmation-danger"
    }) {

    return (
        <Modal className={"confirmation-modal " + type} show={isOpen} onHide={setIsOpen} centered >

            <div style={{ display: "flex" }}>
                <div className="confirmation-modal-title">
                    {
                        isDisable
                            ? <>Disable Project</>
                            : <>Activate Project</>
                    }
                </div>
                <div style={{ marginLeft: "auto", }}>
                    <img src={ic_top_bg} alt="" />
                    <img src={ic_close} alt="" className="confirmation-modal-close" onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                    }} />
                </div>
            </div>
            <div style={{ marginLeft: "27px", marginRight: "27px" }}>
                <Modal.Body className='px-0 pb-0 mb-0 pt-0' style={{ letterSpacing: "0px" }}>
                    <div >
                        {
                            isDisable
                                ? <>You are disabling &nbsp;<b className="bold-600">{projectName}.&nbsp;</b>
                                    The project will become invisible to user & cannot receive donations.</>
                                : <>You are activating &nbsp;<b className="bold-600">{projectName}.&nbsp;</b>
                                    The project will become visible to user & will start receiving donations.</>
                        }

                    </div>
                    <div className='d-flex text-right' style={{ justifyContent: "space-evenly", marginTop: "40px" }}>
                        <button className="confirmation-modal-keep" onClick={(e) => {
                            e.preventDefault();
                            setIsOpen(false);
                        }}>Cancel</button>
                        <button className="confirmation-modal-submit" onClick={(e) => {
                            e.preventDefault();
                            submitFunc()
                        }}>{
                                isDisable
                                    ? <>Disable</>
                                    : <>Activate</>
                            }</button>
                    </div>
                </Modal.Body>
            </div>

        </Modal>
    );
}
