import React from 'react';
import FinalRegistrationPage from '../reusableComponents/FinalRegistrationPage/FinalRegistrationPage'
import './VerificationEmailReceived.css';

const VerificationEmailReceived = (props) => {
    return (
        <FinalRegistrationPage
            image={props.image}
            title={props.title}
            subtitle1={props.subtitle1}
            subtitle2={props.subtitle2}
            showResendEmail={props.showResendEmail}
        />
    )
}

export default VerificationEmailReceived;