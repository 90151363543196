
import React from 'react';
import RegisterOrganization from '../components/register/registerOrganization/registerOrganization'

const registerOrganization = () => {
    return (
        <RegisterOrganization />
    )
}

export default registerOrganization;