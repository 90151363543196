import React from "react";
import "./NoProjects.css"
import ic_no_projects from "../../../../images/ic_no_projects.png";
import ic_add_project from "../../../../images/ic_add_project.png";
import { useHistory } from "react-router-dom";
import * as AppUrls from "../../../../constants/AppUrls";

function NoProjects({ isAdmin = false }) {
    let history = useHistory();

    return (
        <div className="no-projects-container">
            <img className="no-projects-img" src={ic_no_projects} alt="No Projects" />
            <div className="no-projects-text-1">
                There are no projects.
            </div>

            {
                isAdmin
                    ? null
                    : <>
                        <div className="no-projects-text-2">
                            Create a project for a cause and start receiving donations
                        </div>
                        <div>
                            <button className="create-new-project-btn" onClick={(e) => {
                                e.preventDefault();
                                history.push(AppUrls.org_create_new_project);
                            }}>
                                <img className="create-new-project-img" src={ic_add_project} alt="Create New Project" />
                                Create New Project
                            </button>
                        </div>
                    </>
            }
        </div>
    )
}

export default NoProjects;