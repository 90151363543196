import React, { useState } from 'react';
import { ThreeDots, Dot } from 'react-bootstrap-icons';

import Edit from '../../../Icons/edit.svg';
import Remove from '../../../Icons/remove.svg';

import { Dropdown } from 'react-bootstrap';
import EditProfileModal from '../EditProfileModal';

import './styles.css';
import * as helpers from '../../../global/helpers';
import * as Constants from '../../../constants/Constants';

import defaultImage from '../../../images/img-default.svg';
import ConfirmationModal from '../../reusableComponents/ConfirmationModal/ConfirmationModal';

export default function ProjectUpdate({
  update = null,
  editUpdate,
  removeUpdate,
  isProjectUpdate = true,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  let updateId = update?.id;
  let title = update?.title;
  let by = update?.by !== undefined ? update?.by : null;
  let time = helpers.getTimeFromUnix(update?.createdAtUnix);
  let date = helpers.getDateFromUnix(update?.createdAtUnix);
  let value = update?.value;

  let img = defaultImage;
  if (
    update !== undefined &&
    update.image !== null &&
    update.image !== '' &&
    update.image !== 'guid'
  ) {
    let imgUrl = Constants.BASE_URL + '/media/' + update.image + '/200'; //first field: GUID, second is: size
    img = imgUrl;
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage;
  };

  const removeUpdateClicked = () => {
    removeUpdate(updateId, setShowRemove);
  };

  return (
    <div className='bg-white rounded p-4 shadow my-4 '>
      <div className='d-flex justify-content-between align-items-start '>
        <div className='d-flex'>
          <img
            className='img-object-fit-cover'
            src={img}
            alt='avatar'
            onError={addDefaultSrc}
            style={{ width: '70px', height: '70px', borderRadius: '2px' }}
          />
          <div className='ml-4 mt-2'>
            <h1
              className='text-md text-capitalize mb-0'
              style={{ letterSpacing: '0px', fontWeight: 'bold' }}
            >
              {title}
            </h1>
            {by !== null ? (
              <p className='text-dark my-1 text-sm'>
                <span style={{ color: 'lightgray' }}>by </span>
                {by}
              </p>
            ) : null}

            <p style={{ fontSize: '12px' }}>
              <span className='text-sm'>
                {time} <Dot />
              </span>
              <span className='text-sm'> {date}</span>
            </p>
          </div>
        </div>
        {helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? null : (
          <div className='ml-md-5 ml-sm-4 cursor-pointer relative '>
            <Dropdown drop='end'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
              >
                <ThreeDots style={{ color: '#7CB1FF', fontSize: '18px' }} />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ border: '1px solid #7CB1FF' }}
                className='update-profile-dropdown '
              >
                {isProjectUpdate ? null : (
                  <Dropdown.Item onClick={() => setShowEdit(true)}>
                    <span>
                      <img src={Edit} alt='edit' className='mr-2' />
                    </span>
                    Edit
                  </Dropdown.Item>
                )}

                <Dropdown.Item style={{ color: '#FF7E7E' }} onClick={() => setShowRemove(true)}>
                  <span>
                    <img src={Remove} alt='remove' className='mr-2' />
                  </span>
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <div className='mt-3'>
        <p className='mb-0 text-dark tracking-normal text-sm'>{value}</p>
      </div>
      {showEdit ? (
        <EditProfileModal
          updateId={updateId}
          value={value}
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          editUpdate={editUpdate}
        />
      ) : null}
      <ConfirmationModal
        title='Remove Update'
        description='You are removing an update left by a user. This will permanently delete it & cannot be undone.'
        isOpen={showRemove}
        setIsOpen={setShowRemove}
        cancelBtnTitle='Keep'
        submitBtnTitle='Remove'
        submitFunc={removeUpdateClicked}
      />
    </div>
  );
}
