import React from "react";
import * as helpers from "../../global/helpers";
import * as Constants from "../../constants/Constants";
import OrganizationAdmin from "../SuperAdmin/OrganizationsAdmin";
import Organizations from "../organizations";

function OrganizationWrapper() {
    return (
        helpers.getUserType() === Constants.USER_TYPE_ADMIN
            ? <OrganizationAdmin />
            : helpers.getUserType() === Constants.USER_TYPE_CONTRIB
                ? <Organizations />
                : helpers.getUserType() === Constants.USER_TYPE_ORG
                    ? <div>not allowed</div>
                    : <div>Invalid User</div>

    )
}

export default OrganizationWrapper;