import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from './global/helpers';
import * as AppUrls from './constants/AppUrls';
import * as helpers from "./global/helpers";
import * as Constants from './constants/Constants';
import * as SideData from './components/reusableComponents/SideBar/SideBarData';
import HomePage from './pages/HomePage';

const PrivateRoute = ({ component: Component, userType, ...rest }) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route {...rest} render={props => (
            isLogin() ?
                helpers.getUserType() === Constants.USER_TYPE_CONTRIB && (userType === Constants.USER_TYPE_CONTRIB || userType === Constants.USER_TYPE_ALL)
                    ? (
                        <HomePage SideData={SideData.ContribSidebarData}>
                            <Component {...props} />
                        </HomePage>
                    )
                    : helpers.getUserType() === Constants.USER_TYPE_ORG && (userType === Constants.USER_TYPE_ORG || userType === Constants.USER_TYPE_ALL)
                        ? (
                            <HomePage SideData={SideData.OrgSidebarData}>
                                <Component {...props} />
                            </HomePage>
                        )
                        : helpers.getUserType() === Constants.USER_TYPE_ADMIN && (userType === Constants.USER_TYPE_ADMIN || userType === Constants.USER_TYPE_ALL)
                            ? (
                                <HomePage SideData={SideData.AdminSidebarData}>
                                    <Component {...props} />
                                </HomePage>
                            )
                            : (
                                <Redirect to={AppUrls.home} />
                            )
                : <Redirect to={AppUrls.any} />
        )} />
    );
};

export default PrivateRoute;
