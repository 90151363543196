import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../images/Greenlogo.png';
import BackButton from '../../images/back-button.png';
import Heart from '../../images/heart.png';
import Email from '../../images/emaiIcon.png';
import SinglePasswordToggle from '../reusableComponents/SinglePasswordToggle/SinglePasswordToggle';
import './login.css';
import * as helpers from "../../global/helpers";
import * as AppUrls from "../../constants/AppUrls";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

const schema = yup.object().shape({
  emailAddress: yup.string().required("Email Address is required"),
  password: yup.string().required("Password is required")
});


const Login = () => {
  const [isBtnsDisabled, setIsBtnsDisabled] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  function callLoginApi(data) {
    setIsBtnsDisabled(true);
    let postdata = {
      email: data.emailAddress,
      password: data.password
    };

    helpers.signin(postdata)
      .then((response) => {
        setIsBtnsDisabled(false)
        if (response !== undefined) {
          if (response.data) {
            if (response.data.user !== undefined) {
              console.log("response data ", response.data)
              helpers.setUserType(response.data.user.role);
              helpers.setAccessToken(response.data.accessToken);
              helpers.setRefreshToken(response.data.refreshToken);
              setRedirect(AppUrls.home);
            } else {
              console.log("failed")
            }
          }
        }
      })
      .catch((error) => {
        setIsBtnsDisabled(false)
        console.log(error)
      })
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <section id="login">
      <div className="login-section">
        <div className="login-inner">
          <div className="login_item">
            <div id="login_nav_content-div">
              <div className="row">
                <div className="col-lg-6" id="nav_content_first-div">
                  <div className="container">
                    <Link to="/" className={isBtnsDisabled ? "disabled" : ""}>
                      <img src={BackButton} className="back-button" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6" id="nav_content_second-div">
                  <div className="container text-right">
                    <p className="contact-us-link">
                      Contact Us
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center" id="nav_content_third-div">
                <img src={Logo} className="mx-auto sadaqa-logo" alt="" />
              </div>
            </div>
            <div id="login_form-div">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <form className="justify-content-center" onSubmit={handleSubmit((data) => {
                    console.log(data)
                    callLoginApi(data);
                  })}>
                    <div className="wrapper">
                      <h5 className="login_title">Join or Log in</h5>
                      <img src={Heart} className="heart_logo" alt="" />
                    </div>
                    <p className="login_text">
                      to give your favorite organizations &#38; causes
                    </p>
                    <div className="form-group login_input">
                      <input
                        type="text"
                        className="form-control email_input"
                        placeholder="Your email address"
                        {...register("emailAddress")}
                      />
                    </div>
                    <div
                      className="required_field text-xs mr-auto mb-2"
                      style={{ width: "fit-content" }} >
                      {errors.emailAddress?.message}
                    </div>
                    <SinglePasswordToggle register={{ ...register("password") }} />
                    <div
                      className="required_field text-xs mr-auto mb-2"
                      style={{ width: "fit-content" }} >
                      {errors.password?.message}
                    </div>
                    <Link
                      id="forget_pass-a"
                      className={
                        isBtnsDisabled
                          ? "to_forgot_password disabled"
                          : "to_forgot_password"
                      }
                      to={AppUrls.forgot_password}>
                      <p className="forget_pass">
                        Forgot your password?
                      </p>
                    </Link>
                    <button
                      disabled={isBtnsDisabled}
                      type="submit"
                      className="btn btn-primary btn-block text-capitalize login_button"
                    >
                      Log in
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div id="social_network_login-div">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="justify-content-center">
                    {/* <h5 className="continue_with">Continue with</h5>
                    <div className="btn-toolbar mb-2">
                      <button id="facebook-btn" className="btn btn-primary text-capitalize mr-2" disabled={isBtnsDisabled} >
                        <i className="fab fa-facebook-square icon"></i>
                        <p className="social_button_text">Facebook</p>
                      </button>
                      <button id="google-btn" className="btn btn-primary text-capitalize" disabled={isBtnsDisabled}>
                        <i className="fab fa-google icon"></i>
                        <p className="social_button_text">Google</p>
                      </button>
                    </div> */}
                    <Link className={
                      isBtnsDisabled
                        ? "to_register_individual disabled"
                        : "to_register_individual"
                    } to={AppUrls.register_individual}>
                      <button id="email-btn" className="btn btn-primary btn-block" disabled={isBtnsDisabled}>
                        <img src={Email} id="send_email-icon" alt="" />
                        <p>Sign up with email</p>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id="login_bottom_content-div">
              <div className="container login_bottom_card">
                <div className="row justify-content-center align-items-center">
                  <div className="justify-content-center">
                    <p>
                      Looking to accept donations?
                    </p>
                    <Link
                      className={
                        isBtnsDisabled
                          ? "to_register_organization disabled"
                          : "to_register_organization"
                      }
                      to={AppUrls.register_organization}>
                      <button id="register_organization-btn" className="btn btn-primary" disabled={isBtnsDisabled}>
                        Register your organization
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login;