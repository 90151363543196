import React from 'react';
import ProjectInfo from '../../components/reusableComponents/ProjectInfo/ProjectInfo'
import { Container, Row, Col} from 'react-bootstrap';

import './ProjectImages.css'


const ProjectImages = () => {
    return (
    <div className='projects-about-page' >
        <Container >
            <Row>
                <Col lg={2} id='project-images-info'>
                <ProjectInfo title='save humans & turtles' author="sadaqas" amount="3400" totalamount="12000" endDate='08/20/2021' createdDate='1/1/2021' overview=" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,..."/>
                </Col>
                
                <Col lg={10} id='project-images-field' >
                                     
                </Col>
            </Row>
        </Container>
    </div>
    )
}

export default ProjectImages