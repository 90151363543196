import React from 'react';
import "./DropDownItem.css";
import * as Constants from "../../../constants/Constants";
import amountLogo from '../../../images/amount-logo.svg';

function DropDownItem(props, option) {
    const item = option.object;
    const isProject = item.type === "project";
    return (
        <button {...props} className="btn-container">
            <img alt={""} src={item.image ? (Constants.MEDIA + "/" + item.image + "/100") : amountLogo}
                 className={isProject ? "project-img" : "organization-img"}/>
            <div>
                <div className="type">
                    {
                        isProject ? "Project" : "Organization"
                    }
                </div>
                <div className="title">{item.title}</div>
            </div>
        </button>
    )
}

export default DropDownItem;
