import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PaymentMethodRadioBtn from '../../../components/reusableComponents/PaymentMethodRadioBtn/PaymentMethodRadioBtn';
import DonateSupport from './donateSupport/DonateSupport';
import ic_visa from '../../../images/ic_visa.png';
import ic_mastercard from '../../../images/ic_mastercard.png';
import ic_paypal from '../../../images/ic_paypal.png';
import { useFetchPayments } from '../../../hooks/useFetchPayments';
import './DropDownItem.css';
import './RecurringDonationsStep1.css';
import './RecurringDonationsStep2.css';
import { BASE_URL } from '../../../constants/Constants';
import AddPaymentMethodFunctional from '../../../components/reusableComponents/AddPaymentMethod/AddPaymentMethodFunctional';

import { usePostPayment } from '../../../hooks/usePostPayment';
import { useDonate } from '../../../hooks/useDonate';

import { useParams } from 'react-router-dom';
import { initialRadios } from './RecurringDonationsStep1';
import { SelectedDonationItem } from './RecurringDonations';

import defaultImage from '../../../images/img-default.svg';

const RecurringDonationsStep2 = (props) => {
  const { amount, recurring, data, selectedItem } = props;

  const [paymentMethods, setPaymentMethods] = useState('');
  const [checkedId, setCheckedId] = useState('');

  let url = `${BASE_URL}/payments/cards`;
  const { data: paymentsData } = useFetchPayments(url);
  const { mutateAsync } = usePostPayment(url);

  const { orgId, projectId } = useParams();

  let donateUrl, donateId;

  if (orgId) {
    donateUrl = `${BASE_URL}/orgs/${orgId}/donations`;
    donateId = orgId;
  }
  if (projectId) {
    donateUrl = `${BASE_URL}/projects/${projectId}/donations`;
    donateId = projectId;
  }

  if (selectedItem) {
    if (selectedItem.type === 'org') {
      donateUrl = `${BASE_URL}/orgs/${selectedItem.id}/donations`;
    } else {
      donateUrl = `${BASE_URL}/projects/${selectedItem.id}/donations`;
    }
  }

  const { mutateAsync: mutateDonate, isLoading } = useDonate(donateUrl);
  const [cancel, setCancel] = useState(true);
  const [reqFailure, setReqFailure] = useState('');

  useEffect(() => {
    setCancel(isLoading);
    const isLoadingTimeout = setTimeout(() => {
      if (isLoading) {
        setCancel(false);
        setReqFailure('Looks like something went wrong');
      }
    }, 30000);

    return () => clearTimeout(isLoadingTimeout);
  }, [isLoading]);

  const onRadioChange = (id) => {
    setCheckedId(id);
  };

  const addPaymentMethod = (cardNumber, validityDate, methodType, cardHolderName, onClose) => {
    let img;
    switch (methodType) {
      case 1:
        img = ic_visa;
        break;
      case 2:
        img = ic_mastercard;
        break;
      case 3:
        img = ic_paypal;
        break;
      default:
        img = ic_mastercard;
        break;
    }
    let newId = paymentMethods[paymentMethods.length - 1].id + 1;

    setPaymentMethods((oldArray) => [
      ...oldArray,
      {
        id: newId,
        cardNumber: cardNumber,
        validityDate: validityDate,
        img: img,
        cardHolderName: cardHolderName,
      },
    ]);

    onClose();
  };

  const addPaymentMethodPopup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AddPaymentMethodFunctional
            onClose={onClose}
            addPaymentMethod={addPaymentMethod}
            mutateAsync={mutateAsync}
          />
        );
      },
    });
  };

  const [isSupportedModalView, setIsSupportedModalView] = useState(false);

  return (
    <div>
      {selectedItem ? (
        <SelectedDonationItem
          title={selectedItem.title}
          description={selectedItem.description}
          type={selectedItem.type}
          image={selectedItem.image}
          by={selectedItem.by}
        />
      ) : null}

      {data ? (
        <div className='d-flex mb-5'>
          <img
            src={`${!data.image ? defaultImage : `${BASE_URL}/media/${data.image}/100`}`}
            className='donate_org_vector'
            alt=''
          />
          {orgId ? (
            <h3 className='org_name-h3'>{data.name}</h3>
          ) : (
            <div className='d-flex flex-column ml-3 mt-2'>
              <h3 className='tracking-normal text-sm text-normal '>{data.description}</h3>
              <h3 className='tracking-normal text-sm text-normal  mt-2'>
                <span style={{ color: '#90AD98' }} className='font-weight-normal mr-2'>
                  Created By
                </span>{' '}
                {data.byOrg}
              </h3>
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      <div className='recurring-item-title' style={{ marginTop: '11px' }}>
        Select Payment Method
      </div>

      <Form style={{ marginTop: '18px' }}>
        <div key='inline-radio' className='mb-3'>
          {paymentsData &&
            paymentsData.map((paymentMethod) => {
              return (
                <PaymentMethodRadioBtn
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  checkedId={checkedId}
                  onRadioChange={onRadioChange}
                  setCheckedId={setCheckedId}
                  isCheckPayment={true}
                />
              );
            })}
        </div>
      </Form>
      <button
        className='add-payment-method-btn'
        onClick={(e) => {
          e.preventDefault();
          addPaymentMethodPopup();
        }}
      >
        + Add Payment Method
      </button>
      {isLoading && (cancel || !cancel) ? (
        <div className='mt-5 text-center'>
          <Spinner animation='border' role='status' style={{ color: '#67DB88' }} />
        </div>
      ) : (
        <button
          className='donate-btn'
          onClick={async (e) => {
            e.preventDefault();
            setReqFailure('');

            await mutateDonate({
              amount,
              recurring,
              paymentMethodId: checkedId,
            });
            setIsSupportedModalView(true);
          }}
        >
          Donate ${amount}{' '}
          {recurring
            ? `- ${initialRadios.filter((radio) => radio.key === recurring)[0].value}`
            : '- One Time'}
        </button>
      )}

      <div className='back-btn-container'>
        {reqFailure && <p className='required_field mb-3 tracking-normal'>{reqFailure}</p>}
        <button
          className='back-btn'
          onClick={(e) => {
            e.preventDefault();
            props.backBtn();
          }}
        >
          Back
        </button>
      </div>
      <DonateSupport
        donateId={donateId}
        donationDuration={
          recurring
            ? `- ${initialRadios.filter((radio) => radio.key === recurring)[0].value}`
            : '- One Time'
        }
        isSupport={isSupportedModalView}
        amount={amount}
        setIsSupport={setIsSupportedModalView}
        data={data}
        selectedItem={props.selectedItem}
      />
    </div>
  );
};

export default RecurringDonationsStep2;
