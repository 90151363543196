import React, { useEffect, useState } from 'react';

import { Search } from 'react-bootstrap-icons';
/* import Download from '../../../Icons/download.svg';
 */
import Card from '../../../components/SAContributors/Card';


import InfiniteScroll from 'react-infinite-scroll-component';
import { Col } from 'react-bootstrap';
import { disableEnableContrib, getContribs } from '../../../global/helpers';

export default function Contributors() {
  const [contribs, setContribs] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [filterIsActive, setFilterIsActive] = useState("");

  useEffect(() => {
    getContribsLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isSearching && contribs.length === 0 && page === 0 && !isFinished) {
      getContribsLocal()
    }
    if (isSearching && contribs.length === 0 && page === 0 && !isFinished) {
      callGetContribs(searchText, 0, filterIsActive, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, contribs, page, isFinished])

  useEffect(() => {
    if (filterIsActive !== null) {
      searchAndFilterFunctionality()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsActive])

  const getContribsLocal = () => {
    callGetContribs("", page, "")
  }

  const searchContrib = () => {
    callGetContribs(searchText, page, filterIsActive)
  }
  const callGetContribs = (query, pageParam, filterIsActiveParam, isInitialSearch = false) => {
    getContribs(query, pageParam, filterIsActiveParam)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            if (isInitialSearch) {
              setPage(1)
              setContribs(response.data.data)
            } else {
              let pageLocal = page + 1;
              setPage(pageLocal)
              let contribsLocal = contribs.concat(response.data.data)
              setContribs(contribsLocal)
            }
            setIsFinished(true)
            if (response.data.data.length === 0 || response.data.data.length < 10) {
              setHasMore(false)
            } else {
              if (!hasMore)
                setHasMore(true)
            }
          }
        }
      })
  }
  const searchAndFilterFunctionality = () => {
    if (searchText === "" && filterIsActive === "") {
      if (isSearching) {
        setIsSearching(false)
        setContribs([])
        setPage(0)
        setIsFinished(false)
        return
      } else {
        return;
      }
    } else {
      setIsSearching(true)
      setContribs([])
      setPage(0)
      setIsFinished(false)
    }
  }

  const disableActivateContrib = (id, isDisable, setShowModal) => {
    disableEnableContrib(id, isDisable)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            setShowModal(false)
            let newArr = [...contribs];
            for (let index = 0; index < contribs.length; index++) {
              if (contribs[index].id === id) {
                newArr[index].isActive = !isDisable;
              }
            }
            setContribs(newArr)
          }
        }
      })
  }

  let nextFunc = isSearching ? searchContrib : getContribsLocal

  return (
    <div className='container-fluid px-0' style={{ minHeight: '70vh' }}>
      <div id="cont-top" className='d-lg-flex justify-content-lg-between align-items-lg-center mx-3'>
        <div className='d-lg-flex  align-items-lg-center col-lg-8 px-lg-0 px-sm-0 px-md-0'>
          <div className="d-flex mr-lg-3 relative col-lg-6 mb-md-4 mb-lg-0 mb-sm-4 px-0">

            <div className='flex-grow-1 mr-2'>
              <Search className='absolute ' style={{ top: '15px', left: '20px', color: '#90AD98' }} />
              <input
                placeholder='Search'
                className='form-control pl-5 bg-white shadow rounded'
                style={{ color: '#90AD98' }}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <button className="btn btn-success ml-2 mr-4 rounded py-2 px-4" onClick={(e) => {
              e.preventDefault();
              searchAndFilterFunctionality();
            }} >
              search
            </button>
          </div>
          <div className='d-flex justify-content-between align-items-center mb-lg-0 mb-md-4 mb-sm-4 my-xs-4 '>
            <label
              htmlFor='filter_by'
              className='mb-0'
              style={{ color: '#90AD98', width: '100px' }}
            >
              Filter by
            </label>
            <select
              id='filter_by'
              className='form-control py-2 pr-5 pl-1 ml-3 shadow border-0 rounded'
              style={{}}
              onChange={(e) => {
                setFilterIsActive(e.target.value);
              }}
              value={filterIsActive}
            >
              <option value="">All</option>
              <option value="true">Active</option>
              <option value="false">Disabled</option>
            </select>
          </div>
        </div>
        {/* <button className='btn btn-success rounded d-flex align-items-center col-md-12 col-lg-auto'>
          <img src={Download} alt='logo' className='mr-2  ' />
          Download CSV
        </button> */}
      </div>

      {
        !isFinished
          ? <h4 className="loading-label">Loading...</h4>
          : <div className='mt-2' style={{ height: "calc(100% - 30px)" }}>
            <InfiniteScroll
              className="row w-100 pb-5 mx-0 h-100"
              dataLength={contribs.length}
              next={nextFunc}
              hasMore={hasMore}
              loader={<h4 className="loading-label">Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {
                contribs.map((data) => {
                  return (
                    <Col lg={4} md={4} sm={12} xs={12}
                      key={"org-item-" + data.id}
                      className="mt-4 mb-xl-2">
                      <Card contribDetails={data} disableActivateContrib={disableActivateContrib} />
                    </Col>
                  )
                })
              }
            </InfiniteScroll>
          </div>

      }
    </div>
  );
}
