import React from 'react';
import Tabs from '../../../components/SAProjectDetails/Tabs';
import InfoCard from './InfoCard';

export default function ProjectDetails({ projectDetails }) {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-4'>
          <InfoCard projectDetails={projectDetails} />
        </div>
        <div className='col-lg-7 mt-lg-0 mt-md-5 mt-sm-5'>
          <Tabs projectDetails={projectDetails} />
        </div>
      </div>
    </div>
  );
}
