import { getImages as getReviews } from '../global/helpers';

import { useQuery } from 'react-query';
import { BASE_URL } from '../constants/Constants';
import { reviews } from '../constants/Keys';

export const useFetchReviews = (id) => {
  let url = `${BASE_URL}/orgs/${id}/reviews`;
  return useQuery(reviews, () => getReviews(url).then((resp) => resp.data.data));
};
