import React, { useState, useEffect, useImperativeHandle } from 'react';
import logo from '../../../Icons/update_logo.svg';
import postlogo from '../../../Icons/post-logo.svg';

import PostModal from '../PostModal';
import PostComponent from '../PostComponent';
import * as helpers from "../../../global/helpers"
import * as Constants from "../../../constants/Constants"
import InfiniteScroll from 'react-infinite-scroll-component';

const UpdatePost = React.forwardRef((props, ref) => {
  const [showPost, setShowPost] = useState(false);


  const [updates, setUpdates] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (page === 0 && updates.length === 0 && hasMore)
      getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updates])

  const getUpdates = () => {
    let pageSize = 10;
    let url = Constants.UPDATES + "?filter=mine&limit=10&offset=" + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal)
    helpers.getData(url)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let updatesLocal = updates.concat(response.data.data)
            setUpdates(updatesLocal);

            if (response.data.data.length === 0 || response.data.data.length < pageSize)
              setHasMore(false);
          }
        }
      })
  }

  const postUpdate = (value) => {
    let postdata = {
      value: value
    }

    helpers.postUpdate(postdata)
      .then((response) => {
        if (response.status === 201) {
          console.log("register 201")
          helpers.successNotifyWithTitle("Saved", "Your changes have been saved.")
          setShowPost(false)
          setPage(0)
          setHasMore(true);
          setUpdates([])
          props.getUpdatesFromParent()
        }

      })
  }

  const editUpdate = (id, value, setShowEdit) => {
    let postdata = {
      value
    }

    helpers.editUpdate(id, postdata)
      .then((response) => {
        if (response.status === 200) {
          helpers.successNotifyWithTitle("Saved", "Your changes have been saved.")
          setShowEdit(false)

          let newArr = [...updates]; // copying the old datas array
          for (let index = 0; index < updates.length; index++) {
            if (updates[index].id === id) {
              newArr[index].value = value;
            }
          }
          setUpdates(newArr)
          props.getUpdatesFromParent()

        }
      })
  }

  useImperativeHandle(ref, () => ({
    getUpdatesFromChild() {
      setHasMore(true);
      setPage(0);
      setUpdates([]);
    }
  }));

  const removeUpdate = (id, setShowRemove) => {
    helpers.deleteUpdate(id)
      .then((response) => {
        if (response.status === 200) {
          helpers.successNotify("Update deleted")
          setShowRemove(false)
          setUpdates(updates.filter(item => item.id !== id))
          props.getUpdatesFromParent()
        }
      })
  }

  return (
    <div className='bg-white rounded  shadow my-4 pb-4'>
      <div className='p-4 border-bottom'>
        <h1 className='text-lg text-capitalize tracking-normal font-weight-bold mb-4'>
          Your Updates
        </h1>
        <div className='d-flex align-items-center mb-4'>
          <img src={logo} alt='logo' />
          <h1 className='text-md  tracking-normal font-weight-bold ml-4'>Sadaqas</h1>
        </div>
        <div className='text-center'>
          <button
            onClick={() => setShowPost(true)}
            className='py-1 px-3 border-0 text-light rounded'
            style={{ backgroundColor: '#7CB1FF' }}
          >
            <img src={postlogo} alt='logo' className='mr-2 ' />
            Post an update
          </button>
        </div>
      </div>
      <div id="scrollableDivMyUpdates" className='overflow-auto' style={{ maxHeight: '65vh' }}>
        <InfiniteScroll
          dataLength={updates.length}
          next={getUpdates}
          hasMore={hasMore}
          loader={<h4 className="loading-label">Loading...</h4>}
          scrollableTarget="scrollableDivMyUpdates"
        >
          {
            updates.map((item, index) => {
              return (
                <PostComponent key={"updates-" + index}
                  update={item} editUpdate={editUpdate} removeUpdate={removeUpdate} />
              )
            })
          }
        </InfiniteScroll>
      </div>
      <PostModal isOpen={showPost} setIsOpen={setShowPost} postUpdate={postUpdate} />

    </div>
  );
})

export default UpdatePost;