import React from 'react';
import { Row } from 'react-bootstrap';
// import donateOrgVector from '../../../images/oragnization_test.png';
import money from '../../../images/money.svg';
import './DonateOrganizationItem.css';
import { BASE_URL } from '../../../constants/Constants';

const DonateOrganizationItem = (props) => {
    const { title, amount, recurringText, nextDonationAt, profileImage, recurring } = props;
    let src = profileImage ? `${BASE_URL}/media/${profileImage}/200` : profileImage ;

    return (
        <div className='donate-organization-item'>
            <Row className="donate-organization-item-row">
                <div className="donate-org-col-one">
                    <img src={src} alt='' className='donate_org_logo' />
                </div>
                <div className="donate-org-col-two">
                    <p className="donate_org_name">{title}</p>
                    <div className="donate_value-div">
                        <img src={money} alt="money" className="org_money_img mr-1" />
                        <p className="org_donate_value-p">{amount}</p>
                    </div>
                </div>
                <div 
                    className="donate-org-col-three"
                    style={{
                        alignSelf: recurring === null ? 'center' : ''
                    }}
                >
                    <p className="donate_badge_status">{recurringText}</p>
                    <div
                        style={{
                            display: recurring === null ? 'none' : 'inline'
                        }}
                    >
                        <div className="org_donation_date-div">
                            <p className="org_donation_date-lbl">Next Donation:</p>
                            <p className="org_donation_date-txt">{nextDonationAt}</p>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default DonateOrganizationItem;
