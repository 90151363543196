import React, { useState } from 'react';

import Edit from '../../../Icons/edit.svg';
import Remove from '../../../Icons/remove.svg';

import { ThreeDots, Dot } from 'react-bootstrap-icons';

import { Dropdown } from 'react-bootstrap';
import * as helpers from "../../../global/helpers";
import EditPostModal from '../EditPostModal';
import ConfirmationModal from '../../reusableComponents/ConfirmationModal/ConfirmationModal';

export default function PostComponent({ update, editUpdate, removeUpdate }) {
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  let id = update.id
  let time = helpers.getTimeFromUnix(update?.createdAtUnix)
  let date = helpers.getDateFromUnix(update?.createdAtUnix)
  let value = update?.value

  const removeUpdateClicked = () => {
    removeUpdate(id, setShowRemove)
  }
  return (
    <div className='p-4 border-bottom'>
      <div className='d-flex justify-content-between align-items-center '>
        <div>
          <p className='mb-0' style={{ fontSize: '12px' }}>
            <span>
              {time} <Dot />
            </span>
            <span> {date}</span>
          </p>
        </div>

        <div className='ml-5 cursor-pointer '>
          <Dropdown drop='start'>
            <Dropdown.Toggle
              variant='light'
              id='dropdown-basic'
              style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
            >
              <ThreeDots style={{ color: '#7CB1FF', fontSize: '18px' }} />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }} className=''>
              <Dropdown.Item onClick={() => setShowEdit(true)}>
                <span>
                  <img src={Edit} alt='edit' className='mr-2' />
                </span>
                Edit
              </Dropdown.Item>
              <Dropdown.Item style={{ color: '#FF7E7E' }} onClick={() => setShowRemove(true)}>
                <span>
                  <img src={Remove} alt='remove' className='mr-2' />
                </span>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <p className='tracking-normal mt-4 text-sm'>
        {value}
      </p>
      <EditPostModal updateId={id} value={value} isOpen={showEdit} setIsOpen={setShowEdit} editUpdate={editUpdate} />
      <ConfirmationModal
        title="Remove Update"
        description="You are removing your update. This will permanently delete it & cannot be undone."
        isOpen={showRemove}
        setIsOpen={setShowRemove}
        cancelBtnTitle="Keep"
        submitBtnTitle="Remove"
        submitFunc={removeUpdateClicked}
      />
    </div>
  );
}
