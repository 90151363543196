import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CreateNewProject from '../CreateNewProject/CreateNewProject';

export default function EditProject() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
    }, [dispatch]);
    return (
        <div className="w-100">
            <CreateNewProject isEdit={true}/>
        </div>
    );
}
