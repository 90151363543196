import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";

import ProjectUpdate from '../../../components/SuperAdminComponents/ProjectUpdate';
import UpdatePost from '../../../components/SuperAdminComponents/UpdatePost';
import * as helpers from "../../../global/helpers"
import * as Constants from "../../../constants/Constants"

export default function Updates() {
  const [updates, setUpdates] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const childUpdateRef = useRef();

  useEffect(() => {
    getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (page === 0 && updates.length === 0 && hasMore)
      getUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updates, hasMore])

  const getUpdates = () => {
    let pageSize = 10;
    let url = Constants.UPDATES + "?limit=10&offset=" + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal)
    helpers.getData(url)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let updatesLocal = updates.concat(response.data.data)
            setUpdates(updatesLocal);
            if (!isFinished)
              setIsFinished(true)

            if (response.data.data.length === 0 || response.data.data.length < pageSize)
              setHasMore(false);
          }
        }
      })
  }

  const reGetUpdates = () => {
    setPage(0);
    setUpdates([]);
    setHasMore(true);
  }
  const editUpdate = (id, value, setShowEdit) => {
    let postdata = {
      value
    }

    helpers.editUpdate(id, postdata)
      .then((response) => {
        if (response.status === 200) {
          helpers.successNotifyWithTitle("Saved", "Your changes have been saved.")
          setShowEdit(false)

          let newArr = [...updates]; // copying the old datas array
          for (let index = 0; index < updates.length; index++) {
            if (updates[index].id === id) {
              newArr[index].value = value;
            }
          }
          setUpdates(newArr)
        }
      })
  }

  const removeUpdate = (id, setShowRemove) => {
    helpers.deleteUpdate(id)
      .then((response) => {
        if (response.status === 200) {
          helpers.successNotify("Update Removed")
          setShowRemove(false)
          setUpdates(updates.filter(item => item.id !== id))
          childUpdateRef.current.getUpdatesFromChild();
        }
      })
  }


  return (
    <div className='d-lg-flex d-md-block row w-100'>
      <div className='col-lg-7 col-md-12'>
        <InfiniteScroll
          className="px-4"
          dataLength={updates.length}
          next={getUpdates}
          hasMore={hasMore}
          loader={<h4 className="loading-label">Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {
            updates.map((item, index) => {
              return (
                <ProjectUpdate key={"updates-" + index} update={item} editUpdate={editUpdate} removeUpdate={removeUpdate} />
              )
            })
          }
        </InfiniteScroll>
      </div>
      <div className='ml-lg-auto ml-md-1 col-lg-4 col-md-12'>
        <UpdatePost getUpdatesFromParent={reGetUpdates} ref={childUpdateRef} />
      </div>
    </div>
  );
}
