import React, { useRef, useState } from 'react';
import './AccountWrapper.css';
import * as helpers from '../global/helpers';
import * as Constants from '../constants/Constants';
import * as AppUrls from '../constants/AppUrls';

import ContributorAccount from './ContributorAccount/ContributorAccount';
import AdminAccount from './SuperAdmin/AdminAccount';
import OrgAccount from '../components/OrganizationComponents/OrgAccount/OrgAccount';

import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChangePasswordIcon } from '../images/ChangePassword.svg';
import { ReactComponent as Logout } from '../images/Logout.svg';
import { ReactComponent as AdminChangeProfileImage } from '../images/ic_admin_change_profile_image.svg';

import ConfirmationModal from '../components/reusableComponents/ConfirmationModal/ConfirmationModal';

function AccountWrapper() {
  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();
  const adminAccountRef = useRef(null);

  function uploadSingleFile(e) {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append(
      "file", file
    );

    helpers.uploadMedia(formData)
      .then((response) => {
        if (response.status !== undefined && response.status === 201) {
          let postData = {
            imageGuid: response.data.guid
          }
          helpers.adminChangeProfile(postData).then((response) => {
            if (response.status !== undefined && response.status === 200) {
              helpers.successNotify("Profile Image Changed");
              if (adminAccountRef !== undefined && adminAccountRef.current !== undefined) {
                adminAccountRef.current.getProfileFromOutside();
              }
            }
          })
        }
      })
  }

  return (
    <div className='container-fluid px-0'>
      <Row style={{ height: '100%' }}>
        <Col lg={8} md={8} sm={12} xs={12}>
          {helpers.getUserType() === Constants.USER_TYPE_ORG ? (
            <OrgAccount />
          ) : helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? (
            <ContributorAccount />
          ) : helpers.getUserType() === Constants.USER_TYPE_ADMIN ? (
            <AdminAccount ref={adminAccountRef} />
          ) : (
            <div>Invalid User</div>
          )}
        </Col>
        <Col className='account-right-side' lg={4} md={4} sm={12} xs={12}>
          {helpers.getUserType() === Constants.USER_TYPE_ADMIN ? (
            <Form.Group >
              <Form.Control
                type="file"
                style={{ opacity: "0", position: "fixed", cursor: "pointer" }}
                onChange={(e) => { uploadSingleFile(e) }}
              />
              <div
                id='account-change-password'
                className='d-flex justify-content-start align-items-center'
                style={{ background: "white", borderRadius: "" }}
              >
                <span className='mx-3'>
                  <AdminChangeProfileImage />
                </span>
                <span> Change Profile Image</span>
              </div>

            </Form.Group>
          ) : null}

          <button
            id='account-change-password'
            className='d-flex justify-content-start align-items-center'
            onClick={(e) => {
              e.preventDefault();
              history.push(AppUrls.change_password);
            }}
          >
            <span className='mx-3'>
              <ChangePasswordIcon />
            </span>
            <span>Change Password</span>
          </button>
          <button
            id='log-out-account-button'
            className='d-flex justify-content-start align-items-center'
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(true);
            }}
          >
            <span className='mx-3'>
              <Logout />
            </span>
            <span> Log out</span>
          </button>
        </Col>
      </Row>

      <ConfirmationModal
        title='Logging out'
        description='Are you sure you want to log out?'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        submitFunc={helpers.logoutAndRedirect}
        cancelBtnTitle='Stay'
        submitBtnTitle='Log out'
        type='confirmation-danger'
      />
    </div >
  );
}

export default AccountWrapper;
