import { useParams } from 'react-router';

import { getImages } from '../global/helpers';

import { BASE_URL } from '../constants/Constants';

import { ImagesKey } from '../constants/Keys';

import { useQuery } from 'react-query';

export default function useFetchImages() {
  const { id } = useParams();

  let url = `${BASE_URL}/projects/${id}/images`;

  return useQuery(ImagesKey, () => getImages(url).then((resp) => resp.data));
}