import React from 'react';

import { Modal } from 'react-bootstrap';

import { XLg } from 'react-bootstrap-icons';
import { BASE_URL } from '../../constants/Constants';

export default function ImageModal({ image, isOpen, setIsOpen, isUploaded, caption }) {
  let src = isUploaded ? image : `${BASE_URL}/media/${image}/600`;
  return (
    <Modal show={isOpen} onHide={setIsOpen} centered>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0  relative'>
        <Modal.Title>
          {
            // <span
            //   className='tracking-normal text-white py-3 px-4 rounded text-normal text-sm font-weight-normal mr-4 '
            //   style={{ backgroundColor: '#67DB88' }}
            // >
            //   4 / 6
            // </span>
          }
          <span
            className='tracking-normal text-normal text-sm font-weight-normal mr-3'
            style={{ color: '#90AD98' }}
          >
            01/20/2021
          </span>
          <span
            className='tracking-normal text-normal text-sm font-weight-normal mr-3'
            style={{ color: '#90AD98' }}
          >
            2:49 PM
          </span>
        </Modal.Title>
        <div className=' text-success '>
          <XLg
            style={{ zIndex: '2' }}
            className='relative cursor-pointer'
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center ' style={{ width: '100%' }}>
          <img src={src} alt='thumbnail' style={{ width: '100%' }} />
        </div>
        <p className='tracking-normal text-normal text-sm font-weight-normal my-3'>{caption}</p>
      </Modal.Body>
    </Modal>
  );
}
