import * as actions from "./actionTypes";

const initialState = {
    title: "",
    isBreadcrumbShown: false
}

const reducer = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case actions.SET_TITLE:
            newState.title = action.payload.title
            return newState;
        case actions.SET_IS_BREADCRUMB_SHOWN:
            newState.isBreadcrumbShown = action.payload.isBreadcrumbShown
            return newState;
        default:
            break;
    }
    return newState;
};

export default reducer