import React from 'react'
import HomeProjectItem from '../../components/reusableComponents/HomeProjectItem/HomeProjectItem'

import ProjectInfo from '../../components/reusableComponents/ProjectInfo/ProjectInfo'
import ProjectImage from '../../images/projectItemImage.png'
import './ProjectUpdates.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ProjectUpdates = () => {
    return (

        <div className='project-updates-page'>
            <Container>
                <Row>
                    <Col lg={4} id='updates-project-info'>
                        <ProjectInfo title='save humans & turtles' author="sadaqas" amount="3400" totalamount="12000" endDate='08/20/2021' createdDate='1/1/2021' overview=" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,..." />
                    </Col>

                    <Col lg={3} id='updates-project-item'>
                        <HomeProjectItem image={ProjectImage} title='Save turtles' name='NGO' date="12/12/2021" time='12:30 PM' review='Project to save turtles' />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProjectUpdates