import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import ImageConfirmModal from '../ImageConfirmModal';

import { BASE_URL } from '../../constants/Constants';

import Textarea from '../Textarea';
import { usePostImage } from '../../hooks/usePostImage';
import { usePatchImage } from '../../hooks/usePatchImage';

export default function ImageDetailsModal({
  isOpen,
  setIsOpen,
  title,
  image,
  alt,
  hasConfirmModal,
  imageFile,
  isUploaded,
  caption,
  setIsEditing,
}) {
  const [textarea, setTextarea] = useState(caption ? caption : '');
  const [showConfirm, setShowConfirm] = useState(false);

  const [disabled, setDisabled] = useState(false);

  let src = isUploaded ? image : `${BASE_URL}/media/${image}/600`;

  const { mutate: mutatePost, onError: onErrorPost } = usePostImage(
    imageFile,
    textarea,
    setIsEditing,
    setIsOpen,
    setDisabled
  );

  const { mutate: mutateEdit, onError: onErrorPatch } = usePatchImage(
    image,
    textarea,
    setDisabled,
    setIsOpen
  );

  if (onErrorPost) {
    setDisabled(false);
  }

  if (onErrorPatch) {
    setDisabled(false);
  }

  return (
    <Modal show={isOpen} onHide={setIsOpen} centered backdrop='static'>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0  relative'>
        <Modal.Title>{title}</Modal.Title>
        <div className=' text-success '>
          <XLg
            style={{ zIndex: '2' }}
            className={`${disabled ? 'cursor-disabled' : ''} relative cursor-pointer`}
            onClick={() => {
              setIsOpen(false);
              setIsEditing(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className=''>
          <img className='rounded ' style={{ width: '100%' }} src={src} alt={alt} />
        </div>
        <h1 className='tracking-normal text-sm text-normal my-3'>
          Caption{' '}
          <span className='text-xs font-weight-normal ' style={{ color: 'lightgrey' }}>
            (optional)
          </span>
        </h1>
        <Textarea textarea={textarea} setTextarea={setTextarea} placeholder='Enter your caption' />
        <div className='d-md-flex d-block text-center text-md-left justify-content-between align-items-center mt-4'>
          {hasConfirmModal ? (
            <React.Fragment>
              <button
                onClick={() => setShowConfirm(true)}
                className={`${disabled ? 'cursor-disabled' : ''} mr-4 mb-4 mb-md-0`}
                style={{ backgroundColor: 'transparent', border: 'none', color: '#7CB1FF' }}
              >
                Discard
              </button>
              <button
                onClick={mutateEdit}
                className={`${disabled ? 'cursor-disabled' : ''} py-2 px-4 rounded text-white`}
                style={{ backgroundColor: '#67DB88', border: 'none', color: '#7CB1FF' }}
              >
                Done Editing
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button
                disabled={disabled}
                onClick={() => {
                  setIsOpen(false);
                  setIsEditing(false);
                }}
                className={`${disabled ? 'cursor-disabled' : ''} mr-4 mb-4 mb-md-0`}
                style={{ backgroundColor: 'transparent', border: 'none', color: '#7CB1FF' }}
              >
                Discard
              </button>
              <button
                disabled={disabled}
                onClick={mutatePost}
                className={`${disabled ? 'cursor-disabled' : ''} py-2 px-4 rounded text-white`}
                style={{ backgroundColor: '#67DB88', border: 'none', color: '#7CB1FF' }}
              >
                Add Image
              </button>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
      <ImageConfirmModal
        isOpen={showConfirm}
        setIsOpen={setShowConfirm}
        closeMainModal={setIsOpen}
        caption={caption}
        setTextarea={setTextarea}
        callback={mutateEdit}
        disabled={disabled}
      />
    </Modal>
  );
}
