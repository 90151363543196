import React from 'react';
//import Human from '../../../Icons/human.png';
import Money from '../../../Icons/money12.svg';
import "./DonationCard.css";
import * as helpers from "../../../global/helpers"

export default function DonationCard({ donationDetails = null }) {
  let amountText = donationDetails !== null ? helpers.numberWithCommas(donationDetails.amountText) : ""
  let recurringTypeText = donationDetails !== null ? donationDetails.recurringTypeText : ""
  let recurringType = donationDetails !== null ? donationDetails.recurringType : ""
  let nextDonationAt = donationDetails !== null ? donationDetails.nextDonationAt : ""
  if (nextDonationAt !== "") {
    nextDonationAt = helpers.getDateFromUnix(nextDonationAt)
  }
  let createdAt = donationDetails !== null ? donationDetails.createdAt : ""
  if (createdAt !== "") {
    createdAt = helpers.getDateFromUnix(createdAt)
  }
  let isSubscriptionCancelled = donationDetails !== null ? donationDetails.isSubscriptionCancelled : null
  let subscriptionCancelledAt = donationDetails !== null && donationDetails.subscriptionCancelledAt !== null
    ? donationDetails.subscriptionCancelledAt
    : ""
  if (subscriptionCancelledAt !== "") {
    subscriptionCancelledAt = helpers.getDateFromUnix(subscriptionCancelledAt)
  }

  return (
    <div
      id="org_donation_item"
      className='px-md-5 px-3 py-4 bg-white rounded shadow d-flex justify-content-between mt-4'
      style={{
        height: "56px",
        background: "#FFFFFF"
      }}
    >
      {/*       {userType === 'admin' ? (
        <>
        <div className='d-flex align-items-center'>
        <img src={Human} alt='human' />
        <h1 className='ml-2 ml-md-4 tracking-normal text-normal text-md font-weight-semi mb-0'>
          Jenny Wilson
        </h1>
      </div>
      <div className='d-flex align-items-center'>
        <h1 className='mr-md-5 mr-2 tracking-normal text-normal text-md font-weight-semi mb-0'>$25</h1>
        <img src={Money} alt='money' />
      </div>
        </>
      ) : userType === 'contributor' ? (
        <> */}
      <div className='d-flex align-items-center'>
        <img src={Money} alt='money' className="mr-3 org_money_vector" />
        <h1 className='tracking-normal text-normal text-md font-weight-semi mb-0 org_money_value'>{amountText}</h1>
      </div>
      <div
        className='d-flex align-items-center'
      >
        {
          recurringType === null
            ? <>
              <span className='next_donation-span'>
                <p className="next_donation-p mr-2">Donated At:</p>
                <p className='next_donation_date mr-2'>
                  {createdAt}
                </p>
              </span>
            </>
            : <>
              {
                isSubscriptionCancelled
                  ? <>
                    <p className="next_donation-p mr-2" >Cancelled At:</p>
                    <p className='next_donation_date mr-2'>
                      {subscriptionCancelledAt}
                    </p>
                  </>
                  : <>
                    <p className="next_donation-p mr-2">Next Donation:</p>
                    <p className='next_donation_date mr-2'>
                      {nextDonationAt}
                    </p>
                  </>
              }

            </>

        }

        <p
          className='d-inline-block px-1 text-white text-xxs mx-1 mr-2 donation_status'
          style={{ backgroundColor: isSubscriptionCancelled ? "#FF7E7E" : "" }}
        >
          {recurringTypeText}
        </p>
      </div>
      {/* </>
      ) : ''} */}
    </div>
  );
}
