import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import UploadFile from '../../reusableComponents/SingleFileUploadComponent/SingleFileUploadComponent';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './RegisterOrganizationStep2.css';
import states from '../../../states';
import { supportingCauses } from '../../../global/helpers';

const RegisterOrganizationSecondStepSchema = yup.object().shape({
  aboutOrg: yup.string().required('About organization is required'),
  city: yup.string().required('City is required'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(/^\d{5}(-\d{4})?$/, 'Must match 12345 or 12345-1234'),
  streetAddress: yup.string().required('Street address is required'),
  federalTaxId: yup.string().required('Federal Tax ID is required'),
  supportingCauses: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .max(7, 'Supporting Causes can be 7 max')
    .min(1)
    .required('Supporting Causes are required'),
  stateSelection: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('State is required'),
  uploadFile: yup.string().required('501 (c) Document (PDF or TIFF)'),
  acceptTerms: yup
    .boolean()
    .required('Licence agreement must be accepted.')
    .oneOf([true], 'Licence agreement must be accepted.'),
});

const RegisterOrganizationStep2 = React.forwardRef((props, ref) => {
  const [supportingCausesOptions, setSupportingCausesOptions] = useState([]);

  useEffect(() => {
    getSupportingCauses();
  }, []);

  const getSupportingCauses = () => {
    supportingCauses().then((response) => {
      if (response !== undefined) {
        if (response.data) {
          let options = response.data.map((state) => {
            return {
              value: state.id,
              label: state.value,
            };
          });
          setSupportingCausesOptions(options);
        }
      }
    });
  };
  const statesOptions = states;

  const {
    formState: { errors },
    control,
    handleSubmit,
    getValues,
  } = useForm({
    resolver: yupResolver(RegisterOrganizationSecondStepSchema),
  });

  const supportingCausesStyles = {
    control: (styles) => {
      return {
        ...styles,
        borderColor: !errors.supportingCauses ? '' : 'red',
      };
    },
  };

  const stateSelectionStyles = {
    control: (styles) => {
      return {
        ...styles,
        borderColor: !errors.stateSelection ? '' : 'red !important',
      };
    },
  };

  console.log(errors);

  const onSubmit = (data) => {
    props.setRegisterOrgStep2Data(data);
  };

  if (props.currentStep !== 2) {
    return null;
  }

  return (
    <Container className='register_organization_step_two'>
      <Row className='justify-content-center align-items-center mr-0 ml-0'>
        <Form className='justify-content-center' onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={props.isFormDisabled}>
            <Col lg={12} className='mt-2'>
              <Row>
                <Col lg={5}>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      Supporting Cause(s)
                      <span className='required_field'> * </span>
                      <span className='max_number'>7 max</span>
                    </Form.Label>
                    <Controller
                      name='supportingCauses'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={supportingCausesOptions}
                          isMulti={true}
                          styles={supportingCausesStyles}
                          className='supportingCauseSelector'
                          maxMenuHeight={150}
                          isOptionDisabled={() =>
                            getValues('supportingCauses') !== undefined
                              ? getValues('supportingCauses').length >= 7
                              : false
                          }
                        />
                      )}
                    />
                    <p className='org_signup_error_step_2'>
                      {errors.supportingCauses?.message || errors.supportingCauses?.label.message}
                    </p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      About Organization<span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id='aboutOrg'
                          as='textarea'
                          className='about_organization'
                          name='aboutOrg'
                          style={{
                            borderColor: !errors.aboutOrg ? '' : 'red',
                          }}
                        />
                      )}
                      name='aboutOrg'
                      control={control}
                      defaultValue=''
                    />
                    {errors.aboutOrg && (
                      <p className='org_signup_error_step_2'>{errors.aboutOrg.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      State <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      name='stateSelection'
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          isSearchable={false}
                          options={statesOptions.map((state) => {
                            return {
                              value: state.value,
                              label: state.label,
                            };
                          })}
                          styles={stateSelectionStyles}
                        />
                      )}
                    />
                    <p className='org_signup_error_step_2'>{errors.stateSelection?.message}</p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      City <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id='city'
                          className='input_field'
                          name='city'
                          style={{
                            borderColor: !errors.city?.message ? '' : 'red',
                          }}
                        />
                      )}
                      name='city'
                      control={control}
                      defaultValue=''
                    />
                    {errors.city && (
                      <p className='org_signup_error_step_2'>{errors.city.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={2} className='w-100'></Col>
                <Col lg={5}>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      Street Address <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id='streetAddress'
                          className='input_field'
                          name='streetAddress'
                          style={{
                            borderColor: !errors.streetAddress?.message ? '' : 'red',
                          }}
                        />
                      )}
                      name='streetAddress'
                      control={control}
                      defaultValue=''
                    />
                    {errors.streetAddress && (
                      <p className='org_signup_error_step_2'>{errors.streetAddress.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      Zip Code <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id='zipCode'
                          className='input_field'
                          name='zipCode'
                          style={{
                            borderColor: !errors.zipCode?.message ? '' : 'red',
                          }}
                        />
                      )}
                      name='zipCode'
                      control={control}
                      defaultValue=''
                    />
                    {errors.zipCode && (
                      <p className='org_signup_error_step_2'>{errors.zipCode.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='reg_org_lbl'>
                      Federal Tax ID <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          id='federalTaxId'
                          className='input_field'
                          name='federalTaxId'
                          style={{
                            borderColor: !errors.federalTaxId?.message ? '' : 'red',
                          }}
                        />
                      )}
                      name='federalTaxId'
                      control={control}
                      defaultValue=''
                    />
                    {errors.federalTaxId && (
                      <p className='org_signup_error_step_2'>{errors.federalTaxId.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className='upload_file_form'>
                    <Form.Label className='reg_org_lbl'>
                      501 (c) Document (PDF or TIFF) <span className='required_field'> *</span>
                    </Form.Label>
                    <Controller
                      render={({ field }) => (
                        <UploadFile
                          field={field}
                          errors={errors}
                          ref={ref}
                          accept='application/pdf,image/tiff'
                        />
                      )}
                      name='uploadFile'
                      control={control}
                      defaultValue=''
                    />
                    {errors.uploadFile && (
                      <p className='file_upload_error'>{errors.uploadFile.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group id='licence-div'>
                    <Row className='d-flex'>
                      <Controller
                        render={({ field }) => (
                          <Form.Check
                            {...field}
                            name='acceptTerms'
                            type='checkbox'
                            label='I agree to'
                            id='agree_checkbox'
                          />
                        )}
                        name='acceptTerms'
                        control={control}
                      />
                      <span className='end_user ml-0 ml-md-3 mt-2'>End User Licence Agreement</span>
                    </Row>
                    <p className='org_signup_error_step_2'>{errors.acceptTerms?.message}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Button type='submit' color='primary' className='register_organization_button'>
              Register
            </Button>
          </fieldset>
        </Form>
      </Row>
    </Container>
  );
});

export default RegisterOrganizationStep2;
