import React from 'react';
import OrganizationAdmin from '../pages/SuperAdmin/OrganizationsAdmin/index';

const Organizations = () => {
    return (
        <OrganizationAdmin />
    )
}

export default Organizations;
