import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ic_top_bg from '../../../../images/ic_top_bg.svg';
import ic_close from '../../../../images/ic_close.svg';
import hand_vector from '../../../../images/Hand.svg';
import './DonateSupport.css';
import { useStore } from '../../../../donationStore';

import { useParams } from 'react-router';

export default function DonateSupport({
  isSupport,
  setIsSupport,
  donationDuration,
  amount,
  data,
  selectedItem,
}) {
  const donationSummary = useStore((state) => state.donationSummary);

  const { orgId, projectId } = useParams();

  let donationLink;

  if (donationSummary.donationCategory === 'project') {
    if (selectedItem) {
      donationLink = `/projects/${donationSummary.donatedTo.title}/${selectedItem.id}/donation_summary/${donationSummary.donationId}`;
    } else {
      donationLink = `/projects/${donationSummary.donatedTo.title}/${projectId}/donation_summary/${donationSummary.donationId}`;
    }
  }

  if (donationSummary.donationCategory === 'org') {
    if (selectedItem) {
      donationLink = `/organizations/${donationSummary.donatedTo.title}/${selectedItem.id}/donation_summary/${donationSummary.donationId}`;
    } else {
      donationLink = `/organizations/${donationSummary.donatedTo.title}/${orgId}/donation_summary/${donationSummary.donationId}`;
    }
  }
  console.log('selectedItem', selectedItem);

  return (
    <Modal show={isSupport} onHide={setIsSupport} className='donate_support_modal modal-center'>
      <div style={{ marginLeft: 'auto', width: '66px', height: '68px' }}>
        <img src={ic_top_bg} alt='' />
        <img
          src={ic_close}
          alt=''
          className='confirmation-modal-close'
          onClick={(e) => {
            e.preventDefault();
            setIsSupport(false);
          }}
        />
      </div>
      <Modal.Body>
        <h3 className='supporting_title'>Thank you for supporting!</h3>
        <div style={{ textAlign: 'center' }}>
          <img src={hand_vector} alt='' />
        </div>
        <p className='supporting_txt'>
          You have chosen to donate{' '}
          <span>
            ${amount} {donationDuration}
          </span>{' '}
          to
        </p>
        {data ? (
          <h5 className='supporting_to-h5'>{data.name ? data.name : data.byOrg}</h5>
        ) : (
          <h5 className='supporting_to-h5'>{selectedItem.title}</h5>
        )}
        <div
          style={{
            textAlign: 'center',
            marginTop: 30,
          }}
        >
          <Link className='btn btn-primary' id='support_donation_summary-a' to={donationLink}>
            View donation summary
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}
