import React from 'react';
import { Row } from 'react-bootstrap';
import { BASE_URL } from '../../constants/Constants';
import { trending_orgs } from '../../constants/Keys';
import useFetchTrending from '../../hooks/useFetchTrending';
import OrganizationItem from '../reusableComponents/OrganizationItem/OrganizationItem';
import SectionTitle from '../reusableComponents/SectionTitle/SectionTitle';
import './TrendingOrganizationsSection.css';

const TrendingOrganizationsSection = (props) => {
  const url = `${BASE_URL}/trending/orgs?limit=8`;

  const { data } = useFetchTrending(trending_orgs, url);

  return (
    <section id='TrendingOrganizationsSection' className='mt-5 mb-5'>
      <div className='container'>
        <div className='col-lg-12'>
          <div className='row mb-2'>
            <div className='col-lg-8'>
              <SectionTitle title='trending ORGANIZATIONS' />
            </div>
          </div>
          <Row className='organization-opening-row-container'>
            {data && data.data.length === 0 ? (
              <h1 style={{ color: '#67DB88' }} className=' tracking-normal text-normal my-5'>
                No Trending Organizations...
              </h1>
            ) : (
              data &&
              data.data.map((item) => {
                return (
                  <OrganizationItem
                    key={'TrendingOrganizationsSection-item-' + item.id}
                    image={`${BASE_URL}/media/${item.image}`}
                    title={item.name}
                    rating={item.rating}
                    tags={item.visibleTags}
                    isOpeningSection={true}
                  />
                );
              })
            )}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default TrendingOrganizationsSection;
