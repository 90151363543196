import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Review from "../Review/Review";
import "./ReviewsList.css";

function ReviewsList(props) {

    return (
        <div className="org-reviews-section-container col-lg-12 ">
            <InfiniteScroll
                className="pt-3 pb-3"
                dataLength={props.reviews.length}
                next={props.getReviews}
                hasMore={props.hasMore}
                loader={<h4 className="loading-label">Loading...</h4>}
                scrollableTarget="scrollableDiv"
            >
                {
                    props.reviews.map((item, index) => {
                        let marginTop = index > 0 ? "mt-3" : ""
                        return (<div key={"review-" + index}
                            className={"mx-auto " + marginTop}
                        >
                            <Review review={item} openDeleteModal={props.openDeleteModal} />
                        </div>)
                    })
                }
            </InfiniteScroll>
        </div>
    )
}

export default ReviewsList;