import { useMutation, useQueryClient } from 'react-query';
import { PaymentsKey } from '../constants/Keys';

import { postImage as postPayment } from '../global/helpers';

export const usePostPayment = (url) => {
  const queryClient = useQueryClient();

  const handlePost = async (data) => {
    await postPayment(url, data);
  };

  return useMutation(handlePost, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(PaymentsKey);
    },
  });
};
