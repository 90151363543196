import React, { Component } from 'react'
import statIcon from '../../../images/statIcon.svg'
import { Tooltip, OverlayTrigger  } from 'react-bootstrap';
import icon from '../../../images/blueInformationIcon.svg';
import './TrendingProjectsOrgsStat.css'

export default class TrendingProjectsOrgsStat extends Component {

    render() {
        const projectsTooltip = (props) => (
            <Tooltip className="projects_tooltip" {...props}>
                Total number of projects you have supported
            </Tooltip>
        );
    
        const organizationsTooltip = (props) => (
            <Tooltip className="organizations_tooltip" {...props}>
                Total number of organizations you have supported
            </Tooltip>
        );

        return (
            <div className="stat-section">
                <img src={statIcon} alt='' className='stat-icon' />      
                <h2 className='stat-projects'>Projects</h2>
                <div className='projects-info'>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={projectsTooltip}
                    >
                        <img src={icon} width={15} height={15} alt=''/>
                    </OverlayTrigger>
                </div>
                <span className='projects-input'>{this.props.projects}</span>

                <h2 className='stat-organizations'>Organizations</h2>
                <div className='organizations-info'>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={organizationsTooltip}
                    >
                        <img src={icon} width={15} height={15} alt=''/>
                    </OverlayTrigger>
                </div>
                <span className='organizations-input'>{this.props.organizations}</span>

                <h2 className='stat-total-amount'>My Donated Amount</h2>  
                <span className='total-amount-input'>${this.props.amount}</span>
            </div>
        )
    }
}
