import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ThreeDots } from 'react-bootstrap-icons';
import Green from '../../../Icons/green2.svg';
import Disable from '../../../Icons/disable.svg';
import Activate from '../../../Icons/activate.svg';
import AcceptDropdown from '../../../Icons/accept-dropdown.svg';
import Accept from '../../../Icons/accept.svg';
import Reject from '../../../Icons/reject.svg';
import Money from '../../../Icons/money2.svg';
import Mail from '../../../Icons/mail.svg';
import Location from '../../../Icons/location.svg';
import Phone from '../../../Icons/phone.svg';
import WriteYourMessage from './WriteYourMessage';
import imgDefault from '../../../images/img-default.svg';
import starLogo from '../../../images/star.svg';
import share from '../../../images/share.svg';
import * as Constants from '../../../constants/Constants';
import * as helpers from '../../../global/helpers';

import './InfoCard.css';
import ConfirmationModal from '../../reusableComponents/ConfirmationModal/ConfirmationModal';

import { Link, useParams } from 'react-router-dom';

export default function InfoCard({
  orgDetails = null,
  acceptRejectOrg,
  enableDisableOrg,
}) {
  const userType = helpers.getUserType()
  const [show, setShow] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  let name = orgDetails !== null ? orgDetails.name : '';
  let email = orgDetails !== null ? orgDetails.email : '';
  let fullPhoneNumber = orgDetails !== null ? orgDetails.fullPhoneNumber : '';
  let totalAmount = orgDetails !== null ? orgDetails.totalAmount : '';

  let rating =
    orgDetails !== null && orgDetails.rating && orgDetails.rating > 0 ? orgDetails.rating : '-';
  // let address = helpers.getAddress(orgDetails);

  let imageSrc = imgDefault;
  if (
    orgDetails !== null &&
    orgDetails.image !== undefined &&
    orgDetails.image !== null &&
    orgDetails.image !== '' &&
    orgDetails.image !== 'guid'
  ) {
    imageSrc =
      orgDetails !== null ? Constants.BASE_URL + '/media/' + orgDetails.image + '/200' : imgDefault;
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
  };

  let status = '';
  let statusTextColor = '';
  let statusBgColor = '';

  if (userType === Constants.USER_TYPE_ADMIN && orgDetails !== null) {
    status = helpers.getOrgStatus(orgDetails.isActive, orgDetails.isApproved);

    statusTextColor =
      status === 'pending'
        ? '#FFB627'
        : status === 'active'
          ? '#7CB1FF'
          : status === 'disabled'
            ? '#FFFFFF'
            : '#FF7E7E';

    statusBgColor = status === 'disabled' ? '#FF7E7E' : '#FFFFFF';
  }

  const acceptRejectOrgLocal = (isAccept) => {
    acceptRejectOrg(isAccept);
  };

  const enableDisableOrgLocal = () => {
    let isDisable = orgDetails.isActive;
    enableDisableOrg(isDisable, setShow);
  };

  const { orgName, id } = useParams();
  // console.log('org name: ', orgName)
  // console.log('or id: ', id);

  console.log("orgDetails: ", orgDetails)
  return (
    <div
      className={
        userType === Constants.USER_TYPE_ADMIN
          ? 'relative p-3 bg-white shadow rounded admin_specific_org_info_card'
          : userType === Constants.USER_TYPE_CONTRIB
            ? 'relative p-3 bg-white shadow rounded contrib_specific_org_info_card'
            : 'relative p-3 bg-white shadow rounded admin_specific_org_info_card'
      }
    >
      <img
        src={Green}
        alt='green'
        className='absolute specific_org_green_vector'
        style={{ top: '0px', left: '0px', zIndex: '0' }}
      />
      {userType === Constants.USER_TYPE_ADMIN && status !== '' ? (
        status === 'pending' ? (
          <div className='mt-4' />
        ) : status === 'rejected' ? (
          <div className='text-right'>
            <Dropdown className='ml-2'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
              >
                <ThreeDots style={{ color: '#7CB1FF', fontSize: '24px' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
                <Dropdown.Item onClick={() => acceptRejectOrgLocal(true)}>
                  <span>
                    <img src={AcceptDropdown} alt='disable' className='mr-2' />
                  </span>
                  Accept
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : status === 'disabled' ? (
          <div className='text-right'>
            <Dropdown className='ml-2'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
              >
                <ThreeDots style={{ color: '#7CB1FF', fontSize: '24px' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
                <Dropdown.Item onClick={() => setShow(true)}>
                  <span>
                    <img src={Activate} alt='disable' className='mr-2' />
                  </span>
                  Activate User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className='text-right'>
            <Dropdown className='ml-2'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
              >
                <ThreeDots style={{ color: '#7CB1FF', fontSize: '24px' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
                <Dropdown.Item onClick={() => setShow(true)}>
                  <span>
                    <img src={Disable} alt='disable' className='mr-2' />
                  </span>
                  Disable User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      ) : userType === Constants.USER_TYPE_CONTRIB ? (
        ''
      ) : (
        ''
      )}
      <div className='text-center d-sm-flex d-md-block'>
        <div className='specific_org_info_banner-div'>
          <img
            src={imageSrc}
            alt='Organization Profile'
            className='relative specific_org_info_banner '
            style={{ zIndex: '1', borderRadius: '50%', border: '1px solid #67DB88' }}
            onError={addDefaultSrc}
          />
        </div>
        <div className='ml-sm-5 ml-md-0 specific_org_name-div'>
          <h1 className='text-capitalize text-md mt-5 mb-4 tracking-normal'>{name}</h1>
          {userType === Constants.USER_TYPE_ADMIN ? (
            <span
              className='px-4 py-1 mr-1 rounded text-capitalize'
              style={{
                border: '2px solid ' + statusTextColor,
                color: statusTextColor,
                backgroundColor: `${statusBgColor}`,
              }}
            >
              {status}
            </span>
          ) : userType === Constants.USER_TYPE_CONTRIB ? (
            <div className='org_rate'>
              <img className='star_logo' src={starLogo} alt='' />
              <span className='rate_value'>{rating}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='px-5 mt-5 specific_org_contact-div'>
        <h1
          className='text-capitalize text-sm mb-4 contact-h1'
          style={{ color: '#90AD98', fontWeight: 'normal' }}
        >
          Contact
        </h1>
        <div className='d-flex align-items-center mb-4 org_contact_details'>
          <img src={Mail} alt='mail' />
          <h1 className='tracking-normal font-semi text-normal text-sm ml-2 mb-0'>{email}</h1>
        </div>
        <div className='d-flex align-items-center mb-4 org_contact_details'>
          <img src={Phone} alt='phone' />
          <h1 className='tracking-normal font-semi text-normal text-sm ml-2 mb-0'>
            {fullPhoneNumber}
          </h1>
        </div>

        {orgDetails ? (
          <div className='d-flex align-items-center mb-3 org_contact_details'>
            <img src={Location} alt='location' />
            <p className='text-normal tracking-normal text-sm ml-3 mb-0'>
              <span className='d-block font-semi'>{orgDetails.streetAddress}</span>
              <span className='font-semi'>
                {orgDetails.city}, {orgDetails.state.abbrev} {orgDetails.zipCode}
              </span>
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      {
        userType === Constants.USER_TYPE_ADMIN ? (
          status === 'pending' ? (
            <div className='d-flex mb-4 mt-4' style={{ justifyContent: 'space-evenly' }}>
              <button
                className='reject-org-btn'
                onClick={(e) => {
                  e.preventDefault();
                  acceptRejectOrgLocal(false);
                }}
              >
                <span>
                  <img src={Reject} alt='reject' className='mr-2' />
                </span>
                Reject
              </button>
              <button
                className='reject-org-btn accept-org-btn'
                onClick={(e) => {
                  e.preventDefault();
                  acceptRejectOrgLocal(true);
                }}
              >
                <span>
                  <img src={Accept} alt='accept' className='mr-2' />
                </span>
                Accept
              </button>
            </div>
          ) : status !== '' && status !== 'rejected' && totalAmount !== '' ? (
            <div className='d-flex px-5 mt-5 align-items-center specific_org_total_amount-div'>
              <div>
                <img alt='money' src={Money} />
              </div>
              <div className='ml-5'>
                <h1 className='tracking-normal text-sm text-capitalize mb-3 text-secondary'>
                  Total Amount
                </h1>
                <h1 className='tracking-normal text-sm text-capitalize mb-0'>
                  ${helpers.numberWithCommas(totalAmount)}
                </h1>
              </div>
            </div>
          ) : null
        ) : userType === Constants.USER_TYPE_CONTRIB ? (
          <div className='text-center specific_org_donate-div'>
            <Link
              className='btn btn-primary'
              id='sp_donate-btn'
              to={`/organizations/${orgName}/${id}/donation`}
            >
              Donate
            </Link>
            <div className='btn-group align-items-center con_share-grp d-none' role='group'>
              <button
                type='button'
                className='btn btn-primary mr-2'
                id='sp_contact-btn'
                onClick={() => setShowContactModal(true)}
              >
                Contact
              </button>
              <button type='button' className='btn btn-primary' id='sp_share-btn'>
                <img src={share} id='share-icon' alt='' />
                <p>Share</p>
              </button>
            </div>
          </div>
        ) : totalAmount !== ''
          ? (
            <div className='d-flex px-5 mt-5 align-items-center specific_org_total_amount-div'>
              <div>
                <img alt='money' src={Money} />
              </div>
              <div className='ml-5'>
                <h1 className='tracking-normal text-sm text-capitalize mb-3 text-secondary'>
                  Total Amount
                </h1>
                <h1 className='tracking-normal text-sm text-capitalize mb-0'>${helpers.numberWithCommas(totalAmount)}</h1>
              </div>
            </div>
          )
          : null}
      {userType === Constants.USER_TYPE_ADMIN && orgDetails !== null ? (
        orgDetails.isActive ? (
          <ConfirmationModal
            isOpen={show}
            setIsOpen={setShow}
            title='Disable User'
            description={
              "<span>You are disabling <strong class='font-weight-bold'>" +
              name +
              '</strong>. Disabled account will not be able to log in anymore.<span>'
            }
            cancelBtnTitle='Keep'
            submitBtnTitle='Disable'
            submitFunc={enableDisableOrgLocal}
          />
        ) : (
          <ConfirmationModal
            isOpen={show}
            setIsOpen={setShow}
            title='Activate User'
            description='You are activating the account of this user. They will get access to their account.'
            cancelBtnTitle='Keep'
            submitBtnTitle='Activate'
            submitFunc={enableDisableOrgLocal}
            type='confirmation-primary'
          />
        )
      ) : null}
      {/* <DisableUserModal isOpen={show} setIsOpen={setShow} /> */}
      <WriteYourMessage isContact={showContactModal} setIsContact={setShowContactModal} />
    </div>
  );
}
