import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import MultiStepProgressBar from '../../../components/reusableComponents/MultiStepProgressBar/MultiStepProgressBar';
import RecurringDonationsStep1 from './RecurringDonationsStep1';
import RecurringDonationsStep2 from './RecurringDonationsStep2';
import './RecurringDonations.css';
import { useAutoComplete } from '../../../hooks/useAutoComplete';
import * as Constants from '../../../constants/Constants';
import amountLogo from '../../../images/amount-logo.svg';

export const SelectedDonationItem = (props) => {
  return (
    <div className='btn-container btn-container-selected'>
      <img
        src={props.image ? Constants.MEDIA + '/' + props.image + '/100' : amountLogo}
        alt={props.description}
        className={props.type === 'project' ? 'project-img' : 'organization-img'}
      />
      <div>
        <div className='type type-selected'>
          {props.type === 'project' ? 'Project' : 'Organization'}
        </div>
        <div className='title title-selected'>{props.title}</div>
        {props.type === 'project' ? (
          <div className='created-by'>
            <span className='created-by-title'>Created by </span>
            <span className='created-by-text'>{props.by}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const RecurringDonations = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { data } = useAutoComplete();

  const [selectedItem, setSelectedItem] = useState(null);
  const [amount, setAmount] = useState(10);
  const [recurring, setRecurring] = useState(!props.isRecurringDonations ? undefined : 'day');

  const [dataById, setDataById] = useState('');

  const nextBtn = () => {
    setCurrentStep(2);
  };
  const backBtn = () => {
    setCurrentStep(1);
  };

  return (
    <div className='recurring-container'>
      <div className='recurring-top-bg' />
      <div className='recurring-main-inner-container'>
        <MultiStepProgressBar className='RSPBprogressBar' currentStep={currentStep} />
        <Col className='recurring-inner-container' lg={4} md={4} sm={10} xs={10}>
          {currentStep === 1 ? (
            <RecurringDonationsStep1
              nextBtn={nextBtn}
              items={data}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setAmount={setAmount}
              amount={amount}
              recurring={recurring}
              setRecurring={setRecurring}
              data={dataById}
              setData={setDataById}
            />
          ) : currentStep === 2 ? (
            <RecurringDonationsStep2
              backBtn={backBtn}
              selectedItem={selectedItem}
              amount={amount}
              recurring={recurring}
              data={dataById}
            />
          ) : (
            'summary'
          )}
        </Col>
      </div>
    </div>
  );
};

export default RecurringDonations;
