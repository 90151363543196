import React from 'react';
import './ConfirmationModal.css';
import { Modal, Spinner } from 'react-bootstrap';
import ic_top_bg from '../../../images/ic_top_bg.svg';
import ic_close from '../../../images/ic_close.svg';

export default function ConfirmationModal({
  title,
  description,
  isOpen,
  setIsOpen,
  submitFunc,
  cancelBtnTitle,
  submitBtnTitle,
  type = 'confirmation-danger', //confirmation-danger | confirmation-primary
  spinnerLoad = false,
  spinnerColor,
}) {
  return (
    <Modal className={'confirmation-modal ' + type} show={isOpen} onHide={setIsOpen} centered>
      <div style={{ display: 'flex' }}>
        <div className='confirmation-modal-title'>{title}</div>
        <div style={{ marginLeft: 'auto', width: '66px', height: '68px' }}>
          <img src={ic_top_bg} alt='' />
          <img
            src={ic_close}
            alt=''
            className='confirmation-modal-close'
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          />
        </div>
      </div>
      <div style={{ marginLeft: '27px', marginRight: '27px' }}>
        <Modal.Body className='px-0 pb-0 mb-0 pt-0'>
          <div
            className='d-flex'
            style={{ letterSpacing: '0px' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div
            className='d-flex mx-auto'
            style={{ justifyContent: 'space-evenly', marginTop: '27px' }}
          >
            <button
              style={{ opacity: `${spinnerLoad ? 0.4 : 1}` }}
              disabled={spinnerLoad}
              className='confirmation-modal-keep'
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              {cancelBtnTitle}
            </button>
            {spinnerLoad ? (
              <div className='text-center'>
                <Spinner animation='border' role='status' style={{ color: spinnerColor }} />
              </div>
            ) : (
              <button
                className='confirmation-modal-submit'
                onClick={(e) => {
                  e.preventDefault();
                  submitFunc();
                }}
              >
                {submitBtnTitle}
              </button>
            )}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
