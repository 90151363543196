/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Dot, ThreeDots } from 'react-bootstrap-icons';
import Remove from '../../../Icons/remove.svg';
import StarFill from '../../../Icons/star-fill.svg';
import StarEmpty from '../../../Icons/star-empty.svg';
import * as helpers from '../../../global/helpers';
import * as Constants from '../../../constants/Constants';
import imgDefault from '../../../images/img-default.svg';
import ConfirmationModal from '../../reusableComponents/ConfirmationModal/ConfirmationModal';

import './Review.css';
import { useQueryClient } from 'react-query';
import { reviews } from '../../../constants/Keys';

export default function Review({ data = null, updateAfterDelete, userType }) {
  const [show, setShow] = useState(false);
  let id = data !== null && data.id !== null ? data.id : '';
  let reviewer = data !== null && data.reviewer !== null ? data.reviewer : '';
  let time =
    data !== null && data.createdAtUnix !== null ? helpers.getTimeFromUnix(data.createdAtUnix) : '';
  let date =
    data !== null && data.createdAtUnix !== null ? helpers.getDateFromUnix(data.createdAtUnix) : '';
  let value = data !== null && data.value !== null ? data.value : 0;
  let feedback = data !== null && data.feedback !== null ? data.feedback : '';

  let imageSrc = imgDefault;
  if (
    data !== null &&
    data.image !== undefined &&
    data.image !== null &&
    data.image !== '' &&
    data.image !== 'guid'
  ) {
    imageSrc = data !== null ? Constants.BASE_URL + '/media/' + data.image + '/200' : imgDefault;
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
  };
  const queryClient = useQueryClient();
  function deleteReview() {
    helpers.deleteReview(id).then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          helpers.successNotify('Review Removed');
          updateAfterDelete(id);
          queryClient.invalidateQueries(reviews);
          setShow(false);
        }
      }
    });
  }

  return (
    <>
      <div className='bg-white rounded p-4 shadow my-1 '>
        <div className='d-flex justify-content-between align-items-start '>
          <div className='d-flex'>
            <div>
              <img
                style={{
                  width: '70px',
                  height: '70px',
                  border: '1px solid #67DB88',
                  borderRadius: '50%',
                }}
                src={imageSrc}
                alt='human'
                onError={addDefaultSrc}
              />
            </div>
            <div className='ml-5'>
              <h1 className='tracking-normal text-normal text-md font-weight-semi mb-2'>
                {reviewer}
              </h1>

              <p className='font-weight-normal mb-2' style={{ fontSize: '12px' }}>
                <span>
                  {time} <Dot />
                </span>
                <span> {date}</span>
              </p>
              <div>
                {[...Array(value)].map(() => {
                  return <img src={StarFill} alt='star' />;
                })}

                {[...Array(5 - value)].map(() => {
                  return <img src={StarEmpty} alt='star' />;
                })}
              </div>
            </div>
          </div>
          {userType === 'admin' ? (
            <div className='ml-md-5 ml-sm-4 cursor-pointer relative ' style={{ marginTop: '-8px' }}>
              <Dropdown drop='end'>
                <Dropdown.Toggle
                  variant='light'
                  id='dropdown-basic'
                  style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
                >
                  <ThreeDots style={{ color: '#7CB1FF', fontSize: '18px' }} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ border: '1px solid #7CB1FF' }}
                  className='update-profile-dropdown '
                >
                  <Dropdown.Item style={{ color: '#FF7E7E' }} onClick={() => setShow(true)}>
                    <span>
                      <img src={Remove} alt='remove' className='mr-2' />
                    </span>
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : userType === 'contributor' && data.isMyReview === true ? (
            <Dropdown drop='end'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
              >
                <ThreeDots style={{ color: '#7CB1FF', fontSize: '18px' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
                <Dropdown.Item style={{ color: '#FF7E7E' }} onClick={() => setShow(true)}>
                  <span>
                    <img src={Remove} alt='remove' className='mr-2' />
                  </span>
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ''
          )}
        </div>
        <div className='mt-3'>
          <p
            className='mb-0 text-dark tracking-normal text-sm font-weight-normal'
            style={{
              wordBreak: 'break-all'
            }}
          >{feedback}</p>
        </div>

        <ConfirmationModal
          title='Remove Review'
          description={
            userType === 'admin'
              ? 'You are removing a review left by a user. This will permanently delete it & cannot be undone.'
              : userType === 'contributor'
              ? 'You are removing your review. Are you sure?'
              : ''
          }
          isOpen={show}
          setIsOpen={setShow}
          submitFunc={deleteReview}
          cancelBtnTitle={userType === 'admin' ? 'Keep' : userType === 'contributor' ? 'No' : ''}
          submitBtnTitle={userType === 'admin' ? 'Remove' : userType === 'contributor' ? 'Yes' : ''}
          type='confirmation-danger'
        />
      </div>
    </>
  );
}
