import React from 'react';
import dotenv from "dotenv";
import "./OpeningHome.css";
import '../App.js'
import OpeningSection from '../components/OpeningSection/OpeningSec'
import TrendingOrganizationsSection from '../components/TrendingOrganizationsSection/TrendingOrganizationsSection'
import TrendingProjectsSection from '../components/TrendingProjectsSection/TrendingProjectsSection'
import InfoSection from '../components/InfoSection/InfoSection'
import ComingSoon from './ComingSoon/ComingSoon';
import Greenlogo from "../images/Greenlogo.png";

const Home = () => {

    dotenv.config()
    const comingSoonEnv = process.env.REACT_APP_COMING_SOON;
    
    let orgs = [
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        },
        {
            image: Greenlogo, title: "title", rating: "4.5", tags: "tag1"
        }
    ]

    let projects = [{
        title: "Save & protect baby sea turtles",
        author: "Humane Society",
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,...",
        amount: "1,234",
        totalamount: "12,000",
        endDate: "07-07-2021"
    },
    {
        title: "Save & protect baby sea turtles",
        author: "Humane Society",
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,...",
        amount: "1,234",
        totalamount: "12,000",
        endDate: "07-07-2021"
    },
    {
        title: "Save & protect baby sea turtles",
        author: "Humane Society",
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,...",
        amount: "1,234",
        totalamount: "12,000",
        endDate: "07-07-2021"
    },
    {
        title: "Save & protect baby sea turtles",
        author: "Humane Society",
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in dolor odio libero orci. Etiam massa,...",
        amount: "1,234",
        totalamount: "12,000",
        endDate: "07-07-2021"
    }]

    if(comingSoonEnv === 'true') {
        return <ComingSoon/>
    }
    return (
        <>
            <OpeningSection />
            <div className="landing-section-2-3">
                <TrendingOrganizationsSection orgs={orgs} />
                <TrendingProjectsSection projects={projects} max={4} />
            </div>
            <InfoSection />
        </>
    )
}

export default Home;
