import React from 'react';
import { Link } from 'react-router-dom';
import dp from '../../../images/projectItemImage.png';
import urlattachment from '../../../images/urlAttachment.svg';
import coin from '../../../images/projectCoinIcon.svg';
import shareIcon from '../../../images/shareIcon.svg';
import icon from '../../../images/blueInformationIcon.svg';
import { Tooltip, OverlayTrigger  } from 'react-bootstrap';
import './ProjectInfo.css';

const ProjectInfo = (props) => {
    const [style, setStyle] = React.useState({});

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: (`${props.amount}`*282/`${props.totalamount}`),
        }
        setStyle(newStyle);
    }, 200);

    const end = new Date(props.endDate)
    const today = new Date()
    const endDateTooltip = (props) => (
        <Tooltip className="end_date-tooltip project_info_end_date" {...props}>
            Ends at 12 AM GMT (midnight)
        </Tooltip>
    );
    
    return (
        <form className='Info-project'>
            <img src={dp} alt='' className='Info-image' /> 
            <p className='Info-project-title-section'>{props.title}</p>
            <img src={coin} alt='' className='Info-coin' />
            <div className='Info-amount-rasied-section'>
                <p id='project-raised-amount'>
                    ${props.amount} raised <span id='Info-of-amount'> of {props.totalamount}</span>
                </p>
            </div>
            <div className="progress">
                <div className="progress-done" style={style}></div>
		    </div>
            <div className='url-section'>
                <img src={urlattachment} alt='' className='url-icon' />
                <Link to ='' id='url-item'>www.projecturl.com</Link> 
            </div>
            <div className={'Info-date-section'}>
                <p id='project-ends-on-h'>Ends on</p>
                <p id={(end > today) ? 'Info-end-date' : 'Info-date-ended'}>{props.endDate}</p>
                <div  id={(end > today) ? 'icon-on' : 'icon-off'} >
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={endDateTooltip}
                    >
                        <img src={icon} width={props.size} height={props.size} alt=''/>
                    </OverlayTrigger>
                </div>
            </div>
            <div className={'Info-createdate-section'}>
                <p id='Info-created-on-h'>Created on</p>
                <p id='Info-create-date'>{props.createdDate}</p>
            </div>
            <button id='Info-donate-btn'>DONATE</button>
            <button id='Info-share-btn'><img src={shareIcon} alt='' id='share-icon' />Share</button>
        </form>
    )
}

export default ProjectInfo;
