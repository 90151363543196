import { NavLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './SubMenu.css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actionTypes';

const SubMenu = (props) => {
  const location = useLocation();
  const [subnav, setSubnav] = useState(false);
  const [imageClass, setImageClass] = useState('');
  const [prevPathName, setPathName] = useState(location.pathname.toLowerCase().trim());

  if (location.pathname.toLowerCase().trim() !== prevPathName) {
    setPathName(location.pathname.toLowerCase().trim());
    props.setActiveId(props.item.title + '-' + Math.random());
  }

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  useEffect(() => {
    getImageClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getImageClass(isVisited = false) {
    if (!isVisited) {
      switch (props.item.title) {
        case 'Home':
          setImageClass('ic-home');
          break;
        case 'Organizations':
          setImageClass('ic-org');
          break;
        case 'Projects':
          setImageClass('ic-projects');
          break;
        case 'Donate':
          setImageClass('ic-donate');
          break;
        case 'Account':
          setImageClass('ic-account');
          break;
        case 'Stats':
          setImageClass('ic-stats');
          break;
        case 'Contributors':
          setImageClass('ic-contrib');
          break;
        case 'Updates':
          setImageClass('ic-updates');
          break;
        case 'Reviews':
          setImageClass('ic-reviews');
          break;
        case 'Donations':
          setImageClass('ic-donations');
          break;
        default:
          break;
      }
    } else {
      let imageClassVisited = imageClass.endsWith('-visited')
        ? imageClass
        : imageClass.concat('-visited');
      setImageClass(imageClassVisited);
    }
  }
  if (window.location.pathname.endsWith(props.item.path)) {
    props.setTitle(props.item.title);
  }

  let navLinkId = window.location.pathname.endsWith(props.item.path)
    ? props.item.subNav === undefined
      ? 'activelink'
      : 'link'
    : 'link';

  return (
    <>
      <NavLink
        id={navLinkId}
        to={props.item.path}
        onClick={() => {
          if (props.item.subNav) {
            showSubnav();
          }
        }}
        onMouseOver={(e) => {
          e.preventDefault();
          getImageClass(true);
        }}
        onMouseLeave={(e) => {
          e.preventDefault();
          getImageClass(false);
        }}
      >
        <div id='icon'>
          {/* {item.icon} */}
          <div style={{ width: '22px' }}>
            <div
              className={'ic-menu '
                .concat(imageClass)
                .concat(
                  window.location.pathname.endsWith(props.item.path)
                    ? props.item.subNav === undefined
                      ? imageClass.endsWith('-visited')
                        ? ''
                        : '-visited'
                      : ''
                    : ''
                )}
              alt=''
            />
          </div>
        </div>
        <span id='title'>{props.item.title}</span>

        <div id='sub-icon'>
          {props.item.subNav && subnav
            ? props.item.iconOpened
            : props.item.subNav
            ? props.item.iconClosed
            : null}
        </div>
      </NavLink>
      {subnav &&
        props.item.subNav.map((item, index) => {
          return (
            <NavLink
              id={window.location.pathname.endsWith(item.path) ? 'active-droplink' : 'droplink'}
              to={item.path}
              key={index}
            >
              <div id='sub-title'>{item.title}</div>
            </NavLink>
          );
        })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    title: state.title,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => {
      dispatch({
        type: actions.SET_TITLE,
        payload: {
          title: title,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
