import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import './AddPaymentMethodStep2.css';
import './AddPaymentMethod.css';
import DatePicker from 'react-date-picker';
import ic_calendar from '../../../images/ic_calendar.png';
//import ic_credit_card from "../../../images/ic_credit_card.png";

export default function AddPaymentMethodFunctional(props) {
  const { mutateAsync } = props;
  const [validated, setValidated] = useState(false);
  const [validityDate, setValidtyDate] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  //const [expirationDate, setExpirationDate] = useState("")
  const [cvv, setCvv] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  //   const [checkedId /* setCheckedId */] = useState(2);

  const addPaymentMethod = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      let validityYearLocal = validityDate.getFullYear().toString();
      //   let validityDateLocal = validityDate.getMonth() + 1 + '/' + validityYearLocal.substring(2, 4);
      let data = {
        cardNo: cardNumber,
        cardExpMonth: validityDate.getMonth() + 1,
        cardExpYear: validityYearLocal,
        cardCVC: cvv,
      };
      await mutateAsync(data);
      props.onClose();

      //   addPaymentMethodLocal(cardNumber, validityDateLocal, cardHolderName);
    }
    setValidated(true);
    e.preventDefault();
  };

  //   const addPaymentMethodLocal = (cardNumber, validityDate, cardHolderName) => {
  //     props.addPaymentMethod(cardNumber, validityDate, checkedId, cardHolderName, props.onClose);
  //   };

  let dateDisplay = validityDate === null ? 'date-display' : '';

  return (
    <div
      className={'add-payment-type-container add-payment-type-container-step-2' /* + step2Class*/}
    >
      <div className='add-payment-type-inner-2'>
        <div className='add-payment-type-title' style={{ marginTop: '11px' }}>
          Credit Card info
        </div>
        <Form
          noValidate
          validated={validated}
          style={{ marginTop: '10px', marginRight: '' }}
          onSubmit={addPaymentMethod}
        >
          <Form.Group className='cc-number' controlId='formGroupCardNumber'>
            <Form.Label className='cc-input-title'>Card number</Form.Label>
            <Form.Control
              className='cc-input'
              required
              autocomplete='cc-number'
              pattern='[0-9]{13,16}'
              type='tel'
              maxlength='16'
              placeholder='xxxx xxxx xxxx xxxx'
              inputMode='numeric'
              value={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group controlId='formGroupCardholderName'>
            <Form.Label className='cc-input-title'>Cardholder Name</Form.Label>
            <Form.Control
              className='cc-input'
              type='text'
              required
              value={cardHolderName}
              onChange={(e) => {
                setCardHolderName(e.target.value);
              }}
            />
          </Form.Group>

          <Row className='multi-container'>
            <Form.Group as={Col} className='col-custom pl-0' controlId='formGroupExpirationDate'>
              <Form.Label className='cc-input-title'>Expiration Date</Form.Label>
              <DatePicker
                className={'cc-input w-100 ' + dateDisplay}
                clearIcon={null}
                calendarIcon={<img src={ic_calendar} alt='Calendar' />}
                showLeadingZeros={true}
                format='MM-yy'
                defaultView='year'
                maxDetail='year'
                yearPlaceholder={null}
                monthPlaceholder={null}
                value={validityDate}
                onChange={(e) => {
                  setValidtyDate(e);
                }}
                required
              />
              {/* <Form.Control className="cc-input" type="text" /> */}
            </Form.Group>

            <Form.Group as={Col} className='col-custom pr-0' controlId='formGroupCVV'>
              <Form.Label className='cc-input-title'>CVV</Form.Label>
              <Form.Control
                className='cc-input'
                type='text'
                required
                value={cvv}
                onChange={(e) => {
                  setCvv(e.target.value);
                  //this.setState({ cvv: e.target.value })
                }}
              />
            </Form.Group>
          </Row>

          <Form.Group
            as={Col}
            className='col-custom pl-0'
            controlId='formGroupBillingZipCode'
            lg={6}
            md={6}
            sm={6}
          >
            <Form.Label className='cc-input-title'>Billing Zip Code</Form.Label>
            <Form.Control
              className='cc-input'
              type='text'
              required
              value={billingZipCode}
              onChange={(e) => {
                setBillingZipCode(e.target.value);
                //this.setState({ billingZipCode: e.target.value })
              }}
            />
          </Form.Group>

          <button className='add-card' variant='primary' type='submit'>
            Add Card
          </button>
        </Form>
        {/* <div className="back-btn-container">
                    <button className="back-btn" onClick={(e) => {
                        e.preventDefault();
                        this.props.backBtn()
                    }}>
                        Back
                    </button>
                </div> */}
      </div>
      <button className='close-btn' onClick={() => props.onClose()}>
        Close
      </button>
    </div>
  );
}
