import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import { XLg } from 'react-bootstrap-icons';
import Green from '../../Icons/modal-bg.png';

import { useMutation, useQueryClient } from 'react-query';
import { deleteImage } from '../../global/helpers';

import { ImagesKey } from '../../constants/Keys';
import { BASE_URL } from '../../constants/Constants';
import { useParams } from 'react-router';

export default function ConfirmRemoveModal({
  isOpen,
  setIsOpen,
  title,
  msg,
  discard,
  confirm,
  guid,
}) {
  const [disabled, setDisabled] = useState(false);
  const queryClient = useQueryClient();

  const { id } = useParams();

  let url = `${BASE_URL}/projects/${id}/images/${guid}`;

  const { mutate, onError } = useMutation(deleteImage, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ImagesKey);
      setIsOpen(false);
    },
  });

  if (onError) {
    setDisabled(false);
  }

  return (
    <Modal show={isOpen} onHide={setIsOpen} centered>
      <Modal.Header className='d-flex justify-content-between align-items-center border-0 mb-3 relative'>
        <Modal.Title style={{ color: '#FF7E7E' }}>{title}</Modal.Title>
        <div className=' text-success '>
          <XLg
            style={{ zIndex: '2' }}
            className={`${disabled ? 'cursor-disabled' : ''} relative cursor-pointer`}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <img
          alt='background'
          src={Green}
          className='absolute'
          style={{ top: '0px', right: '0px' }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className='mb-5'>
          <p>{msg}</p>
        </div>
        <div className='mt-2 d-flex justify-content-between'>
          <button
            className={`${
              disabled ? 'cursor-disabled' : ''
            } btn  px-5 rounded text-capitalize text-sm`}
            onClick={() => setIsOpen(false)}
            style={{ color: '#67DB88', border: '2px solid #67DB88' }}
          >
            {discard}
          </button>
          <button
            className={`${
              disabled ? 'cursor-disabled' : ''
            } btn text-white  rounded text-capitalize text-sm`}
            style={{ backgroundColor: '#FF7E7E' }}
            onClick={() => {
              setDisabled(true);
              mutate(url);
            }}
          >
            {confirm}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
