import React from 'react';
import { Dropdown } from 'react-bootstrap';

import Edit from '../../Icons/edit.svg';
import Remove from '../../Icons/remove.svg';

import EditImg from '../../Icons/edit-img.svg';

export default function EditImageDropdown({ setShowEdit, setShowRemove }) {
  return (
    <Dropdown className='absolute' style={{ top: '-15px', right: '-5px' }}>
      <Dropdown.Toggle
        variant='light'
        id='dropdown-basic'
        style={{ padding: '0px', background: 'none', boxShadow: 'none' }}
      >
        <img src={EditImg} alt='edit' />
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }} className=''>
        <Dropdown.Item onClick={() => setShowEdit(true)}>
          <span>
            <img src={Edit} alt='edit' className='mr-2' />
          </span>
          Edit
        </Dropdown.Item>
        <Dropdown.Item style={{ color: '#FF7E7E' }} onClick={() => setShowRemove(true)}>
          <span>
            <img src={Remove} alt='remove' className='mr-2' />
          </span>
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
