import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DonationCard from '../SAOrganization/Donations/DonationCard';
import './OrganizationTab.css';
import InfoCard from "../SAOrganization/InfoCard";

import * as Constants from "../../constants/Constants";
import * as helpers from "../../global/helpers";
import InfiniteScroll from 'react-infinite-scroll-component';
import { home_org_donation_summary } from '../../constants/AppUrls';

const OrganizationTab = () => {

  const [orgDetails, setOrgDetails] = useState(null)
  const [donations, setDonations] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {

    helpers.getProfile()
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let profile = response.data;
            setOrgDetails(profile)
          }
        }
      })
    getDonations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDonations = () => {
    let pageSize = 10;
    let url = Constants.BASE_URL + "/donations?limit=10&offset=" + page * pageSize;
    let pageLocal = page + 1;
    setPage(pageLocal)
    helpers.getData(url)
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let donationsLocal = donations.concat(response.data.data)
            setDonations(donationsLocal);

            if (response.data.data.length === 0 || response.data.data.length < pageSize)
              setHasMore(false);
          }
        }
        if (!isFinished)
          setIsFinished(true)

      })
      .catch((error) => {
        if (!isFinished)
          setIsFinished(true)
      })
  }

  let userType = helpers.getUserType() === Constants.USER_TYPE_CONTRIB ? 'contributor'
    : helpers.getUserType() === Constants.USER_TYPE_ADMIN ? 'admin' : 'org';


  return (
    <Container id='organization_tab-div'>
      <Row className='mx-0 justify-content-between'>
        <div className='col-12 col-md-7 px-0 mr-1 py-md-3 py-0'>
          {
            !isFinished
              ? <h4 className="loading-label">Loading...</h4>
              : donations !== undefined && donations !== null && donations.length > 0
                ? <InfiniteScroll
                  className="px-md-3 px-0 pb-4"
                  dataLength={donations.length}
                  next={getDonations}
                  hasMore={hasMore}
                  loader={<h4 className="loading-label">Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {
                    donations.map((item, index) => {
                      let linkTo = home_org_donation_summary
                        .replace(":donationId", item.id)
                      return (
                        <Link
                          key={"donation-" + index}
                          className='link-reset'
                          to={linkTo}
                        >
                          <DonationCard userType={"org"} donationDetails={item} />
                        </Link>
                      )
                    })
                  }
                </InfiniteScroll>
                : <h4 className="center">No donations made to your organization</h4>
          }

        </div>

        <Col xs={12} md={4} className='px-0'>
          <InfoCard orgDetails={orgDetails} userType={userType} acceptRejectOrg={null} enableDisableOrg={null} />
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationTab;
