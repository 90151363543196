import React, { useEffect } from "react";
import "./TrendingOrganizationsAndProjects.css";
import TrendingOrganizationsAndProjectsTabs from "./TrendingOrganizationsAndProjectsTabs";
import { useDispatch } from 'react-redux';

const TrendingOrganizationsAndProjects = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'SET_IS_BREADCRUMB_SHOWN', payload: { isBreadcrumbShown: true } });
    }, [dispatch])

    return (
        <TrendingOrganizationsAndProjectsTabs
            className="trending_pages_tabs"
        />
    );
};

export default TrendingOrganizationsAndProjects;