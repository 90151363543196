import React, { useEffect, useState } from 'react';
import "./BarChart.css"
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getOverallDonations } from '../../../global/helpers';
import HC_rounded from "highcharts-rounded-corners";

HC_rounded(Highcharts);

export default function BarChart() {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column'
    },
    title: {
      text: 'Overall Donations'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: [],
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px"></span><table>',
      pointFormat: '<tr>' +
        '<td style="padding:0;font-weight:bold"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 4,
        borderRadiusTopRight: 4
      }
    },
    series: [{
      name: '',
      data: []
    }]
  });

  useEffect(() => {
    getOverallDonations()
      .then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            let series = response.data.map((item) => {
              return item.amount
            })
            let categories = response.data.map((item) => {
              return item.month
            })
            console.log("categories: ", categories)
            setChartOptions({
              xAxis: {
                categories: [...categories]
              },
              series: [{
                name: '',
                data: [...series],
                color: '#7CB1FF'
              }]
            });
          }
        }
      })
  }, [])

  return <div className='bg-white rounded shadow mt-0 w-100 p-lg-5 p-md-2 p-sm-1'>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  </div>;
}
