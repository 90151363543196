import React from 'react';
import { Badge } from 'react-bootstrap';
/* import ellipse from '../../../images/Ellipse 6.png'; */
import starVector from '../../../images/star.svg';
import styles from './OrganizationItem.module.css';
import defaultImage from '../../../images/img-default.svg';
import * as helpers from '../../../global/helpers';
import * as Constants from '../../../constants/Constants';

const OrganizationItem = (props) => {
  const { title: orgTitle, rating: orgRating, image } = props;
  const data = props.data;
  let userType =
    helpers.getUserType() === Constants.USER_TYPE_CONTRIB
      ? 'contributor'
      : helpers.getUserType() === Constants.USER_TYPE_ADMIN
      ? 'admin'
      : '';

  const title = orgTitle ? orgTitle : data !== undefined ? data.name : 'Project Alert';
  const rateValue = orgRating ? orgRating : data?.rating;
  const tags = data !== undefined ? [...data.visibleTags] : [];

  let orgImg = defaultImage;
  if (data && data.image) {
    orgImg = Constants.BASE_URL + '/media/' + data.image + '/200'; //first field: GUID, second is: size
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage;
  };

  let badges;
  let statusTextColor =
    props.status === 'pending'
      ? '#FFB627'
      : props.status === 'active'
      ? '#7CB1FF'
      : props.status === 'disabled'
      ? '#FFFFFF'
      : '#FF7E7E';

  let statusBgColor = props.status === 'disabled' ? '#FF7E7E' : '#FFFFFF';

  badges = tags.map((item, index) => {
    return (
      <Badge className={styles.tag} variant='info' key={index}>
        {item}
      </Badge>
    );
  });

  let container_class = styles.container;
  let organization_class = styles.organization;
  let organization_body_class = styles.organization_body;
  let rating_class = styles.rating;
  let star = styles.star;
  let tags_class = styles.tags;

  if (props.isOpeningSection !== undefined && props.isOpeningSection) {
    container_class = styles.container + ' ' + styles.container_opening + ' col-lg-3 col-md-4';
    organization_class = styles.organization + ' ' + styles.organization_opening;
    organization_body_class = styles.organization_body_opening;
    rating_class = styles.rating_opening;
    star = styles.star_opening;
    tags_class = styles.tags_opening;
  }

  return (
    <div className={`${container_class}`}>
      <div className={`${organization_class} `} style={{ height: 'auto' }}>
        <img
          className={styles.image}
          alt='organization img'
          src={image ? image : orgImg}
          onError={addDefaultSrc}
        />
        <div className={organization_body_class}>
          <p className={styles.title}>{title}</p>

          <div className={rating_class}>
            <img alt='rate' className={star} src={starVector} />{' '}
            <span className={styles.value}>{rateValue}</span>
          </div>
          {userType === 'admin' ? (
            <p
              className='px-3 py-1 mr-1 rounded mb-0 mt-2 d-inline-block text-capitalize '
              style={{
                border: `2px solid ${statusTextColor}`,
                color: `${statusTextColor}`,
                backgroundColor: `${statusBgColor}`,
              }}
            >
              {props.status}
            </p>
          ) : userType === 'contributor' ? (
            ''
          ) : (
            ''
          )}
          <div className={tags_class}>{badges}</div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationItem;
