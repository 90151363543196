import React, { useRef, useState, useEffect } from "react";
import { Col, Form, Row, InputGroup, Image, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import "./CreateNewProject.css";
import ic_calendar from "../../../../images/ic_calendar.png";
import dangerCircleIcon from "../../../../images/danger-circle.svg";
import ReplaceImageLogo from '../../../../images/replace-image.png';
import * as helpers from "../../../../global/helpers";
import * as AppUrls from "../../../../constants/AppUrls";
import { connect } from "react-redux";
import * as actions from "../../../../store/actionTypes";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { useHistory, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../constants/Constants";
const crnschema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    projectGoal: yup.string().required("Project Goal is required")
});

function CreateNewProject(props) {
    let history = useHistory();
    //const [file, setFile] = useState(null);
    //const [defaultStyle, setDefaultStyle] = useState(null);
    //const [imgPreview, setImgPreview] = useState(null);
    const [showImgError, setShowImgError] = useState(false);
    //const [replaceImage, setReplaceImage] = useState(null);
    //const [hideReplaceImageStyle, setHideReplaceImageStyle] = useState(null);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [endsAtHover, setEndsAtHover] = useState(false);
    const [projectUrl, setProjectUrl] = useState("");
    const [checkedId, setCheckedId] = useState("");
    const [showRadioError, setShowRadioError] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const crnImageRef = useRef(null);

    const [projectImg, setProjectImg] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [rawImageFile, setRawImageFile] = useState(null);
    const [projectImgGUID, setProjectImgGUID] = useState("");

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(crnschema)
    });

    let radioOpen = "RadioOpen";
    let radioDate = "RadioDate";

    let dateDisplay = endDate === null ? "date-display" : "";
    let infoIconDislay = checkedId === radioDate ? "block" : "none";
    let endsAtDisplay = endsAtHover ? "block" : "none";

    if (endDate !== null && checkedId !== radioDate) {
        setEndDate(null)
    }
    const { id } = useParams();

    useEffect(() => {
        props.setIsBreadcrumbShown(true);
        if (props.isEdit !== undefined && props.isEdit) {
            setFieldsDisabled(true);

            helpers.getProjectById(id).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setFieldsDisabled(false)
                    let data = response?.data
                    setValue("title", data?.title)
                    setValue("description", data?.description)

                    setProjectUrl(data?.projectUrl)
                    let endDateUnix = data?.endDateUnix
                    if (endDateUnix === null) {
                        setCheckedId(radioOpen)
                    } else {
                        setCheckedId(radioDate);
                        let endDateConverted = helpers.getDateFromUnixAsDate(endDateUnix);
                        console.log("endDateConverted: ", endDateConverted);
                        setEndDate(endDateConverted)
                    }
                    setValue("projectGoal", data?.targetAmount)
                    let projectImgLocal = null;
                    if (data.image !== undefined && data.image !== null && data.image !== "" && data.image !== "guid") {
                        let imgUrl = BASE_URL + "/media/" + data.image + "/200" //first field: GUID, second is: size
                        projectImgLocal = imgUrl;
                    }
                    setProjectImg(projectImgLocal)
                    setProjectImgGUID(data.image)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function uploadImgFile(e) {
        if (e.target.files[0] !== undefined) {
            let file = URL.createObjectURL(e.target.files[0])
            setImageFile(file);
            setRawImageFile(e.target.files[0])
            setShowImgError(false)
        } else if (imageFile === null) {
            validate()
        }
    }


    function replaceImg() {
        setImageFile(null);
        setProjectImg(null);
    }


    function getGUID(data, rawImageFileParam) {
        setFieldsDisabled(true);
        setSubmitBtnDisabled(true)
        if (rawImageFile === null) {
            createNewProject(projectImgGUID, data);
            return;
        }
        const formData = new FormData();
        formData.append(
            "file", rawImageFileParam
        );

        helpers.uploadMedia(formData)
            .then((response) => {
                if (response.status !== undefined && response.status === 201) {
                    createNewProject(response.data.guid, data);
                } else {
                    console.log("error get guid: ", response.data)
                }
            })
            .catch((error) => {
                setFieldsDisabled(false);
                setSubmitBtnDisabled(false)
            });
    }

    function createNewProject(guid, data) {
        var goal = parseInt(data.projectGoal);
        let postdata = {
            image: guid,
            title: data.title,
            description: data.description,
            url: projectUrl,
            endDate: helpers.dateFormmater(endDate),
            goal: goal
        }

        if (props.isEdit !== undefined && props.isEdit) {
            helpers.editProject(id, postdata)
                .then((response) => {
                    setFieldsDisabled(false);
                    setSubmitBtnDisabled(false)
                    if (response !== undefined) {
                        if (response.status === 200) {
                            helpers.successNotify("Project Updated");
                            history.goBack();
                        }
                    }
                })
                .catch((error) => {
                    setFieldsDisabled(false);
                    setSubmitBtnDisabled(false)
                })
        } else {
            postdata = { ...postdata, images: [] }
            helpers.createProject(postdata)
                .then((response) => {
                    setFieldsDisabled(false);
                    setSubmitBtnDisabled(false)
                    if (response !== undefined) {
                        if (response.status === 201) {
                            helpers.successNotify("Project Created");
                            history.push(AppUrls.projects)
                        }
                    }
                })
                .catch((error) => {
                    setFieldsDisabled(false);
                    setSubmitBtnDisabled(false)
                })
        }

    }


    function validate() {
        let isValid = true
        if (projectImg === null && imageFile === null) {
            isValid = false;
            setShowImgError(true)
        }

        if (checkedId === "") {
            isValid = false;
            setShowRadioError(true)
        }
        return isValid;
    }

    let imageInputDisplay = projectImg !== null || imageFile !== null ? "none" : "block";
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
        <div className="create-new-project-container">
            <div className="create-new-project-top-bg" />
            <div className="inner-container">
                <fieldset disabled={fieldsDisabled}>


                    <Form onSubmit={handleSubmit((data) => {
                        console.log(data)
                        if (validate()) {
                            getGUID(data, rawImageFile);
                        }
                    })}
                        onReset={(e) => {
                            reset()
                            e.target.reset();
                        }}>
                        <div>
                            Project Image <span className="required_field">*</span>
                        </div>

                        {/* <>
                            <Form.Group className="replace_image_form" style={hideReplaceImageStyle}>
                                {imgPreview}{replaceImage}
                            </Form.Group>
                            <Form.Group style={defaultStyle} className="crn-profile-image">
                                <Form.Control
                                    ref={crnImageRef}
                                    type="file"
                                    className='crn-profile-image-1'
                                    onChange={(e) => {
                                        let file = e.target.files[0]
                                        uploadSingleFile(file)
                                    }}
                                />
                            </Form.Group>
                        </> */}

                        <>
                            {
                                projectImg !== null || imageFile !== null
                                    ? <Form.Group className="replace_image_form" >
                                        <Image
                                            src={projectImg || imageFile}
                                            className="crn-image-uploaded" />
                                        <Row id="replace_image-div">
                                            <Image src={ReplaceImageLogo} id="replace_logo-img" />
                                            <Button id="replace-button" onClick={(e) => {
                                                e.preventDefault();
                                                replaceImg()
                                            }}>Replace Image</Button>
                                        </Row>
                                    </Form.Group>
                                    : null
                            }

                            <Form.Group style={{ display: imageInputDisplay }} className="crn-profile-image">
                                <Form.Control
                                    ref={crnImageRef}
                                    type="file"
                                    className='crn-profile-image-1'
                                    onChange={uploadImgFile}
                                    onClick={(e) => {
                                        e.target.value = ''
                                    }}
                                />
                            </Form.Group>

                            {/* <Form.Group style={{ display: imageInputDisplay }} id="profile_image-div">
                                <Form.Control type="file" className='profile_image_input' onChange={uploadImgFile} />
                            </Form.Group> */}
                        </>

                        <div className="required_field text-xs mt-1 mr-auto" style={{ width: "fit-content" }} >
                            {
                                showImgError
                                    ? <span>Profile image is required </span>
                                    : null
                            }
                        </div>

                        <Row className="crn-2">
                            <Col lg={5} md={5} sm={5} xs={12}>
                                <Form.Group className="mb-1">
                                    <Form.Label className="crn-label">Title</Form.Label><span className="required_field"> *</span>
                                    <Form.Control id="titleInput" className="input_field"  {...register('title')} />
                                </Form.Group>
                                <div
                                    className="required_field text-xs mr-auto mb-2"
                                    style={{ width: "fit-content" }} >
                                    {errors.title?.message}
                                </div>

                                <Form.Group className="mt-1 mb-1">
                                    <Form.Label className="crn-label">Description</Form.Label><span className="required_field"> *</span>
                                    <Form.Control
                                        id="description"
                                        as="textarea"
                                        className="about_organization crn-text-area"
                                        {...register('description')}
                                    />
                                </Form.Group>
                                <div
                                    className="required_field text-xs mr-auto mb-2"
                                    style={{ width: "fit-content" }} >
                                    {errors.description?.message}
                                </div>
                                <Form.Group>
                                    <Form.Label className="crn-label">Project URL</Form.Label>
                                    <Form.Control id="projectUrl" className="input_field" value={projectUrl} onChange={(e) => {
                                        setProjectUrl(e.target.value)
                                    }} />
                                </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={6} xs={12}>
                                <Form.Group className="mt-1 mb-1">
                                    <Form.Label className="crn-label">Project Funding End Date</Form.Label><span className="required_field"> *</span>
                                    <Form.Check type="radio" >
                                        <Form.Check.Input
                                            className="radio-input"
                                            type="radio"
                                            value={radioOpen}
                                            checked={checkedId === radioOpen}
                                            onChange={(e) => {
                                                setCheckedId(e.target.value)
                                                if (showRadioError)
                                                    setShowRadioError(false)
                                            }}
                                        />

                                        <Form.Check.Label
                                            className="radio-label crn-radio-label"
                                            onClick={() => {
                                                setCheckedId(radioOpen)
                                                if (showRadioError)
                                                    setShowRadioError(false)
                                            }}>
                                            Keep it open (no end date)
                                        </Form.Check.Label>
                                    </Form.Check>

                                    <Form.Check type="radio" style={{ marginTop: "10px" }}>

                                        <Form.Check.Input
                                            className="radio-input"
                                            type="radio"
                                            value={radioDate}
                                            checked={checkedId === radioDate}
                                            onChange={(e) => { setCheckedId(e.target.value) }}
                                        />

                                        <Form.Check.Label className="radio-label crn-radio-label" >
                                            <div onClick={() => {
                                                setCheckedId(radioDate)
                                            }}>
                                                Set an end date
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                                <DatePicker
                                                    className={"cc-input w-100 crn-end-date " + dateDisplay}
                                                    clearIcon={null}
                                                    calendarIcon={<img src={ic_calendar} alt="Calendar" />}
                                                    showLeadingZeros={true}
                                                    format="MM/dd/yy"
                                                    yearPlaceholder={null}
                                                    monthPlaceholder={null}
                                                    dayPlaceholder={null}
                                                    value={endDate}
                                                    minDate={tomorrow}
                                                    onChange={(e) => {
                                                        setEndDate(e);
                                                        console.log("date from date picker: ", e)
                                                        if (showRadioError)
                                                            setShowRadioError(false)
                                                    }}
                                                    disabled={checkedId !== radioDate}
                                                />
                                            </div>
                                            <div style={{ display: infoIconDislay }}>
                                                <img className="crn-danger-circle" src={dangerCircleIcon} alt="info"
                                                    onMouseEnter={() => setEndsAtHover(true)}
                                                    onMouseLeave={() => setEndsAtHover(false)}
                                                />
                                                <button type="button" className="btn-primary crn-ends-at" style={{ display: endsAtDisplay }}>
                                                    Ends at 12 AM GMT (midnight)
                                                </button>
                                            </div>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <div className="required_field text-xs mb-2 mr-auto" style={{ width: "fit-content" }} >
                                    {
                                        showRadioError
                                            ? <span>End Date is required </span>
                                            : null
                                    }
                                </div>

                                <Form.Group className="mb-1">
                                    <Form.Label className="crn-label">Project Goal</Form.Label><span className="required_field"> *</span>
                                    <InputGroup className="crn-project-goal-input-container">
                                        <InputGroup.Text id="inputGroupPrepend" className="crn-project-goal-label">$</InputGroup.Text>
                                        <Form.Control
                                            id="titleInput"
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="crn-project-goal-input"
                                            onKeyDown={(event) => {
                                                if (event.key === '.') { event.preventDefault(); }
                                                if (event.key === '-') { event.preventDefault(); }
                                                if (event.key === '+') { event.preventDefault(); }
                                            }}
                                            {...register('projectGoal')}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <div
                                    className="required_field text-xs mr-auto"
                                    style={{ width: "fit-content" }} >
                                    {errors.projectGoal?.message}
                                </div>
                            </Col>
                        </Row>

                        <Row className="crn-bottom-container">
                            <Col lg={4} md={4} sm={4} xs={12}></Col>
                            <Col lg={6} md={6} sm={6} xs={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={6} xs={4} style={{ textAlign: "center" }}>
                                        <button className="crn-discard" type="reset" onClick={() => {
                                            if (props.isEdit !== undefined && props.isEdit) {
                                                history.goBack()
                                                return
                                            }

                                            setCheckedId("");
                                            setProjectUrl("");
                                            if (rawImageFile === null && showImgError) {
                                                setShowImgError(false)
                                            }
                                            if (checkedId === "" && showRadioError) {
                                                setShowRadioError(false);
                                            }
                                            reset()
                                            replaceImg()
                                        }}>
                                            Discard
                                        </button>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={8} style={{ textAlign: "center" }}>
                                        <button
                                            className="crn-create"
                                            disabled={fieldsDisabled || submitBtnDisabled}
                                            type="submit"
                                            onClick={() => {
                                                validate()
                                            }}
                                        >
                                            {
                                                submitBtnDisabled
                                                    ? <>
                                                        {
                                                            props.isEdit !== undefined && props.isEdit
                                                                ? "Updating"
                                                                : "Creating..."
                                                        }
                                                    </>
                                                    : <>
                                                        {
                                                            props.isEdit !== undefined && props.isEdit
                                                                ? "Save Changes"
                                                                : "Create Project"
                                                        }
                                                    </>
                                            }
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </fieldset>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIsBreadcrumbShown: (isBreadcrumbShown) => {
            dispatch({
                type: actions.SET_IS_BREADCRUMB_SHOWN,
                payload: {
                    isBreadcrumbShown: isBreadcrumbShown
                }
            })
        },
    }
}

export default connect(null, mapDispatchToProps)(CreateNewProject)