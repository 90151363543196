export const responseErrorInterceptor = (error) => {
    if (error.message.includes('401')) {
        localStorage.clear();
        window.location = '/login';
    }
}

export const responseSuccessInterceptor = (response) => {
    return response;
}
